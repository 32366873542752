/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-04-26 17:14:33
 * @LastEditors: fugang
 * @LastEditTime: 2022-04-26 19:19:03
 */
import { adminDetail } from "./adminDetail";
export const defaultAdminModel: adminDetail = {
  loginName: "",
  password: "",
  name: "",
  companyCaptcha: "",
  remarks: "",
  company: "",
  qq: "",
  email: "",
  phone: "",
  mobile: "",
  days: "",
  loginFlag: "1",
  confirmPassword: "",
  permissionIds: []
};
