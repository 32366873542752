
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  getCurrentInstance,
  ref
} from "vue";
import { useI18n } from "vue-i18n";
import { api } from "@/apis/user";
import { Time } from "@/utils/filter";
import { formatJson } from "@/utils";
import { exportJson2Excel } from "@/utils/excel";
import { ElMessage } from "element-plus";
export default defineComponent({
  setup() {
    const { t } = useI18n();
    const { proxy } = getCurrentInstance() as any;
    const day = proxy.day;
    const multipleSelection = ref();

    const dataMap = reactive({
      tableKey: 0,
      list: [],
      total: 0,
      name: null,
      listLoading: false,
      downloadLoading: false,
      downallloadLoading: false,
      val: 10,
      time: Time,
      date: "",
      filename: "",
      keyValue: {
        value: "",
        label: ""
      },
      // options: [
      //   {
      //     value: '',
      //     label: '全部'
      //   },
      //   {
      //     value: '2',
      //     label: '下发'
      //   },
      //   {
      //     value: '1',
      //     label: '回收'
      //   }
      // ],
      // 分页查询参数
      listQuery: {
        page: {
          pageNo: 1,
          pageSize: 10
        },
        userLoginEntity: {
          startTime: "",
          endTime: "",
          remark: ""
        }
      },
      // 查询
      toSearch() {
        if (dataMap.date != "") {
          dataMap.listQuery.userLoginEntity.startTime = day(
            dataMap.date[0]
          ).format("YYYY-MM-DD HH:mm:ss");
          dataMap.listQuery.userLoginEntity.endTime = day(
            dataMap.date[1]
          ).format("YYYY-MM-DD HH:mm:ss");
          dataMap.getList(1, 10, {
            page: dataMap.listQuery.page,
            startTime: dataMap.listQuery.userLoginEntity.startTime,
            endTime: dataMap.listQuery.userLoginEntity.endTime,
            remark: dataMap.listQuery.userLoginEntity.remark
          });
        } else {
          dataMap.getList(1, 10, dataMap.listQuery.userLoginEntity);
        }
      },
      toReload() {
        dataMap.date = "";
        dataMap.listQuery.userLoginEntity.startTime = "";
        dataMap.listQuery.userLoginEntity.endTime = "";
        dataMap.listQuery.userLoginEntity.remark = "";
        dataMap.getList(1, 10);
      },
      handleSelectionChange(val: any) {
        multipleSelection.value = val;
        console.log(val);
      },
      // 分页
      handleSizeChange(val: any) {
        dataMap.val = val;
        dataMap.getList(1, val);
      },
      handleCurrentChange(page: any) {
        dataMap.getList(page, dataMap.val);
      },
      // 导出全部
      // async handleallDownload () {
      //   dataMap.downallloadLoading = true
      //   const tHeader = ['账号', '时间', '在线状态',]
      //   const filterVal = ['account', 'createDateTimestamp', 'online',]
      //   const data: any = await api.list.logList({
      //     page: {
      //       pageNo: 1,
      //       pageSize: 100000
      //     },
      //     userLoginEntity: {
      //       startDate: '',
      //       endDate: '',
      //       account: '',
      //       online: '',
      //     }
      //   })
      //   const list = data?.data.data.resultList
      //   if (list.length > 0) {
      //     dataMap.downallloadLoading = false
      //     const data = formatJson(filterVal, list)
      //     exportJson2Excel(tHeader, data, dataMap.filename !== '' ? dataMap.filename : undefined)
      //   }
      // },
      // 导出
      // handleDownload () {
      //   console.log(multipleSelection.value)
      //   // const from = unref(multipleTableNode)
      //   if (multipleSelection.value != undefined && multipleSelection.value.length !== 0) {
      //     dataMap.downloadLoading = true
      //     const tHeader = ['账号', '时间', '在线状态',]
      //     const filterVal = ['account', 'createDateTimestamp', 'online',]
      //     const list = multipleSelection.value
      //     const data = formatJson(filterVal, list)
      //     exportJson2Excel(tHeader, data, dataMap.filename !== '' ? dataMap.filename : undefined)
      //     // from.clearSelection()
      //     dataMap.downloadLoading = false
      //   } else {
      //     ElMessage.warning('请先勾选要导出的选项')
      //   }
      // },

      // 页面初始化数据
      async getList(page?: any, pageSize?: any, userLoginEntity?: any) {
        if (page) {
          dataMap.listQuery.page.pageNo;
        }
        if (pageSize) {
          dataMap.listQuery.page.pageSize = pageSize;
        }
        dataMap.listQuery.page.pageNo = page;
        dataMap.listQuery.page.pageSize = pageSize;
        // dataMap.listQuery.userLoginEntity
        dataMap.listLoading = true;
        const data: any = await api.list.virtuaDetailsList({
          page: dataMap.listQuery.page,
          startTime: dataMap.listQuery.userLoginEntity.startTime,
          endTime: dataMap.listQuery.userLoginEntity.endTime,
          remark: dataMap.listQuery.userLoginEntity.remark
        });
        dataMap.list = data?.data.data.resultList;
        dataMap.total = data?.data.data.totalCount;
        setTimeout(() => {
          dataMap.listLoading = false;
        }, 0.3 * 1000);
      }
    });

    onMounted(() => {
      dataMap.getList(1, 10), multipleSelection;
    });

    return {
      t,
      ...toRefs(dataMap)
    };
  }
});
