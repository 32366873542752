/*
 * @Description:
 * @Autor: fugang
 * @Date: 2020-12-07 10:30:20
 * @LastEditors: fugang
 * @LastEditTime: 2022-04-27 10:29:41
 */
import { createApp, Directive } from 'vue'
import App from './App.vue'

// import './pwa/registerServiceWorker'
import router from './router'
import { store } from './store'
import { loadAllPlugins } from './plugins'
import '@/assets/iconfont/iconfont.css'
import '@/styles/index.scss'
import 'normalize.css'
import * as directives from '@/directives'
import '@/permission'
import dayjs from 'dayjs'
const app = createApp(App)

// 加载所有插件
loadAllPlugins(app)

// 自定义指令
Object.keys(directives).forEach(key => {
  app.directive(key, (directives as { [key: string]: Directive })[key])
})

app.config.globalProperties.day = dayjs
app.use(store).use(router).mount('#app')
