/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-05-05 09:51:37
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-12 17:34:07
 */

import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const ExampleRouter: Array<RouteRecordRaw> = [

  {
    path: '/system',
    component: Layout,
    redirect: 'noredirect',
    meta: {
      title: 'systemPages',
      icon: '#icon-xitongguanli1',
      roles: ['系统管理员']
    },
    children: [
      {
        path: '/deployment',
        component: () => import('@/views/system/deployment.vue'),
        name: 'deployment',
        meta: {
          title: 'deployment',
          icon: '#icon-zhuomianbushu',
          noCache: true
        }
      },
      {
        path: '/menuadministration',
        component: () => import('@/views/system/menuadministration.vue'),
        name: 'menuadministrationPages',
        meta: {
          title: 'menuadministration',
          icon: '#icon-caidan',
          noCache: true,
          hidden: true,
        }
      },
      {
        path: '/roleadmin',
        component: () => import('@/views/system/roleadmin.vue'),
        name: 'roleadminPages',
        meta: {
          title: 'roleadmin',
          icon: '#icon-role',
          noCache: true,
          // hidden: true,

        }
      }
    ]
  }
]

export default ExampleRouter
