
import { defineComponent, onMounted, reactive, toRefs, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { api } from '@/apis/user'
import { ElMessage } from 'element-plus'
import { Time } from '@/utils/filter'
export default defineComponent({
  setup () {
    const { t } = useI18n()
    const dialogFormtoggle = ref(false)
    const formdistribute = ref()

    const rules = reactive({
      name: [
        { required: true, message: '请输入功能名称', trigger: 'blur' }
      ],
      funcCode: [
        { required: true, message: '请输入功能键值', trigger: 'blur' }
      ],

    })
    const dataMap = reactive({
      tableKey: 0,
      val: 10,
      list: [],
      total: 0,
      name: null,
      listLoading: false,
      downloadLoading: false,
      time: Time,

      toggle: {
        name: '',
        funcCode: ''
      },
      // 分页查询参数
      listQuery: {
        page: {
          pageNo: 1,
          pageSize: 10
        },

      },

      // 查询
      toSearch () {
        dataMap.getList(1, 10,)
      },
      toSeeting () {
        dialogFormtoggle.value = true
      },
      // 分页
      handleSizeChange (val: any) {
        dataMap.val = val
        dataMap.getList(1, val)
      },
      handleCurrentChange (page: any) {
        dataMap.getList(page, dataMap.val)
      },
      closedialog () {
        dialogFormtoggle.value = false
        formdistribute.value.resetFields()
      },
      async submitDisToggle () {
        formdistribute.value.validate(async (valid: any) => {
          if (valid) {
            const { data }: any = await api.revise.createfunc(dataMap.toggle)
            if (data.status === 200) {
              dialogFormtoggle.value = false
              formdistribute.value.resetFields()
              ElMessage.success('添加成功')
              dataMap.getList(1, 10)
            }
          } else {
            return false
          }
        })


      },
      // 页面初始化数据
      async getList (page?: any, pageSize?: any,) {
        if (page) {
          dataMap.listQuery.page.pageNo
        }
        if (pageSize) {
          dataMap.listQuery.page.pageSize = pageSize
        }
        dataMap.listQuery.page.pageNo = page
        dataMap.listQuery.page.pageSize = pageSize
        dataMap.listLoading = true
        const data: any = await api.list.funcList(dataMap.listQuery)
        dataMap.list = data?.data.data.resultList
        dataMap.total = data?.data.data.totalCount
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.3 * 1000)
      }
    })
    onMounted(() => {
      dataMap.getList(1, 10)
    })

    return {
      t, ...toRefs(dataMap), dialogFormtoggle, rules, formdistribute
    }
  }
})
