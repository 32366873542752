/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-28 17:18:24
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-23 09:48:10
 */

export default {
  route: {
    dashboard: "首页",
    documentation: "文档",
    guide: "引导页",
    permission: "权限测试页",
    rolePermission: "角色权限",
    pagePermission: "页面权限",
    directivePermission: "指令权限",
    icons: "图标",
    components: "组件",
    tinymce: "富文本编辑器",
    markdown: "Markdown",
    jsonEditor: "JSON 编辑器",
    splitPane: "Splitpane",
    avatarUpload: "头像上传",
    dropzone: "Dropzone",
    sticky: "Sticky",
    countTo: "Count To",
    componentMixin: "小组件",
    backToTop: "返回顶部",
    draggableDialog: "可拖拽对话框",
    draggableKanban: "可拖拽看板",
    draggableList: "可拖拽列表",
    draggableSelect: "可拖拽选择",
    charts: "图表",
    barChart: "柱状图表",
    lineChart: "折线图",
    mixedChart: "混合图表",
    example: "综合实例",
    nested: "路由嵌套",
    menu1: "菜单 1",
    menu2: "菜单 2",
    table: "表格",
    dynamicTable: "动态表格",
    draggableTable: "可拖拽表格",
    inlineEditTable: "表格内编辑",
    complexTable: "综合表格",
    tab: "分栏",
    form: "表单",
    createArticle: "创建文章",
    editArticle: "编辑文章",
    articleList: "文章列表",
    errorPages: "错误页面",
    page401: "401",
    page404: "404",
    errorLog: "错误日志",
    excel: "Excel",
    exportExcel: "导出 Excel",
    selectExcel: "导出 已选择项",
    mergeHeader: "导出 多级表头",
    uploadExcel: "上传 Excel",
    zip: "Zip",
    pdf: "PDF",
    exportZip: "导出 Zip",
    theme: "换肤",
    clipboard: "粘贴板",
    i18n: "国际化",
    externalLink: "外链",
    profile: "个人中心",
    userPages: "用户管理",
    groupPages: "群组管理",
    groupList: "群组列表",
    groupAdd: "群组添加",
    GroupAdd: "群组添加",
    userList: "用户列表",
    userAdd: "用户添加",
    userUpdate: "用户修改",
    ICCIDADD: "iccid放号",
    groupUpdate: "群组修改",
    groupNested: "群组嵌套",
    logging: "日志记录",
    logsearch: "日志查询",
    statistics: "统计信息",
    billinglog: "账单日志",
    changelog: "切换日志",
    virtualCurrencyRecord: "虚拟币记录",
    virtualDetails: "虚拟币明细",
    userLoginlog: "登录信息",
    administrator: "管理员",
    adminlist: "管理员列表",
    adminadd: "管理员添加",
    transfervirtual: "转拨虚拟币",
    authorizationCode: "授权码",
    systemPages: "系统管理",
    domain: "域名设置",
    deployment: "部署管理",
    parametersettingsPages: "虚拟币菜单设置",
    funclist: "功能列表",
    function: "功能",
    funcgrouplist: "功能组列表",
    importcar: "导入卡号",
    importfile: "导入文件",
    cardId: "卡号",
    fucgroupadd: "功能组添加",
    menuadministration: "菜单管理",
    roleadmin: "角色管理",
    aboutus: "关于我们",
    "VIP batch renewal": "VIP批量续费",
    "batch add groups": "批量添加群组",
    "Batch delete": "批量删除",
    "Batch update IMEI": "批量修改IMEI"
  },
  navbar: {
    logOut: "退出登录",
    dashboard: "首页",
    github: "项目地址",
    theme: "换肤",
    size: "布局大小",
    profile: "个人中心"
  },
  login: {
    title: "系统登录",
    logIn: "登录",
    username: "账号",
    password: "密码",
    plaseusername: "请输入账号",
    plasepassword: "请输入密码",
    plasecode: "请输入验证码",
    plaseinputcode: "请输入正确的验证码",
    "Remember Username":"记住账号",
    any: "随便填",
    thirdparty: "第三方登录",
    thirdpartyTips: "本地不能模拟，请结合自己业务进行模拟！！！"
  },
  dashboard: {
    totalusers: "总用户数",
    online: "在线",
    offline: "离线",
    avaiable: "可用虚拟币: 一年期/三年期",
    all: "全部",
    groupNum: "群组数"
  },
  documentation: {
    documentation: "文档",
    github: "Github 地址",
    "Management Platform Intercom Operation Instructions":
      "管理平台对讲操作说明",
    "Open the Dispatcher": "打开调度台",
    "coding software": "写码软件"
  },
  permission: {
    createRole: "新增角色",
    editPermission: "编辑权限",
    roles: "你的权限",
    switchRoles: "切换权限",
    tips:
      "在某些情况下，不适合使用 v-permission。例如：Element-UI 的 el-tab 或 el-table-column 以及其它动态渲染 dom 的场景。你只能通过手动设置 v-if 来实现。",
    delete: "删除",
    confirm: "确定",
    cancel: "取消"
  },
  guide: {
    description:
      "引导页对于一些第一次进入项目的人很有用，你可以简单介绍下项目的功能。本 Demo 是基于",
    button: "打开引导"
  },
  components: {
    documentation: "文档",
    tinymceTips:
      "富文本是管理后台一个核心的功能，但同时又是一个有很多坑的地方。在选择富文本的过程中我也走了不少的弯路，市面上常见的富文本都基本用过了，最终权衡了一下选择了Tinymce。更详细的富文本比较和介绍见",
    stickyTips: "当页面滚动到预设的位置会吸附在顶部",
    backToTopTips1: "页面滚动到指定位置会在右下角出现返回顶部按钮",
    backToTopTips2:
      "可自定义按钮的样式、show/hide、出现的高度、返回的位置 如需文字提示，可在外部使用Element的el-tooltip元素",
    imageUploadTips:
      "由于我在使用时它只有vue@1版本，而且和mockjs不兼容，所以自己改造了一下，如果大家要使用的话，优先还是使用官方版本。"
  },
  table: {
    ID: "账号ID",
    comfirm: "确定",
    Account: "账号",
    AccountType: "账号类型",
    version: "版本",
    designation: "名称",
    name: "名字",
    Creator: "上级账号",
    IP: "接入IP",
    IMEI: "IMEI号",
    password: "密码",
    "Confirm password": "确认密码",
    Company: "公司",
    Email: "电子邮箱",
    "Location save time": "定位保存时间",
    Mobile: "手机",
    Telephone: "座机",
    QQ: "QQ",
    "Manufactor code": "厂商验证码",
    "Allow login": "是否允许登录",
    allow: "允许",
    "not allowed": "不允许",
    createBy: "创建者",
    oneyear: "一年",
    threeyear: "三年",
    Total: "总数",
    Used: "已用",
    Unused: "可用",
    actions: "操作",
    "Issue Credit": "下发虚拟币",
    "Recover Credit": "回收虚拟币",
    "account limited time": "账号的有限时间",
    "Wait renewals": "待续费",
    "Set Years": "年限设置",
    "Set Permissions": "权限设置",
    Issue: "下发",
    Recover: "回收",
    "Are you sure to recycle": "你确定回收吗？",
    "Migrate users": "迁移用户",
    "Migration administrator": "迁移管理员",
    "Target administrator": "目标管理员",
    "Migrate group": "迁移群组",
    "Target group": "目标群组",
    Members: "群组成员",
    Items: "成员",
    Selected: "已选",
    Unselected: "未选",
    unfreeze: "解冻",
    Child: "子级",
    Parent: "父级",
    Basic: "基础续费",
    VIP: "VIP续费",
    Renewals: "续费总数",
    Remark: "备注",
    edit: "编辑",
    add: "添加",
    delete: "删除",
    cancel: "取 消",
    confirm: "确 定",
    submit: "提交",
    back: "返回",
    reset: "重置",
    activation: "激活",
    suspend: "暂停",
    upload: "导入",
    uploadICCID: "导入ICCID放号",
    bindingIMEI: "绑定IMEI号",
    BatchRenewal: "批量续费",
    defaultgroup: "默认群组",
    defaultgroupID: "默认组ID",
    editgroup: "修改默认群组",
    BatchEdit: "批量修改信息",
    registerterminal: "注册终端数",
    onlineterminal: "在线终端",
    online: "在线",
    onlineStatus: "在线时间",
    expireTime: "到期时间",
    accountnumber: "账号",
    BatchName: "批量修改名字",
    uId: "用户ID",
    dynamicTips1: "固定表头, 按照表头顺序排序",
    dynamicTips2: "不固定表头, 按照点击顺序排序",
    dragTips1: "默认顺序",
    dragTips2: "拖拽后顺序",
    title: "标题",
    importance: "重要性",
    type: "类型",
    Debriefingtype: "汇报型",
    Directivetype: "指令型",
    search: "搜索",
    export: "导出",
    exportall: "导出全部",
    reviewer: "审核人",
    id: "序号",
    date: "时间",
    author: "作者",
    readings: "阅读数",
    status: "状态",
    publish: "发布",
    draft: "草稿",
    createTime: "创建时间",
    lastTime: "在线/离线时间",
    validDate: "有效时间",
    sourceServer: "源服务器",
    targetServer: "目标服务器",
    operationType: "操作类型",
    actionobjects: "操作对象",
    Downtimemigration: "宕机迁移",
    loadmigration: "负载迁移",
    operatorAdmin: "操作管理员",
    loginImei: "登录的IMEI号",
    groupId: "群组Id",
    groupName: "群组名称",
    remarks: "备注",
    modify: "修改",
    "Member priority": "成员优先级",
    priority: "优先级",
    primary: "初级",
    mid: "中级",
    senior: "高级",
    "modify success": "修改成功",
    "delete success": "删除成功",
    success: "添加成功",
    detail: "详情",
    friend: "好友",
    "Online (offline) time": "Online (offline) time",
    remotr: "远程",
    select: "选择",
    selectGroup: "选择群组",
    Expiringusers: "快到期用户",
    remotrnum: "远程放号",
    more: "更多数据"
  },
  example: {
    warning:
      "创建和编辑页面是不能被 keep-alive 缓存的，因为keep-alive 的 include 目前不支持根据路由来缓存，所以目前都是基于 component name 来进行缓存的。如果你想类似的实现缓存效果，可以使用 localStorage 等浏览器缓存方案。或者不要使用 keep-alive 的 include，直接缓存所有页面。详情见"
  },
  errorLog: {
    tips: "请点击右上角bug小图标",
    description:
      "现在的管理后台基本都是spa的形式了，它增强了用户体验，但同时也会增加页面出问题的可能性，可能一个小小的疏忽就导致整个页面的死锁。好在 Vue 官网提供了一个方法来捕获处理异常，你可以在其中进行错误处理或者异常上报。",
    documentation: "文档介绍"
  },
  excel: {
    export: "导出",
    selectedExport: "导出已选择项",
    placeholder: "请输入文件名(默认excel-list)"
  },
  zip: {
    export: "导出",
    placeholder: "请输入文件名(默认file)"
  },
  pdf: {
    tips: "这里使用 window.print() 来实现下载pdf的功能"
  },
  theme: {
    change: "换肤",
    documentation: "换肤文档",
    tips:
      "Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。",
    loading: "主题正在努力重置..."
  },
  tagsView: {
    refresh: "刷新",
    close: "关闭",
    closeOthers: "关闭其它",
    closeAll: "关闭所有"
  },
  settings: {
    success: "成功",
    title: "系统布局配置",
    theme: "主题色",
    showTagsView: "显示 Tags-View",
    showSidebarLogo: "显示侧边栏 Logo",
    fixedHeader: "固定 Header",
    sidebarTextTheme: "侧边栏文字主题色"
  },
  // 搜索
  search: {
    adminId: "管理员ID",
    account: "账号",
    remark: "备注",
    startTime: "开始时间",
    endTime: "结束时间",
    onlinestatus: "在线状态",
    Time: "时间"
  },
  // 输入框的提示
  input: {
    enterAdminID: "请输入管理员ID",
    "Please select the number of years": "请选择年限",
    Note: "注意事项：年限设置为账号创建时的有效期时间",
    "Please select the group of the migrated user":
      "请选择或输入迁移用户所在的群组",
    "Please select a group to migrate users": "请选择迁移用户的群组",
    "Please select the target administrator": "请选择或输入目标管理员",
    "Please select bill": "请选择账单",
    "Please select gps period": "请选择gps周期",
    "Please select permissions": "请选择权限",
    "Please select default group": "请选择或输入默认群组",
    "Please select the group": "请选择所属群组",
    "Please select the subgroups": "请选择子群组",
    "Please select the parent group": "请选择父群组",
    "Please select https://store.steampowered.com/app/414340/Hellblade_Senuas_Sacrifice/the users": "请选择终端用户",
    "Please input IMEI": "请输入IMEI号",
    "Please enter the 12~16 digit IMEI number of the device":
      "请输入设备12~16位的IMEI号",
    "Please input Name Starting number": "请输入名称起始数",
    "Please enter an account consisting of 2 to 15 digits or letters":
      "请输入2到15位数字或字母组成的账号",
    "Please enter 6 to 12 characters to support letters and numbers":
      "请输入6到12位的字符,支持字母和数字",
    "The name consists of 15 digits or letters and underlined lines":
      "名字支持数字或字母、下划线、中文,最长15位",
    "The name consists of 15 digits or letters and underlined lines(Use * to indicate the position of the serial number)":
      "名字支持数字或字母、下划线、中文,最长15位(可用*号表示序号位置)",
    "The account number can only contain Chinese alphanumeric underscores, no more than 12 characters":
      "账号只能包含中文字母数字下划线,不超过12个字符",
    "Please enter Chinese or letters, the length should not exceed 6 characters":
      "请输入中文或字母,长度不超过6位",
    "Account length should be between 2 and 12 characters":
      "账号长度应该在2到12位之间",
    "Please enter a password, the length of which should not exceed 20 characters":
      "请输入密码,长度不超过20位",
    "Password length should be between 6 and 12 characters":
      "密码长度应该在6到12位之间",
    "Please enter password again": "请再次输入密码",
    "The two passwords do not match": "两次密码不一致",
    "Please input password": "请输入密码",
    "Please enter a name to search": "请输入名称进行搜索",
    "Please enter a name": "请输入名称",
    "Please input number": "请填写数量",
    "Please select target group": "请选择目标群组",
    "Please select account valid time": "请选择账号有效时间",
    "Please select function group": "请选择功能组",
    "Please select Availability": "请选择是否可用",
    "Please check the options you want to export first": "请先勾选要导出的选项",
    "Please enter a Remark": "请输入备注",
    "Group names can contain only Chinese alphanumeric underscores and no more than 11 characters":
      "群组名只能包含中文字母数字下划线,不超过11个字符",
    "Please enter the number of groups created in bulk":
      "请输入批量创建群组个数",
    "Less than two hundred words": "少于两百字",
      "Please fill in the account number": "请填写账号",
    "The account length should be between 2 and 11 digits":
      "长度应该在2到11位之间",
    "The account length should be between 2 and 16 digits":
      "长度应该在2到16位之间",
    "Are you sure to delete": "你确定删除吗",
    "Please tick first": "请先勾选",
    "The batch modification is successful": "批量修改成功!",
    "Specify the default group that users change": "请指定用户更改的默认群组",
    "Please enter the angent password": "请输入经销商密码",
    "Preferential accounts that have not reached three years will be deducted preferential coins, and three-year renewal coins will be deducted after three years":
      "优惠账号未满三年扣优惠币，已满三年扣三年续费币",
    "Please tick the function": "请勾选功能",
    "Please select a default group": "请选择默认群组",
    "Please select File Upload": "请选择文件上传",
    "Please enter 6-12 digits or a combination of numbers and English":
      "请输入6-12位数字或数字和英文组合",
    "Please enter the ICCID primary card": "请输入ICCID主卡",
    "Please enter 19~20 digits or a combination of numbers and English":
      "请输入19~20位数字或数字和英文组合",
    "Please enter the ICCID secondary card": "请输入ICCID副卡",
    "Please tick Users": "请勾选用户",
    "Whether to continue": "是否继续操作",
    success: "操作成功",
    "Batch renewal successful": "批量续费成功",
    "The modified account already exists": "修改的账号已存在",
    "Valid for less than 90 days": "有效时间不足90天",
    "Insufficient permissions": "权限不足",
    "Expiring/expired cannot be modified": "即将到期/已到期不能修改",
    "The account is about to expire or has expired, and remote number release is prohibited":
      "账号即将过期或已过期禁止远程放号",
    "Uploaded files can only be excel files, and are in xlsx, xls format":
      "上传文件只能为excel文件，且为xlsx,xls格式",
    "Allow child groups to report to the parent group": "允许子群组向父组汇报",
    "Groups that become child groups cannot be added as parent groups":
      "成为子群组的群组不能作为父群组添加子群组",
    "A member of a child group cannot be a member of the parent group at the same time, otherwise the user cannot form a parent-child relationship":
      "子组的成员不能同时是父组里的成员，否则用户无法构成父子关系",
    "Please enter an ID or name": "请输入ID或名称",
    "Please select a group": "请选择群组",
    "Please select the corresponding permissions": "请选择对应权限",
    "Please enter a 20-digit ICCID starting number":
      "请输入20位的iccid起始号码",
    "Please enter a 20-digit ICCID number": "请输入20位的ICCID号",
    "Please enter the number of ICCID numbers": "请输入iccid号码数量",
    "Please enter letters or numbers or a combination":
      "请输入字母或数字或组合",
    "The ICCID number should be 19 or 20 digits long":
      "iccid号码长度应为19或20位",
    "Enter the number of accounts you want to create": "请输入需要创建的账号数",
    "Batch binding IMEI is successful": "批量绑定IMEI成功",
    "If the remote number is successfully released, please start the computer to verify":
      "远程放号成功,请开机验证",
    "The dealer password was successfully modified": "经销商密码修改成功"
  },
  // 统计
  statistics: {
    statistics: "统计",
    threeyearsvirtualcurrency: "三年期虚拟币",
    available: "可用",
    used: "已用",
    total: "总数",
    totalnumofconsumable: "消耗币总数",
    PromotionalAccountRenewalCoin: "优惠账号续费币",
    Industrycurrency: "行业币",
    Permanentcurrency:"终身币",
    Oneyearvirtualcurrency: "一年期虚拟币",
    Oneyearrenewalcurrency: "一年期续费币",
    Threeyearrenewalcurrency: "三年期续费币",
    VIPrenewalcurrency: "VIP续费币",
    "Currency type": "币种类型",
    "Please select currency type": "请选择币种类型",
    Number: "数量",
    Remark: "备注",
    piece: "个",
    RenewCurrency: "续费币",
    availableIndustrycurrency: "可用行业币",
    "One year of renewal currency remaining": "剩余一年期续费币",
    "Three years of renewal currency remaining": "剩余三年期续费币",
    "Remaining promotional account renewal currency": "剩余优惠账号续费币",
    "Remaining VIP renewal currency": "剩余VIP续费币"
  },
  // 表单
  form: {
    functiongroup: "功能组",
    Assignfunctiongroups: "分配功能组",
    discountmonth: "优惠月数",
    groupName: "群组名字",
    Null: "Null",
    null: "无",
    Preferential: "优惠",
    ThreeYears: "三年期",
    OneYear: "一年期",
    Permanent: "终身",
    VIP: "VIP",
    "Basic package": "基础套餐",
    "Schedule packages": "调度套餐",
    "Video packages": "视频套餐",
    "GPS reporting cycle": "GPS上报周期",
    selecctfile: "选择文件",
    download: "模板下载",
    modifyagentpw: "修改经销商密码",
    agentpw: "经销商密码",
    oldagentpw: "旧经销商密码",
    newagentpw: "新经销商密码",
    renewaccount: "续费账号",
    mastarICCID: "ICCID主卡",
    secondICCID: "ICCID副卡",
    ICCIDLOGIN: "优先使用ICCID登录",
    YES: "是",
    childgroup: "子群组",
    Permission: "权限",
    "Group Call": "会话",
    "Switch Group": "切组",
    "Temp call": "单呼",
    "Across-groups temp call": "跨组单呼",
    IMEI: "IMEI",
    GPS: "GPS周期",
    Quantity: "账号数",
    "Initial Number": "账号起始数",
    "name starting number": "名称起始数",
    "Join group": "所属群组",
    Group: "群组",
    Selected: "已选",
    WhetherHyphen: "是否连号",
    "Serial number creation": "连号创建",
    "Created without hyphens": "非连号创建",
    "ICCID start number": "ICCID起始号",
    numofIccid: "ICCID数量"
  },
  adminlevel: {
    super: "系统管理员",
    admin: "总管理",
    Level1: "高级管理",
    Level2: "一级管理",
    Level3: "二级管理"
  }
};
