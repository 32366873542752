
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  getCurrentInstance
} from "vue";
import { useI18n } from "vue-i18n";
import { api } from "@/apis/user";
import { Time } from "@/utils/filter";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const { proxy } = getCurrentInstance() as any;
    const day = proxy.day;
    const dataMap = reactive({
      tableKey: 0,
      list: [],
      total: 0,
      name: null,
      listLoading: false,
      downloadLoading: false,
      value: "Option1",
      val: 10,
      time: Time,
      keyValue: {
        value: "",
        label: ""
      },
      value2: "",
      date: "",
      options: [
        {
          value: "",
          label: t("dashboard.all")
        },
        {
          value: "1",
          label: t("table.Issue")
        },
        {
          value: "2",
          label: t("table.Recover")
        }
      ],
      virtualOptions: [
        {
          value: "",
          label: t("dashboard.all")
        },
        {
          value: "11",
          label: t("statistics.Oneyearvirtualcurrency")
        },
        {
          value: "1",
          label: t("statistics.threeyearsvirtualcurrency")
        },
        {
          value: "12",
          label: t("statistics.Oneyearrenewalcurrency")
        },
        {
          value: "32",
          label: t("statistics.Threeyearrenewalcurrency")
        },
        {
          value: "5",
          label: t("statistics.VIPrenewalcurrency")
        },
        {
          value: "3",
          label: t("statistics.Industrycurrency")
        }
      ],
      // 分页查询参数
      listQuery: {
        page: {
          pageNo: 1,
          pageSize: 10
        },
        action: "",
        toName: "",
        startTime: "",
        endTime: "",
        type: ""
      },
      // 查询
      toSearch() {
        if (dataMap.date != "") {
          dataMap.listQuery.startTime = day(dataMap.date[0]).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          dataMap.listQuery.endTime = day(dataMap.date[1]).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          dataMap.getList(
            1,
            10,
            dataMap.listQuery.startTime,
            dataMap.listQuery.endTime,
            dataMap.listQuery.action,
            dataMap.listQuery.toName
          );
        }

        dataMap.getList(
          1,
          10,
          dataMap.listQuery.startTime,
          dataMap.listQuery.endTime,
          dataMap.listQuery.action,
          dataMap.listQuery.toName
        );
      },
      // 重置
      toReload() {
        dataMap.date = "";
        dataMap.listQuery.toName = "";
        dataMap.listQuery.action = "";
        dataMap.listQuery.startTime = "";
        dataMap.listQuery.endTime = "";
        dataMap.listQuery.type = "";
        dataMap.getList(1, 10);
      },
      // 分页
      handleSizeChange(val: any) {
        dataMap.val = val;
        dataMap.getList(1, val);
      },
      handleCurrentChange(page: any) {
        dataMap.getList(page, dataMap.val);
      },
      // 页面初始化数据
      async getList(
        page?: any,
        pageSize?: any,
        startTime?: any,
        endTime?: any,
        action?: any,
        toName?: any
      ) {
        if (page) {
          dataMap.listQuery.page.pageNo;
        }
        if (pageSize) {
          dataMap.listQuery.page.pageSize = pageSize;
        }
        if (startTime) {
          dataMap.listQuery.startTime = startTime;
        }
        if (endTime) {
          dataMap.listQuery.endTime = endTime;
        }
        dataMap.listQuery.page.pageNo = page;
        dataMap.listQuery.page.pageSize = pageSize;
        // dataMap.listQuery.userLoginEntity
        dataMap.listLoading = true;
        const data: any = await api.list.virtuaList(dataMap.listQuery);
        dataMap.list = data?.data.data.resultList;
        dataMap.total = data?.data.data.totalCount;
        setTimeout(() => {
          dataMap.listLoading = false;
        }, 0.3 * 1000);
      }
    });
    onMounted(() => {
      dataMap.getList(1, 10);
    });

    return {
      t,
      ...toRefs(dataMap)
    };
  }
});
