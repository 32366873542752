
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { api } from "@/apis/user";
import { defaultAdminModel } from "@/model/admin";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    // 确认密码验证
    const validatePass2 = (rule: any, value: any, callback: any) => {
      if (value === "") {
        callback(new Error(t("input.Please enter password again")));
      } else if (value !== dataMap.postForm.password) {
        callback(new Error(t("input.The two passwords do not match")));
      } else {
        callback();
      }
    };
    // 表单验证
    const rules = reactive({
      loginName: [
        {
          required: true,
          message: t("input.Please fill in the account number"),
          trigger: "blur"
        },
        {
          min: 2,
          max: 12,
          message: t(
            "input.Account length should be between 2 and 12 characters"
          ),
          trigger: "blur"
        }
      ],
      password: [
        {
          required: true,
          message: t("input.Please input password"),
          trigger: "blur"
        },
        {
          min: 6,
          max: 20,
          message: t(
            "input.Password length should be between 6 and 12 characters"
          ),
          trigger: "blur"
        }
      ],
      confirmPassword: [
        { required: true, validator: validatePass2, trigger: "blur" }
      ]
    });

    const detailFrom = ref();
    const router = useRoute();
    const routerList = useRouter();

    //数据源
    const dataMap = reactive({
      postForm: Object.assign({}, defaultAdminModel),
      router: router,
      checkAll: false,
      isIndeterminate: true,
      cities: [] as any[],
      options: [
        {
          value: "1",
          label: t("table.allow")
        },
        {
          value: "0",
          label: t("table.not allowed")
        }
      ],
      loginName: ref(),
      // 提交表单
      async submit() {
        console.log(detailFrom);
        if (dataMap.loginName === undefined) {
          detailFrom.value.validate(async (valid: any) => {
            if (valid) {
              const status: any = await api.revise.adminadd(dataMap.postForm);
              if (status.data.status === 200) {
                detailFrom.value.resetFields();
                ElMessage.success(t("table.modify success"));
                routerList.push({ path: "/adminlist" });
              }
            } else {
              return false;
            }
          });
        } else {
          const status: any = await api.revise.adminupdate(dataMap.postForm);
          console.log(status);
          if (status.data.status === 200) {
            ElMessage.success(t("table.success"));
            routerList.push({ path: "/adminlist" });
          }
        }
      },
      // 获取修改的数据
      async update(loginName: string) {
        const dataForm: any = await api.detail.adminfindDetail({
          loginName: loginName
        });
        const { data } = dataForm.data;
        dataMap.postForm = data;
      },
      async handleCheckAllChange(val: boolean) {
        console.log(val);
        console.log(dataMap.cities);
        const newCiteites = dataMap.cities.map(item => {
          return item.id;
        });
        console.log(newCiteites);
        dataMap.postForm.permissionIds = val ? newCiteites : [];
        dataMap.isIndeterminate = false;
        console.log(dataMap.postForm.permissionIds);
      },
      async handleCheckedCitiesChange(value: string[]) {
        const checkedCount = value.length;
        dataMap.checkAll = checkedCount === dataMap.cities.length;
        dataMap.isIndeterminate =
          checkedCount > 0 && checkedCount < dataMap.cities.length;
        console.log(dataMap.postForm.permissionIds);
      },
      async getPermissionList() {
        const status: any = await api.revise.adminPermissionList({});
        dataMap.cities = status.data.data;
        console.log(status.data.data);
      }
    });
    onMounted(() => {
      const loginName: any = dataMap.router.query.loginName;
      dataMap.loginName = loginName;
      dataMap.postForm.permissionIds = [
        "2c1bff6c5d93479b9d6e9d841a98bd81",
        "443fa6ee8e6a4551badba35c3e8cfe0c",
        "c9c0e046ce9741ce9feab15695199839",
        "dd4e7b9e5023496c9c3f61eeb9ef8222",
        "e1d90a7ce30c43178feb4750efae623c",
        "e63fd8b132084d9babd16862fdeb65bd"
      ];
      dataMap.getPermissionList();
      console.log(loginName);
      if (loginName !== undefined) {
        dataMap.update(loginName);
      }
    });
    return {
      ...toRefs(dataMap),
      rules,
      detailFrom,
      routerList
    };
  }
});
