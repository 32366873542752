
import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
import { useI18n } from "vue-i18n";
import { api } from "@/apis/user";
import { formatJson } from "@/utils";
import { exportJson2Excel } from "@/utils/excel";
import { ElMessage } from "element-plus";
import axios from "axios";
import { getLanguage } from "@/utils/cookies";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const dialogFormtoggle = ref(false);
    const dataMap = reactive({
      tableKey: 0,
      list: [],
      total: 0,
      name: null,
      listLoading: false,
      fileList: {},
      downloadLoading: false,
      val: 10,
      toggle: {
        adminId: "",
        domain: ""
      },
      filename: "",
      downloadList: [
        {
          iccid: "89860424212070034825",
          dealerCode: "01",
          dealerName: "卡商A"
        },
        { iccid: "89860424212070034826", dealerCode: "01", dealerName: "卡商A" }
      ],
      // 分页查询参数
      listQuery: {
        page: {
          pageNo: 1,
          pageSize: 10
        },
        iccid: ""
      },
      httpRequest(item: any) {
        if (!/\.(xlsx|xls|XLSX|XLS)$/.test(item.file.name)) {
          ElMessage.error("上传文件只能为excel文件，且为xlsx,xls格式");
        } else {
          dataMap.fileList = item.file;
          dataMap.importData();
        }
      },
      importData() {
        const formData: any = new FormData();
        formData.append("file", dataMap.fileList);
        axios
          .post(`${process.env.VUE_APP_BASE_API}api/iccid/import`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "pc-access-token": localStorage.getItem("token"),
              lang: getLanguage(),
              "manager-web-session": localStorage.getItem("tokensession")
            }
          })
          .then(res => {
            console.log(res);
            if (res.data.status !== 200) {
              ElMessage.error(res.data.message);
            } else {
              ElMessage.success("导入成功");
              dialogFormtoggle.value = false;
            }
          });
      },
      // 查询
      toSearch() {
        dataMap.getList(1, 10);
      },
      // 导入
      toUpload() {
        dialogFormtoggle.value = true;
      },
      // 导出模板
      handleDownload() {
        // const from = unref(multipleTableNode)
        dataMap.downloadLoading = true;
        const tHeader = ["ICCID(唯一,区分大小写)", "卡商编码", "卡商名称"];
        const filterVal = ["iccid", "dealerCode", "dealerName"];
        const list = dataMap.downloadList;
        const data = formatJson(filterVal, list);
        exportJson2Excel(
          tHeader,
          data,
          dataMap.filename !== "" ? dataMap.filename : undefined
        );
        // from.clearSelection()
        dataMap.downloadLoading = false;
      },
      // 分页
      handleSizeChange(val: any) {
        dataMap.val = val;
        dataMap.getList(1, val);
      },
      handleCurrentChange(page: any) {
        dataMap.getList(page, dataMap.val);
      },

      // 页面初始化数据
      async getList(page?: any, pageSize?: any) {
        if (page) {
          dataMap.listQuery.page.pageNo;
        }
        if (pageSize) {
          dataMap.listQuery.page.pageSize = pageSize;
        }
        dataMap.listQuery.page.pageNo = page;
        dataMap.listQuery.page.pageSize = pageSize;
        dataMap.listLoading = true;
        const data: any = await api.list.iccidList(dataMap.listQuery);
        dataMap.list = data?.data.data.resultList;
        dataMap.total = data?.data.data.totalCount;
        setTimeout(() => {
          dataMap.listLoading = false;
        }, 0.3 * 1000);
      }
    });
    onMounted(() => {
      dataMap.getList(1, 10);
    });

    return {
      t,
      ...toRefs(dataMap),
      dialogFormtoggle
    };
  }
});
