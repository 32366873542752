/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-04-19 09:51:01
 * @LastEditors: fugang
 * @LastEditTime: 2022-04-19 10:03:17
 */
// request.ts
import axios from './https'
import qs from 'qs'

export class Request {
  /**
   * get方法
   * @param {string} url 路径
   * @param {object} params 参数
   */
  static get = (url: string, params?: any) => {
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  static post = (url: string, params?: any) => {
    return new Promise((resolve, reject) => {
      axios.post(url, params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
