import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_table = _resolveComponent("el-table")
  const _component_el_pagination = _resolveComponent("el-pagination")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_dialog = _resolveComponent("el-dialog")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_table, {
      data: _ctx.list,
      key: _ctx.tableKey,
      border: "",
      fit: "",
      "highlight-current-row": "",
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('table.Account')
        }, {
          default: _withCtx(({row}) => [
            _createTextVNode(_toDisplayString(row.account), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('table.name')
        }, {
          default: _withCtx(({row}) => [
            _createTextVNode(_toDisplayString(row.name), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, { label: "Imei" }, {
          default: _withCtx(({row}) => [
            _createVNode(_component_el_input, {
              maxlength: "16",
              modelValue: row.imei,
              "onUpdate:modelValue": ($event: any) => (row.imei = $event),
              disabled: row.type == 1 || row.type == -1,
              oninput: "value=value.replace(/[^A-Za-z.][^0-9.]/g,'')"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]), [
      [_directive_loading, _ctx.listLoading]
    ]),
    _withDirectives(_createVNode(_component_el_pagination, {
      total: _ctx.total,
      currentPage: _ctx.listQuery.page.pageNo,
      "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => (_ctx.listQuery.page.pageNo = $event)),
      limit: _ctx.listQuery.page.pageSize,
      "onUpdate:limit": _cache[2] || (_cache[2] = ($event: any) => (_ctx.listQuery.page.pageSize = $event)),
      onSizeChange: _ctx.handleSizeChange,
      onCurrentChange: _ctx.handleCurrentChange,
      "page-sizes": [10, 20, 50, 100],
      layout: "total, sizes, prev, pager, next, jumper"
    }, null, 8, ["total", "currentPage", "limit", "onSizeChange", "onCurrentChange"]), [
      [_vShow, _ctx.total > 0]
    ]),
    _createVNode(_component_el_button, {
      type: "primary",
      onClick: _ctx.handleShowAgent
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('table.confirm')), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.showDialogAgent,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.showDialogAgent = $event)),
      title: _ctx.$t('form.agentpw'),
      width: "40%"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_el_button, {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDialogAgent = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('table.cancel')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, { onClick: _ctx.submit }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('table.confirm')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref: "jxsref",
          model: _ctx.jxsfrom
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('form.agentpw'),
              prop: "agentpwd"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.jxsfrom.agentpwd,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.jxsfrom.agentpwd = $event)),
                  maxlength: "12"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ]))
}