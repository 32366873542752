/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-05-06 09:21:57
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-11 15:17:42
 */
/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-05-05 09:51:37
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-05 19:55:10
 */

import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const ExampleRouter: Array<RouteRecordRaw> = [

  {
    path: '/parametersettings',
    component: Layout,
    redirect: 'noredirect',
    meta: {
      title: 'parametersettingsPages',
      icon: '#icon-caidan',
      roles: ['系统管理员'],
    },
    children: [
      {
        path: '/funclist',
        component: () => import('@/views/parametersettings/funclist.vue'),
        name: 'deploymentPage',
        meta: {
          title: 'funclist',
          icon: '#icon-gongneng',
          noCache: true
        }
      },
      {
        path: '/funcgrouplist',
        component: () => import('@/views/parametersettings/funcgrouplist.vue'),
        name: 'domainPage',
        meta: {
          title: 'funcgrouplist',
          noCache: true,
          icon: '#icon-liebiao'
        }
      },
      {
        path: '/fucgroupadd',
        component: () => import('@/views/parametersettings/fucgroupadd.vue'),
        name: 'fucgroupaddPage',
        meta: {
          title: 'fucgroupadd',
          hidden: true,
          noCache: true,

        }
      }
    ]
  }
]

export default ExampleRouter
