/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-07 10:30:20
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-11 10:03:56
 */
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw
} from "vue-router";
import Layout from "@/layout/Index.vue";

const constantFiles = require.context("./constantModules", true, /\.ts$/);
let constantModules: Array<RouteRecordRaw> = [];
constantFiles.keys().forEach(key => {
  if (key === "./index.ts") return;
  constantModules = constantModules.concat(constantFiles(key).default);
});

const asyncFiles = require.context("./permissionModules", true, /\.ts$/);
let permissionModules: Array<RouteRecordRaw> = [];
asyncFiles.keys().forEach(key => {
  if (key === "./index.ts") return;
  permissionModules = permissionModules.concat(asyncFiles(key).default);
});

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: "/redirect",
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () =>
          import(
            /* webpackChunkName: "redirect" */ "@/views/redirect/Index.vue"
          )
      }
    ]
  },
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/dashboard/Index.vue"
          ),
        name: "Dashboard",
        meta: {
          title: "dashboard",
          icon: "#icondashboard",
          affix: true
        }
      }
    ]
  },
  ...constantModules
];

export const asyncRoutes: Array<RouteRecordRaw> = [...permissionModules];
const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
});

export function resetRouter() {
  const newRouter = router;
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
