
import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  getCurrentInstance,
  ref,
  toRef
} from "vue";
import { useI18n } from "vue-i18n";
import { api } from "@/apis/user";
import { ElMessage } from "element-plus";
interface interfaceRole {
  data: {
    id: any;
  }[];
  roleListSelect: {
    id: any;
    name: any;
    children: any;
    hasPermission: any;
    href: any;
    createBy: any;
    createDate: any;
    icon: any;
    isShow: any;
    parent: any;
    parentId: any;
    parentIds: any;
    permission: any;
    remarks: any;
    sort: any;
    target: any;
    updateBy: any;
    updateDate: any;
    userId: any;
  }[];
}
interface Tree {
  name: string;
  id: any;
}

export default defineComponent({
  setup() {
    const props1 = {
      multiple: true,
      value: "id",
      label: "name",
      children: "children"
    };
    const { t } = useI18n();
    const { proxy } = getCurrentInstance() as any;
    const distributeForm = reactive({
      form: {
        name: "",
        enname: "",
        roleType: "",
        useable: ""
      },
      roleform: {
        parentId: ""
      }
    });

    const roleListSelectIds = reactive<interfaceRole>({
      data: [],
      roleListSelect: []
    });
    const formdistribute = ref();
    const rules = reactive({
      name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      enname: [{ required: true, message: "请输入英文名称", trigger: "blur" }],
      roleType: [
        { required: true, message: "请选择角色类型", trigger: "change" }
      ],
      useable: [
        { required: true, message: "请选择是否可用", trigger: "change" }
      ]
    });
    const dialogFormdistribute = ref(false);
    const dialogFormrole = ref(false);
    const dataMap = reactive({
      tableKey: 0,
      list: [],
      id: "",
      total: 0,
      name: null,
      title: "",
      listLoading: false,
      // 分页查询参数
      rolelist: [
        { value: "0", label: "系统管理员" },
        { value: "1", label: "总管" },
        { value: "2", label: "高级管理" },
        { value: "3", label: "一级管理" },
        { value: "4", label: "二级管理" }
      ],
      ableList: [
        { value: "1", label: "可用" },
        { value: "0", label: "不可用" }
      ],
      // 添加
      toAdd() {
        dataMap.title = "添加";
        distributeForm.form = Object.assign({});
        dialogFormdistribute.value = true;
      },
      // 修改
      handleEdit(index: any, row: any) {
        dataMap.title = "修改";
        distributeForm.form = row;
        dialogFormdistribute.value = true;
      },
      // 权限设置
      async rolesetting(row: any) {
        dialogFormrole.value = true;
        distributeForm.roleform = row;
        dataMap.id = row.id;
        const dataRole: any = await api.list.rolemenufind({ roleId: row.id });
        roleListSelectIds.roleListSelect = dataRole?.data.data;
        let roleList: any = dataRole?.data.data;
        for (let i of roleList) {
          if (i.hasPermission == "1") {
            //有权限是1 没权限是0
            roleListSelectIds.data.push(i?.id);
          }
        }
      },
      // 删除
      async handleDelete(row: any) {
        const { data }: any = await api.revise.roledelete({ id: row.id });
        if (data.status === 200) {
          ElMessage.success("删除成功");
          dataMap.getList();
        }
      },

      // 提交
      submitDisForm() {
        formdistribute.value.validate(async (valid: any) => {
          if (valid) {
            if (dataMap.title === "添加") {
              const { data }: any = await api.revise.roleadd(
                distributeForm.form
              );
              if (data.status === 200) {
                ElMessage.success("添加成功");
                dialogFormdistribute.value = false;
                dataMap.getList();
              }
            } else if (dataMap.title === "修改") {
              const { data }: any = await api.revise.roleupdate(
                distributeForm.form
              );
              if (data.status === 200) {
                ElMessage.success("修改成功");
                dialogFormdistribute.value = false;
                dataMap.getList();
              }
            }
          } else {
            return false;
          }
        });
      },
      handleCheckChange(data: Tree) {
        for (let i = 0; i < roleListSelectIds.roleListSelect.length; i++) {
          if (roleListSelectIds.roleListSelect[i].id == data.id) {
            roleListSelectIds.roleListSelect[i].hasPermission = "1";
          }
          for (
            let j = 0;
            j < roleListSelectIds.roleListSelect[i].children.length;
            j++
          ) {
            console.log(roleListSelectIds.roleListSelect[i].children[j]);
            if (roleListSelectIds.roleListSelect[i].children[j].id == data.id) {
              roleListSelectIds.roleListSelect[i].children[j].hasPermission =
                "1";
            }
            for (
              let k = 0;
              k <
              roleListSelectIds.roleListSelect[i].children[j].children.length;
              k++
            ) {
              if (
                roleListSelectIds.roleListSelect[i].children[j].children[k]
                  .id == data.id
              ) {
                roleListSelectIds.roleListSelect[i].children[j].children[
                  k
                ].hasPermission = "1";
              }
            }
          }
        }
      },
      // 权限设置
      async submitDisRole() {
        const { data }: any = await api.revise.roleauth({
          roleId: dataMap.id,
          menus: roleListSelectIds.roleListSelect
        });
        if (data.status === 200) {
          ElMessage.success("修改成功");
          dialogFormrole.value = false;
        }
      },
      // 页面初始化数据
      async getList(name?: any) {
        if (name) {
          dataMap.name = name;
        }
        dataMap.listLoading = true;
        const data: any = await api.list.roleList({ name: dataMap.name });

        dataMap.list = data?.data.data;

        setTimeout(() => {
          dataMap.listLoading = false;
        }, 0.3 * 1000);
      }
    });
    onMounted(() => {
      dataMap.getList();
    });

    return {
      t,
      ...toRefs(dataMap),
      ...toRefs(distributeForm),
      dialogFormdistribute,
      formdistribute,
      rules,
      roleListSelectIds,
      dialogFormrole,
      props1
    };
  }
});
