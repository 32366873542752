
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { CountTo } from 'vue3-count-to'
import messages from '@/assets/images/home/messages.png'
import shoppings from '@/assets/images/home/shoppings.png'
import visits from '@/assets/images/home/visits.png'
import offline from '@/assets/images/home/offline.png'
import { useI18n } from 'vue-i18n'
import { api } from '@/apis/user'
import { number } from '@intlify/core-base'
export default defineComponent({
  components: {
    CountTo
  },
  emits: ['handle-set-line-chart-data'],

  setup(_, { emit }) {
    const handleSetLineChartData = (type: string) => {
      emit('handle-set-line-chart-data', type)
    }
    const dataMap = reactive({
      data: {
        registerCount: null,
        onlineCount: null,
        surplusCount: null,
        oneSurplusCount: null,
        onlinefalse: 0
      }
    })
    const myCount = ref(null)
    const { t } = useI18n()
    const getData = async() => {
      const detailData: any = await api.detail.aggrefateInfo()
      dataMap.data = detailData.data.data
      const registerCount = Number(dataMap.data.registerCount)
      const onlineCount = Number(dataMap.data.onlineCount)
      let acount = 0
      acount = registerCount - onlineCount
      dataMap.data.onlinefalse = Number(acount)
    }
    onMounted(() => {
      getData()
    })

    return {
      handleSetLineChartData,
      myCount,
      messages,
      visits,
      offline,
      shoppings,
      dataMap,
      t
    }
  }
})
