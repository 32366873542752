/*
 * @Description: user mutations type
 * @Author: ZY
 * @Date: 2020-12-23 10:25:37
 * @LastEditors: fugang
 * @LastEditTime: 2022-04-07 09:38:49
 */

export enum UserMutationTypes {
  SET_TOKEN = 'SET_TOKEN',
  SET_NAME = 'SET_NAME',
  SET_AVATAR = 'SET_AVATAR',
  SET_INTRODUCTION = 'SET_INTRODUCTION',
  SET_ROLES = 'SET_ROLES',
  SET_EMAIL = 'SET_EMAIL',
  SET_SESSION = 'SET_SESSION'
}
