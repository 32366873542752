
import { defineComponent, onMounted, reactive, toRefs, getCurrentInstance, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { api } from '@/apis/user'
import { Time } from '@/utils/filter'
import { ElMessage } from 'element-plus'
export default defineComponent({
  setup () {

    const { t } = useI18n()
    const { proxy } = getCurrentInstance() as any;
    const day = proxy.day
    const distributeForm = reactive({
      form: {
        workState: '',
        name: '',
        ddosIp: '',
        ip: '',
        id: '',
        svrtype: '0',
        amount: 0,
        remarks: '',
      },
      toggle: {
        loginName: '',
        oldIp: '',
        newIp: '',
      }
    })
    const formdistribute = ref()
    const formToggle = ref()
    const rulesFormdistribute = reactive({
      name: [
        { required: true, message: '请输入服务器名字', trigger: 'blur' }
      ],
      ip: [
        { required: true, message: '请输入ip地址', trigger: 'blur' }
      ],
      amount: [
        { required: true, message: '请输入总容量', trigger: 'blur' }
      ],
    })
    const rulesFormToggle = reactive({

      oldIp: [
        { required: true, message: '请选择旧的ip地址', trigger: 'change' }
      ],
      newIp: [
        { required: true, message: '请选择新的ip地址', trigger: 'change' }
      ],
    })
    const dialogFormdistribute = ref(false)
    const dialogFormtoggle = ref(false)
    const dataMap = reactive({
      tableKey: 0,
      list: [],
      total: 0,
      name: null,
      title: '',
      listLoading: false,
      time: Time,
      workState: '',
      options: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '停止'
        },
        {
          value: 2,
          label: '运行'
        }
      ],
      // 分页查询参数
      listQuery: {

      },
      // 查询
      toSearch () {
        dataMap.getList(dataMap.workState)

      },
      toReload () {
        dataMap.workState = ''
        dataMap.getList('')
      },
      // 添加
      toAdd () {
        dataMap.title = '添加'
        distributeForm.form = Object.assign({})
        dialogFormdistribute.value = true

      },
      // 切换目标服务器
      toTogger () {
        dialogFormtoggle.value = true
      },
      // 修改
      handleEdit (index: any, row: any) {
        dataMap.title = '修改'
        distributeForm.form = row
        dialogFormdistribute.value = true

      },
      // 删除
      async handleDelete (row: any) {
        const { data }: any = await api.revise.serverdelete({ id: row.id })
        if (data.status === 200) {
          ElMessage.success('删除成功')
          dataMap.getList('')
        }
      },
      // 同步数据
      async handleSync (row: any) {
        const { data }: any = await api.revise.serversync({ ip: row.ip })
        if (data.status === 200) {
          ElMessage.success('同步成功')
          dataMap.getList('')
        }
      },
      async handelSelectAdminIp () {
        console.log('查ip?', distributeForm.toggle)
        if(distributeForm.toggle.loginName !== ''){
          const { data }: any = await api.revise.servergetip({loginName: distributeForm.toggle.loginName})
          if(data.status === 200) {
            distributeForm.toggle.oldIp = data.data.ip 
          }
        }

      },

      // 提交目标服务器
      submitDisToggle () {
        formToggle.value.validate(async (valid: any) => {
          if (valid) {
            const { data }: any = await api.revise.servertransfer(distributeForm.toggle)
            if (data.status === 200) {
              ElMessage.success('切换目标服务器成功')
              dataMap.getList('')
              dialogFormtoggle.value = false
            }
          } else {
            return false
          }
        })
      },
      // 提交
      submitDisForm () {
        formdistribute.value.validate(async (valid: any) => {
          if (valid) {
            if (dataMap.title === '添加') {
              const { data }: any = await api.revise.serveadd(distributeForm.form)
              if (data.status === 200) {
                ElMessage.success('添加成功')
                formdistribute.value.resetFields()
                dialogFormdistribute.value = false
                dataMap.getList('')
              }
            } else if (dataMap.title === '修改') {
              const { data }: any = await api.revise.serverupdate(distributeForm.form)
              if (data.status === 200) {
                ElMessage.success('修改成功')
                dialogFormdistribute.value = false
                formdistribute.value.resetFields()

                dataMap.getList('')
              }
            }
          } else {
            return false
          }
        })
      },
      // 页面初始化数据
      async getList (workState: any) {
        dataMap.listLoading = true
        const data: any = await api.list.serverList({ workState: workState })
        dataMap.list = data?.data.data

        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.3 * 1000)
      }
    })
    onMounted(() => {
      dataMap.getList('')
    })

    return {
      t, ...toRefs(dataMap), ...toRefs(distributeForm), dialogFormdistribute, dialogFormtoggle, formdistribute, formToggle, rulesFormdistribute, rulesFormToggle
    }
  }
})
