/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-04-25 09:47:06
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-13 16:09:54
 */

import { RouteRecordRaw } from "vue-router";
import Layout from "@/layout/Index.vue";

const ExampleRouter: Array<RouteRecordRaw> = [
  {
    path: "/admin",
    component: Layout,
    redirect: "noredirect",
    meta: {
      title: "administrator",
      icon: "#icon-guanliyuan",
      roles: ["系统管理员", "总管理", "高级管理", "一级管理"]
    },
    children: [
      {
        path: "/adminlist",
        component: () =>
          import(
            /* webpackChunkName: "error-page-401" */ "@/views/administrator/adminlist.vue"
          ),
        name: "PageAdmin",
        meta: {
          title: "adminlist",
          icon: "#icon-permission",
          noCache: true
        }
      },
      {
        path: "/adminlistsub",
        component: () =>
          import(
            /* webpackChunkName: "error-page-401" */ "@/views/administrator/adminlistsub.vue"
          ),
        name: "PageAdminSub"
      },
      {
        path: "/sublist2",
        component: () =>
          import(
            /* webpackChunkName: "error-page-401" */ "@/views/administrator/adminsublist2.vue"
          ),
        name: "PageAdminSub2"
      },
      {
        path: "/sublist3",
        component: () =>
          import(
            /* webpackChunkName: "error-page-401" */ "@/views/administrator/adminsublist3.vue"
          ),
        name: "PageAdminSub3"
      },
      {
        path: "/adminadd",
        component: () =>
          import(
            /* webpackChunkName: "error-page-404" */ "@/views/administrator/adminadd.vue"
          ),
        name: "PageAdminAdd",
        meta: {
          title: "adminadd",
          icon: "#icon-tianjiatianchong",
          noCache: true
        }
      }

      // {
      //   path: '/transfervirtual',
      //   component: () => import(/* webpackChunkName: "error-page-404" */ '@/views/administrator/transfervirtual.vue'),
      //   name: 'PageTransfer',
      //   meta: {
      //     title: 'transfervirtual',
      //     noCache: true,
      //     icon: '#icon-zhuanhuan',
      //     roles: ['总管理']
      //   }
      // }
    ]
  }
];

export default ExampleRouter;
