/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-04-20 10:26:24
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-11 12:08:06
 */
/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-04-19 10:45:04
 * @LastEditors: fugang
 * @LastEditTime: 2022-04-19 15:13:21
 */

import { RouteRecordRaw } from "vue-router";
import Layout from "@/layout/Index.vue";

const ExampleRouter: Array<RouteRecordRaw> = [
  {
    path: "/logging",
    component: Layout,
    redirect: "noredirect",
    meta: {
      title: "logging",
      icon: "#icon-rizhi"
    },
    children: [
      {
        path: "/changelog",
        component: () =>
          import(
            /* webpackChunkName: "error-page-401" */ "@/views/logging/changelog.vue"
          ),
        name: "changelog",
        meta: {
          title: "changelog",
          noCache: true,
          roles: ["系统管理员"],
          icon: "#icon-zhuanhuan"
        }
      },
      {
        path: "/logsearch",
        component: () =>
          import(
            /* webpackChunkName: "error-page-401" */ "@/views/logging/logsearch.vue"
          ),
        name: "logsearch",
        meta: {
          title: "logsearch",
          icon: "#icon-chaxun",
          noCache: true
        }
      },
      {
        path: "/statistics",
        component: () =>
          import(
            /* webpackChunkName: "error-page-401" */ "@/views/logging/statistics.vue"
          ),
        name: "statistics",
        meta: {
          title: "statistics",
          roles: ["系统管理员"],
          icon: "#icon-tongji1",
          noCache: true
        }
      },
      // {
      //   path: '/billinglog',
      //   component: () => import(/* webpackChunkName: "error-page-401" */ '@/views/logging/billinglog.vue'),
      //   name: 'billinglog',
      //   meta: {
      //     title: 'billinglog',
      //     noCache: true
      //   }
      // },
      {
        path: "/virtualrecord",
        component: () => import("@/views/logging/virtualrecord.vue"),
        name: "virtualCurrencyRecord",
        meta: {
          title: "virtualCurrencyRecord",
          icon: "#icon-lishijilu",
          noCache: true
        }
      },
      {
        path: "/virtualdetails",
        component: () => import("@/views/logging/virtualdetails.vue"),
        name: "virtualDetails",
        meta: {
          title: "virtualDetails",
          icon: "#icon-lishijilu",
          noCache: true
        }
      },
      {
        path: "/userloginlog",
        component: () => import("@/views/logging/userloginlog.vue"),
        name: "userLoginlog",
        meta: {
          title: "userLoginlog",
          icon: "#icon-lishijilu",
          noCache: true,
          roles: ["系统管理员", "二级管理"]
        }
      }
    ]
  }
];

export default ExampleRouter;
