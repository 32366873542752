
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
  nextTick,
  toRefs
} from "vue";
import LangSelect from "@/components/lang_select/Index.vue";
import { isValidUsername } from "@/utils/validate";
import { useRoute, LocationQuery, useRouter } from "vue-router";
import { useStore } from "@/store";
import { UserActionTypes } from "@/store/modules/user/action-types";
import { useI18n } from "vue-i18n";
export default defineComponent({
  components: {
    LangSelect
  },
  setup() {
    const userNameRef = ref(null);
    const passwordRef = ref(null);
    const loginFormRef = ref(null);
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();
    const state = reactive({
      loginForm: {
        loginName: "",
        password: ""
      },
      loginRules: {
        loginName: [{ validator: userNameRef, trigger: "blur" }],
        password: [{ validator: passwordRef, trigger: "blur" }]
      },
      passwordType: "password",
      loading: false,
      showDialog: false,
      capsTooltip: false,
      redirect: "",
      otherQuery: {},
      isRemember: false
    });

    const methods = reactive({
      validateUsername: (rule: any, value: string, callback: Function) => {
        if (!isValidUsername(value)) {
          callback(new Error("Please enter the correct user name"));
        } else {
          callback();
        }
      },
      validatePassword: (rule: any, value: string, callback: Function) => {
        if (value.length < 6) {
          callback(new Error("The password can not be less than 6 digits"));
        } else {
          callback();
        }
      },
      checkCapslock: (e: KeyboardEvent) => {
        const { key } = e;
        state.capsTooltip =
          key !== null && key.length === 1 && key >= "A" && key <= "Z";
      },
      showPwd: () => {
        if (state.passwordType === "password") {
          state.passwordType = "";
        } else {
          state.passwordType = "password";
        }
        nextTick(() => {
          (passwordRef.value as any).focus();
        });
      },
      
      // 进行登录
      handleLogin: () => {
        (loginFormRef.value as any).validate(async (valid: boolean) => {
          console.log('?',state.isRemember)
          if(state.isRemember){
            localStorage.setItem('rememberedUsername', state.loginForm.loginName);
            localStorage.setItem('rememberedPassword', state.loginForm.password);
          } else {
            localStorage.removeItem('rememberedUsername');
            localStorage.removeItem('rememberedPassword');
          }
          if (valid) {
            state.loading = true;
            await store.dispatch(UserActionTypes.ACTION_LOGIN, state.loginForm);
            router
              .push({
                path: state.redirect || "/",
                query: state.otherQuery
              })
              .catch(err => {
                console.warn(err);
              });
            // Just to simulate the time of the request
            setTimeout(() => {
              state.loading = false;
            }, 0.3 * 1000);
          } else {
            return false;
          }
        });
      }
    });

    function getOtherQuery(query: LocationQuery) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {} as LocationQuery);
    }

    watch(
      () => route.query,
      query => {
        if (query) {
          state.redirect = query.redirect?.toString() ?? "";
          state.otherQuery = getOtherQuery(query);
        }
      }
    );

    onMounted(() => {
      const rememberedUsername = localStorage.getItem('rememberedUsername');
      const rememberedPassword = localStorage.getItem('rememberedPassword');
      if (rememberedUsername && rememberedPassword) {
        state.loginForm.loginName = rememberedUsername;
        state.loginForm.password = rememberedPassword;
        state.isRemember = true;
      }
      if (state.loginForm.loginName === "") {
        (userNameRef.value as any).focus();
      } else if (state.loginForm.password === "") {
        (passwordRef.value as any).focus();
      }
    });

    return {
      userNameRef,
      passwordRef,
      loginFormRef,
      ...toRefs(state),
      ...toRefs(methods),
      t
    };
  }
});
