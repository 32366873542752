/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-28 11:26:33
 * @LastEditors: fugang
 * @LastEditTime: 2022-04-07 09:40:33
 */
import { getSession, getToken } from '@/utils/cookies'

export interface UserState {
  token: string
  name: string
  avatar: string
  introduction: string
  roles: string[]
  email: string
  managerWebSession: string
}

export const state: UserState = {
  token: getToken() || '',
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  email: '',
  managerWebSession: getSession() || ''
}
