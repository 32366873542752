
import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
import { useI18n } from "vue-i18n";
import { api } from "@/apis/user";
import { ElMessage } from "element-plus";
import { Time } from "@/utils/filter";
import { useRouter } from "vue-router";
export default defineComponent({
  setup() {
    const { t } = useI18n();
    const dialogFormtoggle = ref(false);
    const router = useRouter();
    const dataMap = reactive({
      tableKey: 0,
      list: [],
      total: 0,
      name: null,
      listLoading: false,
      downloadLoading: false,
      time: Time,

      toggle: {
        name: "",
        funcCode: ""
      },
      // 分页查询参数
      listQuery: {
        page: {
          pageNo: 1,
          pageSize: 10
        }
      },
      // 添加
      toAdd() {
        router.push({ path: "/fucgroupadd" });
      },

      // 修改
      handleEdit(row: any) {
        router.push({
          path: "/fucgroupadd",
          query: {
            id: row.id
          }
        });
      },
      // 查询
      toSearch() {
        dataMap.getList(1, 10);
      },

      // 分页
      handleSizeChange(val: any) {
        dataMap.getList(1, val);
      },
      handleCurrentChange(page: any) {
        dataMap.getList(page);
      },
      async submitDisToggle() {
        const { data }: any = await api.table.domainset(dataMap.toggle);
        if (data.status === 200) {
          ElMessage.success("添加成功");
          dataMap.getList("");
        }
      },
      // 页面初始化数据
      async getList(page?: any, pageSize?: any) {
        if (page) {
          dataMap.listQuery.page.pageNo;
        }
        if (pageSize) {
          dataMap.listQuery.page.pageSize = pageSize;
        }
        dataMap.listQuery.page.pageNo = page;
        dataMap.listQuery.page.pageSize = pageSize;
        dataMap.listLoading = true;
        const data: any = await api.list.funcgroupList(dataMap.listQuery);
        dataMap.list = data?.data.data.resultList;
        dataMap.total = data?.data.data.totalCount;
        setTimeout(() => {
          dataMap.listLoading = false;
        }, 0.3 * 1000);
      }
    });
    onMounted(() => {
      dataMap.getList(1, 10);
    });

    return {
      t,
      ...toRefs(dataMap),
      dialogFormtoggle,
      router
    };
  }
});
