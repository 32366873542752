
/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-05-05 09:51:37
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-12 15:40:50
 */

import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const ExampleRouter: Array<RouteRecordRaw> = [

  {
    path: '/aboutus',
    component: Layout,
    redirect: 'noredirect',
    children: [
      {
        path: '/aboutusdetail',
        component: () => import('@/views/aboutus/aboutus.vue'),
        name: 'aboutus',
        meta: {
          title: 'aboutus',
          icon: '#icon-shiyongwendang',

        }
      },

    ]
  }
]

export default ExampleRouter
