/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-04-25 09:32:19
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-11 11:58:49
 */

import { RouteRecordRaw } from "vue-router";
import Layout from "@/layout/Index.vue";
console.log(window.location, 11);

const ExampleRouter: Array<RouteRecordRaw> = [
  {
    path: "/user",
    component: Layout,
    redirect: "noredirect",
    meta: {
      title: "userPages",
      icon: "#icon-yonghu"
    },
    children: [
      {
        path: "/userList",
        component: () =>
          import(
            /* webpackChunkName: "error-page-401" */ "@/views/user/userlist.vue"
          ),
        name: "PageUser",
        meta: {
          title: "userList",
          noCache: true,
          icon: "#icon-yonghu",
          keepAlive: true
        }
      },
      {
        path: "/useradd",
        component: () =>
          import(
            /* webpackChunkName: "error-page-404" */ "@/views/user/useradd.vue"
          ),
        name: "PageUserAdd",
        meta: {
          title: "userAdd",
          noCache: true,
          icon: "#icon-tianjiatianchong",
          roles: ["系统管理员", "二级管理"]
        }
      },
      {
        path: "/userupdate",
        component: () =>
          import(
            /* webpackChunkName: "error-page-404" */ "@/views/user/useradd.vue"
          ),
        name: "PageUserUpdate",
        meta: {
          title: "userUpdate",
          noCache: true,
          hidden: true,
          icon: "#icon-tianjiatianchong",
          roles: ["系统管理员", "二级管理"]
        }
      },
      {
        path: "/iccidadd",
        name: "PageICCID",
        component: () => import("@/views/user/iccidadd.vue"),
        meta: {
          title: "ICCIDADD",
          noCache: true,
          hidden: true,
          icon: "#icon-tianjiatianchong",
          roles: ["系统管理员", "二级管理"]
        }
      },
      {
        path: "/userRemote",
        name: "userRemote",
        component: () =>
          import(
            /* webpackChunkName: "error-page-404" */ "@/views/user/userremote.vue"
          )
      },
      {
        path: "/userbatchImei",
        name: "userbatchImei",
        component: () =>
          import(
            /* webpackChunkName: "error-page-404" */ "@/views/user/userbatchImei.vue"
          )
      }
    ]
  }
];

export default ExampleRouter;
