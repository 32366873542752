/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-04-02 14:45:37
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-19 10:25:36
 */
// 其中使用 install 的目的在于 ts在main.ts中
// 不能通过Vue.prototype.$Api这个方式直接调用
// ，在全局方法中会说到使用 插件的方式去挂载。
// api.ts
import { Request } from "@/utils/request";

class api {
  /* api接口模块 */
  public static login = {
    // 直接在index.ts中设置不需要Base模块
    loginRequest: (params: any) => Request.post("/auth/open/login", params)
  };

  // 列表接口
  public static list = {
    // 批量添加群组
    createSelectGroup: (params: any) =>
      Request.post("/api/group/members/add2", params),

    // 群组查询
    searchGroupCreate: (data: any) =>
      Request.post("api/user/ptt/no_groups2", data),
    // 用户列表查询
    userList: (params: any) => Request.post("/api/user/ptt/list", params),
    // 群组列表查询
    groupList: (params: any) => Request.post("/api/group/list", params),
    // 查询没有父组
    groupnofater: (params: any) =>
      Request.post("/api/group/no_father/list2", params),
    // 群组列表查询(过滤掉满员的群组)
    userNoJoinGroupsList: (params: any) =>
      Request.get("/api/user/ptt/no_groups", params),
    // 账号已加入的群组列表查询
    userJoinGroupsList: (params: any) =>
      Request.post("/api/user/ptt/groups", params),
    // 账号好友列表查询
    friendsList: (params: any) => Request.post("/api/user/ptt/friends", params),
    // 账号非好友列表查询
    noFriendsList: (params: any) =>
      Request.post("/api/user/ptt/no_friends", params),
    // 日志列表查询
    logList: (params: any) =>
      Request.post("/api/log/ptt/login_logout/list", params),
    // 账单列表
    billingList: (params: any) => Request.post("/api/log/bill/list", params),
    changeList: (params: any) =>
      Request.post("api/log/server/transfer/list", params),
    // 虚拟币记录
    virtuaList: (params: any) =>
      Request.post("/api/currency/record/list", params),
    // 虚拟币明细记录
    virtuaDetailsList: (params: any) =>
      Request.post("/api/currency/record/detail", params),
    // 登录信息记录
    userLoginList: (params: any) =>
      Request.post("api/user/ptt/list/loginMessage", params),
    adminList: (params: any) => Request.post("/api/user/admin/list", params),
    transferList: (params: any) =>
      Request.post("/api/currency/transfer/record/list", params),
    authorList: (params: any) =>
      Request.post("/api/admin/authcode/list", params),
    // 激活终端账号
    activation: (params: any) =>
      Request.post("/api/user/ptt/activation", params),
    suspend: (params: any) => Request.post("/api/user/ptt/suspend", params),
    serverList: (data: any) => Request.get("/api/server/list", data),
    generalList: (params: any) =>
      Request.post("/api/user/admin/list/general", params),
    funcList: (params: any) => Request.post("api/func/page", params),
    funcgroupList: (params: any) => Request.post("api/func/group/page", params),
    iccidList: (params: any) => Request.post("/api/iccid/list", params),
    // 查询一级权限列表
    menuList: (params: any) => Request.post("/api/sys/menu/tree", params),
    // 查询角色列表
    roleList: (params: any) => Request.post("/api/sys/role/list", params),
    // 查询当前管理员下详细数据
    serarchadmin: (data: any) =>
      Request.get("api/currency/countOfAdminAll", data),
    // 查询单个菜单
    findmenuList: (data: any) => Request.get("/api/sys/menu/find/id", data),
    // 查询角色菜单
    rolemenufind: (data: any) => Request.get("/api/sys/role/menu", data),
    // 查询当前管理员菜单
    currentmenu: () => Request.get("/api/sys/role/current/menu"),
    updateDefaultGroup: (params: any) =>
      Request.post("/api/user/ptt/updateDefaultGroup", params),
    // 查询没有Imei的用户的列表
    userNoImeiList: (data: any) =>
      Request.get("/api/user/ptt/unbound_imei/list", data),
    parenList: (data: any) => Request.get("api/group/parent_child/list", data),
    supadminList: (data: any) => Request.post("/api/user/admin/list", data),
    adminIdList: (data: any) =>
      Request.post("/api/user/ptt/list/adminId", data),
    // 回收管理员
    retrieve: (data: any) => Request.get("/api/user/admin/retrieve", data),
    // 删除管理员
    deleteAdmin: (data: any) => Request.get("/api/user/admin/delete", data),
  };

  // 页面详情
  public static detail = {
    // VIP续费（支持批量
    cuurencygrantvip: (params: any) =>
      Request.post("/api/user/ptt/renewByVIPCurrency", params),
    // 计算VIP续费需要的币
    currencyretrievevip: (params: any) =>
      Request.post("/api/user/ptt/calcNeedCurrencyForVIPRenew", params),

    // 添加iccid
    addiccid: (params: any) => Request.post("/api/user/ptt/add/iccids", params),
    // 查询功能组
    seacrchfuncgropList: (data: any) =>
      Request.get("/api/func/group/list", data),
    // 管理员所有可用币
    adminAllVirtua: (data: any) =>
      Request.get("api/currency/surlpusAllCount", data),
    // 管理员虚拟币
    adminVirtua: (data: any) => Request.get("/api/currency/surlpusCount", data),
    // 首页基础信息
    aggrefateInfo: () => Request.get("/api/statistics/aggregateInfo"),
    // 管理员可用行业币
    tradeSurlpusCount: (data: any) =>
      Request.get("api/currency/tradeSurlpusCount", data),
    // 管理员可用续费币
    renewSurlpusCount: (data: any) =>
      Request.get("api/currency/renewSurlpusCount", data),
    // 查询功能组
    groupFunList: (data: any) => Request.get("api/func/group/list", data),
    // 功能组详情
    allocation: (data: any) =>
      Request.get("/api/user/admin/allocation/info/get", data),
    // 管理员列表详情
    adminfindDetail: (data: any) =>
      Request.get("/api/user/admin/find/login_name", data),
    // 管理员优惠月份
    preferentialMonths: () =>
      Request.get("/api/user/admin/preferentialMonths/get"),
    // 管理员有效年份
    validYears: () => Request.get("api/user/admin/validYears/get"),
    // 终端账号信息查询(根据uid)
    userInfo: (data: any) => Request.get("/api/user/ptt/find2/id", data),
    // 查询账号加入的功能组
    joinFuncGroupsList: (data: any) =>
      Request.get("/api/user/ptt/joinFuncGroup", data)
  };

  // 表格状态修改
  public static table = {
    // 用户列表批量删除
    userpttdelete: (params: any) =>
      Request.post("api/user/ptt/delete2", params),
    // 解冻
    removeFreeze: (data: any) =>
      Request.get("/api/user/admin/removeFreeze", data),
    // 下发虚拟币
    grant: (data: any) => Request.get("api/currency/grantTT", data),
    // 回收虚拟币
    recycleType: (data: any) => Request.get("api/currency/retrieveTT", data),
    // 管理员分配功能组
    allocationFrtp: (params: any) =>
      Request.post("/api/user/admin/allocation/fgrp", params),
    // 群组删除
    groupdelete: (params: any) => Request.post("/api/group/delete", params),
    // 成员优先级
    groupmemberspriority: (params: any) =>
      Request.post("/api/group/members_priority/get", params),
    groupmemberspriorityset: (params: any) =>
      Request.post("/api/group/members_priority/set", params),
    groupparentchildset: (params: any) =>
      Request.post("/api/group/parent_child/set", params),
    // 设置域名
    domainset: (params: any) =>
      Request.post("/api/user/admin/domain/set", params),
    // 用户删除
    usersDelete: (params: any) => Request.post("/api/user/ptt/delete2", params),
    // 行业功能列表
    tradegrouplist: () => Request.get("/api/func/trade_group/list"),
    // 保存功能
    setfunction: (params: any) =>
      Request.post("/api/user/ptt/functions/set", params),
    cardset: (params: any) =>
      Request.post("/api/user/ptt/card_info/set", params),
    cardget: (data: any) => Request.get("/api/user/ptt/card_info/get", data),
    getFunction: (data: any) => Request.get("/api/user/ptt/find2/id", data),
    // 修改年限
    yearsEdit: (data: any) =>
      Request.get("/api/user/admin/validYears/set", data),
    // 查询权限列表
    rolefind: (data: any) => Request.get("/api/admin/permission/list", data),
    // 退出登录
    logout: (data: any) => Request.get("/auth/logout", data),

    // 查询权限详情
    rolefinddetail: (data: any) =>
      Request.get("/api/admin/permission/find/adminId", data),

    // 设置权限
    roleset: (params: any) => Request.post("/api/admin/permission/set", params),
    // 查找年限
    validyear: (data: any) =>
      Request.get("/api/user/admin/validYears/get", data),
    // 迁移群组
    grouoadminId: (params: any) =>
      Request.post("/api/group/list/adminId", params),
    // 目标管理员
    lowestlevel: (params: any) =>
      Request.post("/api/user/admin/list/lowest_level", params)
  };

  // 添加与修改
  public static revise = {
    // 管理员添加与修改
    adminadd: (params: any) => Request.post("/api/user/admin/add", params),
    adminPermissionList: (data: any) =>
      Request.get("/api/admin/permission/list", data),
    adminupdate: (params: any) =>
      Request.post("/api/user/admin/update", params),
    currencyTransfer: (params: any) =>
      Request.post("/api/currency/transfer/transfer", params),
    // 续费币续费
    batchRenewalByRenewalCurrency: (params: any) =>
      Request.post("/api/user/ptt/renewBatchByRenewCurrency", params),
    // 群组修改与添加
    groupnomembers: (data: any) => Request.get("api/group/no_members", data),
    // 添加/修改群组成员 通过群组名称搜索该群组下的所有成员
    groupUserByGname: (data: any) => Request.get("/api/group/members/TT", data),
    groupmembers: (data: any) => Request.get("/api/group/members", data),
    tansferadminedit: (params: any) =>
      Request.post("api/user/admin/transfer/user", params),
    groupadd: (params: any) => Request.post("api/group/add", params),
    groupupdate: (params: any) => Request.post("/api/group/update", params),
    // 父子群组查询
    groupchildlist: (data: any) =>
      Request.get("/api/group/no_parent/list", data),
    // 父子群组数据回填
    groupnestedes: (data: any) => Request.get("/api/group/child/list", data),
    // 更新服务器
    serverupdate: (params: any) => Request.post("/api/server/update", params),
    // 添加服务器
    serveadd: (params: any) => Request.post("/api/server/add", params),
    // 删除服务器
    serverdelete: (data: any) => Request.get("/api/server/delete", data),
    // 更新服务器
    serversync: (data: any) => Request.get("/api/server/sync", data),
    // 查询目标管理员所在ip
    servergetip: (data: any) => Request.get("/api/server/find/loginName", data),
    // 提交目标服务器
    servertransfer: (data: any) =>
      Request.get("/api/server/transfer/admin", data),
    // 添加功能
    createfunc: (data: any) => Request.get("/api/func/create", data),
    // 添加功能组
    creategroupfunc: (params: any) =>
      Request.post("/api/func/group/create", params),
    // 修改功能组
    updattegroupfunc: (params: any) =>
      Request.post("/api/func/group/update", params),
    // 功能组详情
    groupDetail: (params: any) => Request.get("api/func/group/detail", params),
    // 角色添加
    roleadd: (params: any) => Request.post("/api/sys/role/add", params),
    // 角色修改
    roleupdate: (params: any) => Request.post("/api/sys/role/update", params),
    // 角色删除
    roledelete: (params: any) => Request.post("/api/sys/role/delete", params),
    // 更新菜单
    menuupdate: (params: any) => Request.post("/api/sys/menu/update", params),
    // 添加菜单
    menuadd: (params: any) => Request.post("/api/sys/menu/add", params),
    // 删除菜单
    menudelete: (params: any) => Request.get("/api/sys/menu/delete", params),
    // 角色分配菜单
    roleauth: (params: any) => Request.post("/api/sys/role/auth", params),
    // 账号信息修改
    userUpdate: (params: any) => Request.post("/api/user/ptt/update2", params),
    // 添加用户账号
    userAdd: (params: any) => Request.post("/api/user/ptt/add2", params),
    // 添加用户好友
    userAddFriends: (params: any) =>
      Request.post("/api/user/ptt/friends/add", params),
    // imei远程写号
    userWriteImei: (params: any) =>
      Request.post("/api/user/ptt/write_account", params),
    // 修改经销商密码
    agentpwdUpdate: (params: any) =>
      Request.post("/api/user/ptt/agentpwd/change", params),
    // 批量修改账号信息
    batchUserUpdate: (params: any) =>
      Request.post("/api/user/ptt/update/listInfo", params),
    // 批量修改群组名称
    batchGroupsUpdate: (params: any) =>
      Request.post("/api/group/name/update", params),
    // 批量绑定IMEI(不会放号)
    batchUserImeiUpdate: (params: any) =>
      Request.post("api/user/ptt/bind_imei_nw", params),
    // imei批量远程写号
    batchWriteImei: (params: any) =>
      Request.post("/api/user/ptt/binding_imei", params),
    // 查询所勾选账号续费需要消耗的各种币数量
    renewCount: (params: any) =>
      Request.post("/api/user/ptt/calcNeedCurrencyForRenew", params),
    // 批量续费
    batchRenewal: (params: any) =>
      Request.post("/api/user/ptt/renewBatchByCurrency", params),
    // 删除群组嵌套
    parentchilddelete: (data: any) =>
      Request.get("/api/group/parent_child/delete", data)
  };
}
export { api };
