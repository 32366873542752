
import { defineComponent, onMounted, reactive, toRefs, ref, unref } from "vue";
import { useI18n } from "vue-i18n";
import { api } from "@/apis/user";
import { useRouter } from "vue-router";
import { ElTable, ElMessage } from "element-plus";
import { formatJson } from "@/utils";
import { exportJson2Excel } from "@/utils/excel";
import axios from "axios";
import { group } from "console";
import { getLanguage } from "@/utils/cookies";
interface arrayGids {
  gids: any;
}
interface listForm {
  gid: number;
  prioritys: any;
}
interface groupInfo {
  gid: number;
  name: string;
}
export default defineComponent({
  setup() {
    const { t } = useI18n();
    const multipleSelection = ref();
    const router = useRouter();
    // const multipleTableNode = ref(ElTable)
    const dialogFormdistribute = ref(false);
    const deleteList = reactive<arrayGids>({
      gids: []
    });
    const listDisForm = reactive<listForm>({
      gid: 0,
      prioritys: []
    });
    const dataMap = reactive({
      tableKey: 0,
      list: [],
      downloadLoading: false,
      total: 0,
      name: null,
      filename: "",
      listLoading: false,
      downallloadLoading: false,
      priorityList: [],
      multi: false,
      keyValue: {
        value: "",
        label: ""
      },
      isShow: true,
      userPriority: [
        {
          value: 1,
          label: t("table.primary")
        },
        {
          value: 2,
          label: t("table.mid")
        },
        {
          value: 3,
          label: t("table.senior")
        }
      ],
      val: 10,
      // 分页查询参数
      listQuery: {
        page: {
          pageNo: 1,
          pageSize: 10
        },
        pttGroupEntity: {
          gid: "",
          name: ""
        }
      },
      userGid: null,
      userVal: 10,
      userListQuery: {
        page: {
          pageNo: 1,
          pageSize: 10
        },
        pttUserEntity: {}
      },
      userTotal: 0,

      // 分页
      handleSizeChange(val: any) {
        dataMap.val = val;
        dataMap.getList(1, val);
      },
      // 用户分页
      handleUserSizeChange(val: any) {
        dataMap.userVal = val;
        dataMap.priority(dataMap.userGid, 1, val);
      },
      // 优先级改变
      chageDisFrom(row: any) {
        listDisForm.gid = row.gid
        listDisForm.prioritys.push(row);
      },
      // 优先级提交
      async submitDisForm() {
        const { data }: any = await api.table.groupmemberspriorityset(
          listDisForm
        );
        if (data.status === 200) {
          ElMessage.success(t("table.modify success"));
          dialogFormdistribute.value = false;
          dataMap.getList(1, 10);
        }
        console.log(data);
        console.log(listDisForm);
      },
      // 搜索
      toSearch() {
        localStorage.setItem("searchgid", dataMap.listQuery.pttGroupEntity.gid);
        localStorage.setItem(
          "searchname",
          dataMap.listQuery.pttGroupEntity.name
        );
        dataMap.getList(1, 10, dataMap.listQuery.pttGroupEntity);
      },
      //重置
      toReset() {
        dataMap.listQuery.pttGroupEntity.gid = "";
        dataMap.listQuery.pttGroupEntity.name = "";
        localStorage.removeItem("searchgid");
        localStorage.removeItem("searchname");
        multipleSelection.value = undefined;
        dataMap.multi = false;
        dataMap.getList(1, 10, dataMap.listQuery.pttGroupEntity);
      },
      // 单列删除
      async toRowDelete(row: any) {
        deleteList.gids.push(row.gid);
        const { data }: any = await api.table.groupdelete({
          gids: deleteList.gids
        });
        if (data.status === 200) {
          ElMessage.success(t("table.delete success"));
          dataMap.getList(1, 10);
        }
      },
      // 批量删除
      async handleDeleteChange() {
        if (
          multipleSelection.value == undefined ||
          multipleSelection.value.length === 0
        ) {
          ElMessage.warning(t("input.Please tick first"));
        } else {
          multipleSelection.value.map((i: any) => {
            deleteList.gids.push(i.gid);
          });
          const { data }: any = await api.table.groupdelete({
            gids: deleteList.gids
          });
          if (data.status === 200) {
            ElMessage.success(t("table.delete success"));
            dataMap.getList(1, 10);
          }
        }
      },
      handleCurrentChange(page: any, val: any) {
        dataMap.getList(page, dataMap.val);
      },

      // 修改
      handleEdit(row: any) {
        router.push({
          path: "/groupadd",
          query: { name: row.name, remarks: row.remarks, gid: row.gid }
        });
      },
      // 添加
      toGroupAdd() {
        router.push({ path: "/groupadd" });
      },
      // 勾选
      handleSelectionChange(val: any) {
        multipleSelection.value = val;
      },
      // 取消
      cancelmutil() {
        dataMap.multi = false;
        dataMap.getList(1, 10);
      },
      // 批量修改名字
      handleBatchName() {
        console.log(multipleSelection.value.length);
        if (
          multipleSelection.value == undefined ||
          multipleSelection.value.length === 0
        ) {
          ElMessage.warning(t("input.Please tick first"));
        } else {
          multipleSelection.value.map((i: any) => {
            i.show = true;
          });
          dataMap.multi = true;
        }
      },
      // 提交批量修改
      async handleSubmitBatchName() {
        const groupsInfo: any = [];
        multipleSelection.value.map((i: any) => {
          groupsInfo.push({ gid: i?.gid, name: i?.name });
          i.show = false;
        });
        const data: any = await api.revise.batchGroupsUpdate(groupsInfo);
        if (data.status == 200) {
          ElMessage.success(t("input.The batch modification is successful"));
          dataMap.getList(1, 10);
          dataMap.multi = false;
        }
      },
      // 成员优先级数据初始化
      async priority(row?: any, page?: any, pageSize?: any, pttUserEntity?: any) {
        dialogFormdistribute.value = true;

        if(row.gid){
          dataMap.userGid = row.gid;
          listDisForm.gid = row.gid
          console.log('?',dataMap.userGid )
        }
        if (page) {
          dataMap.userListQuery.page.pageNo = page;
        }
        if (pageSize) {
          dataMap.userListQuery.page.pageSize = pageSize;
        }

        dataMap.userListQuery.pttUserEntity = pttUserEntity;

        dataMap.userListQuery.page.pageNo = page;
        dataMap.userListQuery.page.pageSize = pageSize;

        console.log('请求参数: ', dataMap.userGid , dataMap.userListQuery)

        const data: any = await api.table.groupmemberspriority({
          gid: dataMap.userGid,
          ...dataMap.userListQuery
          // page: {
          //   pageNo:dataMap.userListQuery.page.pageNo,
          //   pageSize:dataMap.userListQuery.page.pageSize
          // }
        });
        dataMap.priorityList = data?.data.data.resultList;
        dataMap.userTotal = data?.data.data.totalCount;
        console.log(dataMap.priorityList);
      },
      // 成员优先级数据翻页
      handleUserCurrentChange(page: any, val: any) {
        console.log('abc')
        dataMap.priority(dataMap.userGid,page,dataMap.userVal)
      },
      //导出全部
      async handleallDownload() {
        let obj: any = {};
        dataMap.downallloadLoading = true;
        axios
          .post(
            `${process.env.VUE_APP_BASE_API}api/membership/export/byGroup`,
            obj,
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "pc-access-token": localStorage.getItem("token"),
                "manager-web-session": localStorage.getItem("tokensession"),
                lang: getLanguage()
              },
              responseType: "arraybuffer"
            }
          )
          .then(res => {
            console.log(res);
            var blob: any = new Blob([res.data]);
            var downloadElement = document.createElement("a");
            var href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.download = "群组列表.xlsx"; //下载后文件名
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href);
            dataMap.downallloadLoading = false;
          });

        // dataMap.downallloadLoading = true
        // const tHeader = ['账号', '时间', '在线状态',]
        // const filterVal = ['account', 'createDateTimestamp', 'online',]
        // const data: any = await api.list.logList({
        //   page: {
        //     pageNo: 1,
        //     pageSize: 1000
        //   },
        //   pttGroupEntity: {
        //     gid: '',
        //     name: '',
        //   }
        // })
        // const list = data?.data.data.resultList
        // if (list.length > 0) {
        //   dataMap.downallloadLoading = false
        //   const data = formatJson(filterVal, list)
        //   exportJson2Excel(tHeader, data, dataMap.filename !== '' ? dataMap.filename : undefined)
        // }
      },
      // 页面初始化数据
      async getList(page?: any, pageSize?: any, pttGroupEntity?: any) {
        if (page) {
          dataMap.listQuery.page.pageNo;
        }
        if (pageSize) {
          dataMap.listQuery.page.pageSize = pageSize;
        }

        if (pttGroupEntity) {
          dataMap.listQuery.pttGroupEntity = pttGroupEntity;
        }
        dataMap.listQuery.page.pageNo = page;
        dataMap.listQuery.page.pageSize = pageSize;
        dataMap.listLoading = true;
        const data: any = await api.list.groupList(dataMap.listQuery);
        dataMap.list = data?.data.data.resultList;
        dataMap.list.map((i: any) => {
          i.show = false;
          return i;
        });
        dataMap.total = data?.data.data.totalCount;
        setTimeout(() => {
          dataMap.listLoading = false;
        }, 0.3 * 1000);
      }
    });
    onMounted(() => {
      let searchgid = localStorage.getItem("searchgid");
      let searchname = localStorage.getItem("searchname");
      if (searchgid || searchname) {
        dataMap.listQuery.pttGroupEntity.gid = searchgid || "";
        dataMap.listQuery.pttGroupEntity.name = searchname || "";
        dataMap.getList(1, 10, dataMap.listQuery.pttGroupEntity);
      } else {
        dataMap.getList(1, 10);
      }
    });
    // 导出
    const handleDownload = () => {
      console.log(multipleSelection.value);
      // const from = unref(multipleTableNode)
      if (
        multipleSelection.value != undefined &&
        multipleSelection.value.length !== 0
      ) {
        dataMap.downloadLoading = true;
        const tHeader = [
          t("table.groupId"),
          t("table.groupName"),
          t("table.createTime"),
          t("table.remarks")
        ];
        const filterVal = ["gid", "name", "createDate", "remarks"];
        const list = multipleSelection.value;
        const data = formatJson(filterVal, list);
        exportJson2Excel(
          tHeader,
          data,
          dataMap.filename !== "" ? dataMap.filename : undefined
        );
        // from.clearSelection()
        dataMap.downloadLoading = false;
      } else {
        ElMessage.warning(
          t("input.Please check the options you want to export first")
        );
      }
    };
    return {
      t,
      ...toRefs(dataMap),
      multipleSelection,
      deleteList,
      dialogFormdistribute,
      handleDownload
    };
  }
});
