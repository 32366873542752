/*
 * @Description: app Mutations
 * @Author: ZY
 * @Date: 2020-12-23 10:25:37
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-11 10:19:13
 */
import { MutationTree } from 'vuex'
import { UserState } from './state'
import { UserMutationTypes } from './mutation-types'

export type Mutations<S = UserState> = {
  [UserMutationTypes.SET_TOKEN] (state: S, token: string): void
  [UserMutationTypes.SET_NAME] (state: S, name: string): void
  [UserMutationTypes.SET_AVATAR] (state: S, avatar: string): void
  [UserMutationTypes.SET_INTRODUCTION] (state: S, introduction: string): void
  [UserMutationTypes.SET_ROLES] (state: S, roles: string[]): void
  [UserMutationTypes.SET_EMAIL] (state: S, email: string): void
  [UserMutationTypes.SET_SESSION] (state: S, managerWebSession: string): void

}

export const mutations: MutationTree<UserState> & Mutations = {
  [UserMutationTypes.SET_TOKEN] (state: UserState, token: string) {
    state.token = token
  },
  [UserMutationTypes.SET_SESSION] (state: UserState, managerWebSession: string) {
    state.managerWebSession = managerWebSession
  },
  [UserMutationTypes.SET_NAME] (state: UserState, name: string) {
    state.name = name
  },

  [UserMutationTypes.SET_AVATAR] (state: UserState, avatar: string) {
    state.avatar = avatar
  },

  [UserMutationTypes.SET_INTRODUCTION] (state: UserState, introduction: string) {
    state.introduction = introduction
  },

  [UserMutationTypes.SET_ROLES] (state: UserState, roles: any[]) {
    state.roles = roles
  },

  [UserMutationTypes.SET_EMAIL] (state: UserState, email: string) {
    state.email = email
  }

}
