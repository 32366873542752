import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { style: {"width":"100%","background":"#fff"} }
const _hoisted_3 = { class: "zhpy" }
const _hoisted_4 = { style: {"width":"100%","background":"#fff"} }
const _hoisted_5 = { class: "zhpy" }
const _hoisted_6 = { class: "wb-140" }
const _hoisted_7 = { class: "wb-140 mg-10" }

import { reactive, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { api } from "@/apis/user";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";

// 数据定义

interface stateTypes {
  data: { key: String; label: String; name: String }[];
}
interface stateForm {
  gid: any;
  name: any;
  users: any;
  remarks: any;
  count: Number;
}

export default _defineComponent({
  expose: [],
  setup(__props) {

const route = useRoute();
const routerList = useRouter();
const { t } = useI18n();

const rules = reactive({
  name: [
    {
      required: true,
      message: t("input.Please fill in the account number"),
      trigger: "blur"
    },
    {
      min: 2,
      max: 11,
      message: t("input.The account length should be between 2 and 11 digits"),
      trigger: "blur"
    }
  ]
});
const groupForm = ref();
const gid = route.query.gid || "";
const dataList = reactive<stateTypes>({
  data: []
});
const dataPage = ref(1)
const form = reactive<stateForm>({
  gid: route.query.gid || "",
  name: route.query.name || "",
  users: ref([]),
  remarks: route.query.remarks || "",
  count: 0
});
const dataMap = reactive({
  keyValue: {
    value: "uname",
    label: ''
  },
  accountList: [
    {
      value: "uname",
      label: t("table.name")
    },
    {
      value: "defaultGroupName",
      label: t("table.defaultgroup")
    }
  ]
})
const leftSearchValue = ref("");
const rightSearchValue = ref("");
const leftFilterMethod = (val: string) => {
  console.log('L-So-uname?', val,dataMap.keyValue)
  const { value,label } = dataMap.keyValue
  if(value == 'uname'){
    getGroupList(val);
  } else {
    getDefaultGroupUsersByGname(val)
  } 
};
const filterMethod = (val: string) => {
  getGroupList(val);
};
// 初始化成员数据
const getGroupList = async (gname?: any) => {
  dataPage.value = 1
  if (gid !== "") {
    console.log('群组修改 ')
    const leftList: any = await api.revise.groupnomembers({
      gid: gid,
      pageNo: 1,
      pageSize: 500,
      uname: gname
    });
    const rightList: any = await api.revise.groupmembers({
      gid: gid,
      pageNo: 1,
      pageSize: 2000,
      uname: gname
    });
    const lList: any = leftList.data.data.resultList;
    const rList: any = rightList.data.data.resultList;
    const concatList: any = lList.concat(rList);
    const everyList: any[] = [];
    for (const i of concatList) {
      everyList.push({
        key: i?.uid,
        name: i?.account,
        label: i?.name
      });
    }
    dataList.data = everyList;
    for (const i of rightList.data.data.resultList) {
      form.users.push(i?.uid);
    }
  } else {
    console.log('群组添加')
    const leftList: any = await api.revise.groupnomembers({
      pageNo: 1,
      pageSize: 500,
      uname: gname
    });
    const lList: any[] = [];
    // if (leftList.data.data.resultList.length !== 0) {
    for (const i of leftList.data.data.resultList) {
      lList.push({
        name: i?.account,
        label: i?.name,
        key: i?.uid
      });
    }
    dataList.data = lList;
    // }
  }
};
// 通过 默认群组名称搜索该群组下所有成员
const getDefaultGroupUsersByGname = async (gname: string) => {
  console.log('搜索?')
  if(gid !== '') {
    const groupUserList: any = await api.revise.groupUserByGname({
    pageNo: 1,
    pageSize: 500,
    gname: gname
  })
  const rightList: any = await api.revise.groupmembers({
    gid: gid,
    pageNo: 1,
    pageSize: 2000,
    uname: gname
  });
  const lList: any = groupUserList.data.data.resultList;
  const rList: any = rightList.data.data.resultList;
  const concatList: any = lList.concat(rList);
  const everyList: any[] = [];
  for (const i of concatList) {
    everyList.push({
      key: i?.uid,
      name: i?.account,
      label: i?.name
    });
  }
  dataList.data = everyList;
  for (const i of rList) {
    form.users.push(i?.uid);
  }
  } else {
    const groupUserList: any = await api.revise.groupUserByGname({
      pageNo: 1,
      pageSize: 500,
      gname: gname
    })
    
    const lList: any[] = []
   
    for (const i of groupUserList.data.data.resultList) {
      lList.push({
        key: i?.uid,
        name: i?.account,
        label: i?.name
      });
    }
    dataList.data = lList
  }
}

const handelMoreUser = async () => {
  console.log('查询更多用户~')
  let page = dataPage.value + 1
  dataPage.value = page
  const leftList: any = await api.revise.groupnomembers({
      pageNo: page || 1,
      pageSize: 500,
    });
    const lList: any[] = dataList.data;
    for (const i of leftList.data.data.resultList) {
      lList.push({
        name: i?.account,
        label: i?.name,
        key: i?.uid
      });
    }
    dataList.data = lList;

}
// 提交
const submit = () => {
  if (gid !== "") {
    console.log('提交群组信息', gid)
    groupForm.value.validate(async (valid: any) => {
      if (valid) {
        console.log('表单提交参数?',form);
        const status: any = await api.revise.groupupdate(form);
        if (status.data.status === 200) {
          routerList.push({ path: "/grouplist" });
          groupForm.value.resetFields();
          ElMessage.success(t("table.modify success"));
        } else {
        }
      }
    });
  } else {
    console.log('新建群组信息')
    groupForm.value.validate(async (valid: any) => {
      console.log(form);
      if (valid) {
        const status: any = await api.revise.groupadd(form);
        if (status.data.status === 200) {
          routerList.push({ path: "/grouplist" });
          ElMessage.success(t("table.success"));
        } else {
        }
      }
    });
  }
};

onMounted(() => {
  getGroupList();
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _component_el_transfer = _resolveComponent("el-transfer")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      model: form,
      "label-width": "120px",
      rules: rules,
      ref: groupForm,
      style: {"width":"80%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('form.groupName'),
          prop: "name"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: form.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (form.name = $event)),
              maxlength: "11",
              placeholder: 
            _ctx.$t(
              'input.Group names can contain only Chinese alphanumeric underscores and no more than 11 characters'
            )
          
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('dashboard.groupNum')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: form.count,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (form.count = $event)),
              type: "number",
              maxlength: "3",
              placeholder: 
            _ctx.$t('input.Please enter the number of groups created in bulk')
          
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('table.Members')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_transfer, {
              modelValue: form.users,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (form.users = $event)),
              data: dataList.data,
              titles: [_ctx.$t('table.Items'), _ctx.$t('table.Selected')]
            }, {
              "left-footer": _withCtx(() => [
                _createVNode("div", _hoisted_2, [
                  _createVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("table.Account")), 1),
                  _createVNode("span", null, _toDisplayString(_ctx.$t("table.designation")), 1),
                  _createVNode("span", {
                    class: "text-span-more",
                    onClick: handelMoreUser
                  }, _toDisplayString(_ctx.$t("table.more")), 1)
                ]),
                _createVNode(_component_el_select, {
                  modelValue: dataMap.keyValue.value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (dataMap.keyValue.value = $event)),
                  style: {"width":"30%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(dataMap.accountList, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_el_input, {
                  style: {"width":"70%"},
                  placeholder: _ctx.$t('input.Please enter a name to search'),
                  "prefix-icon": _ctx.Search,
                  onInput: leftFilterMethod,
                  modelValue: dataMap.keyValue.label,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (dataMap.keyValue.label = $event))
                }, null, 8, ["placeholder", "prefix-icon", "modelValue"])
              ]),
              "right-footer": _withCtx(() => [
                _createVNode("div", _hoisted_4, [
                  _createVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("table.Account")), 1),
                  _createVNode("span", null, _toDisplayString(_ctx.$t("table.designation")), 1)
                ]),
                _createVNode(_component_el_input, {
                  style: {"width":"100%"},
                  placeholder: _ctx.$t('input.Please enter a name to search'),
                  "prefix-icon": _ctx.Search,
                  onChange: filterMethod,
                  modelValue: rightSearchValue.value,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (rightSearchValue.value = $event))
                }, null, 8, ["placeholder", "prefix-icon", "modelValue"])
              ]),
              default: _withCtx(({option}) => [
                _createVNode("span", _hoisted_6, _toDisplayString(option.name), 1),
                _createVNode("span", _hoisted_7, _toDisplayString(option.label), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "data", "titles"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('statistics.Remark')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: form.remarks,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (form.remarks = $event)),
              placeholder: _ctx.$t('input.Less than two hundred words'),
              type: "textarea",
              maxlength: "200"
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              onClick: _cache[8] || (_cache[8] = ($event: any) => (submit())),
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("permission.confirm")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512)
  ]))
}
}

})