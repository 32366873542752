
/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-05-05 09:51:37
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-11 12:07:03
 */

import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const ExampleRouter: Array<RouteRecordRaw> = [

  {
    path: '/carnumberadmin',
    component: Layout,
    redirect: 'noredirect',
    meta: {
      roles: ['系统管理员'],

    },
    children: [
      {
        path: '/importcar',
        component: () => import('@/views/carnumberadmin/importcar.vue'),
        name: 'importcar',
        meta: {
          title: 'importcar',
          icon: '#icon-daoru',

        }
      },

    ]
  }
]

export default ExampleRouter
