/*
 * @Description: app actions
 * @Author: ZY
 * @Date: 2020-12-23 10:25:37
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-11 11:55:45
 */
import { ActionTree, ActionContext } from "vuex";
import { RootState, useStore } from "@/store";
import { state, UserState } from "./state";
import { Mutations } from "./mutations";
import { UserMutationTypes } from "./mutation-types";
import { UserActionTypes } from "./action-types";
import { api } from "@/apis/user";
import {
  removeToken,
  setSession,
  setToken,
  removeSession,
  setLoginName,
  setPerrsion,
  removeLoginName,
  setLoginId,
  removePerrsion,
  removeLoginId
} from "@/utils/cookies";
import { PermissionActionType } from "../permission/action-types";
import router, { resetRouter } from "@/router";
import { RouteRecordRaw } from "vue-router";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<UserState, RootState>, "commit">;

export interface Actions {
  [UserActionTypes.ACTION_LOGIN](
    { commit }: AugmentedActionContext,
    userInfo: { loginName: string; password: string }
  ): void;
  [UserActionTypes.ACTION_RESET_TOKEN]({
    commit
  }: AugmentedActionContext): void;
  [UserActionTypes.ACTION_GET_USER_INFO]({
    commit
  }: AugmentedActionContext): void;
  [UserActionTypes.ACTION_CHANGE_ROLES](
    { commit }: AugmentedActionContext,
    role: string
  ): void;
  [UserActionTypes.ACTION_LOGIN_OUT]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<UserState, RootState> & Actions = {
  async [UserActionTypes.ACTION_LOGIN](
    { commit }: AugmentedActionContext,
    userInfo: { loginName: string; password: string }
  ) {
    let { loginName, password } = userInfo;
    loginName = loginName.trim();
    await api.login
      .loginRequest({ loginName, password })
      .then(async (res: any) => {
        if (res.status === 200 && res.data.data.token) {
          setToken(res.data.data.token);
          setLoginName(res.data.data.loginUser.loginName);
          setLoginId(res.data.data.loginUser.id);
          setSession(res.data.data["manager-web-session"]);
          commit(UserMutationTypes.SET_TOKEN, res.data.data.token);
          commit(
            UserMutationTypes.SET_SESSION,
            res.data.data["manager-web-session"]
          );
          localStorage.setItem(
            "systempremmsion",
            res.data.data.loginUser.role.name
          );
          await api.table
            .rolefinddetail({
              adminId: res.data.data.loginUser.id
            })
            .then(async (res: any) => {
              console.log(res);
              setPerrsion(JSON.stringify(res.data.data));
            });
        }
      })
      .catch(err => {});
  },

  [UserActionTypes.ACTION_RESET_TOKEN]({ commit }: AugmentedActionContext) {
    removeToken();
    removeSession();
    commit(UserMutationTypes.SET_TOKEN, "");
    commit(UserMutationTypes.SET_ROLES, []);
  },

  async [UserActionTypes.ACTION_GET_USER_INFO]({
    commit
  }: AugmentedActionContext) {
    if (state.token === "") {
      throw Error("token is undefined!");
    }
    const rolesname: any[] = [];
    rolesname.push(localStorage.getItem("systempremmsion"));
    commit(UserMutationTypes.SET_ROLES, rolesname);
    commit(UserMutationTypes.SET_NAME, "Super Admin");
    commit(
      UserMutationTypes.SET_AVATAR,
      "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif"
    );
    commit(UserMutationTypes.SET_INTRODUCTION, "I am a super administrator");
    commit(UserMutationTypes.SET_EMAIL, "2218889604@qq.com");
    // await api.list.currentmenu().then((res: any) => {
    //   commit(UserMutationTypes.SET_ROLES, res.data.data)
    // })
    // commit(UserMutationTypes.SET_ROLES, res.data.roles)
    // commit(UserMutationTypes.SET_NAME, res.data.name)
    // commit(UserMutationTypes.SET_AVATAR, res.data.avatar)
    // commit(UserMutationTypes.SET_INTRODUCTION, res.data.introduction)
    // commit(UserMutationTypes.SET_EMAIL, res.data.email)
    // await userInfoRequest().then((res) => {
    //   if (res?.code === 0) {
    //

    //     return res
    //   } else {
    //     throw Error('Verification failed, please Login again.')
    //   }
    // })
  },

  async [UserActionTypes.ACTION_CHANGE_ROLES](
    { commit }: AugmentedActionContext,
    role: string
  ) {
    const token = role + "-token";
    const store = useStore();
    commit(UserMutationTypes.SET_TOKEN, token);
    setToken(token);
    await store.dispatch(UserActionTypes.ACTION_GET_USER_INFO, undefined);
    store.dispatch(PermissionActionType.ACTION_SET_ROUTES, state.roles);
    store.state.permission.dynamicRoutes.forEach((item: RouteRecordRaw) => {
      router.addRoute(item);
    });
  },

  [UserActionTypes.ACTION_LOGIN_OUT]({ commit }: AugmentedActionContext) {
    removeToken();
    removeLoginName();
    removeLoginId();
    removeSession();
    removePerrsion();
    commit(UserMutationTypes.SET_TOKEN, "");
    commit(UserMutationTypes.SET_ROLES, []);
    resetRouter();
  }
};
