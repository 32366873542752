
import { defineComponent, onMounted, reactive, toRefs, ref, toRaw } from "vue";
import { ElForm, ElMessage, ElTable, ElMessageBox } from "element-plus";
import { useI18n } from "vue-i18n";
import { api } from "@/apis/user";
import { useRouter, useRoute } from "vue-router";
import moment, { months } from "moment";
import { formatJson } from "@/utils";
import { getLoginName, getLoginId } from "@/utils/cookies";
import { exportJson2Excel } from "@/utils/excel";
import axios from "axios";
import { react } from "@babel/types";
import { getLanguage } from "@/utils/cookies";
interface userSelectUid {
  uid: number;
}
interface userListModel {
  id: number;
  uid: number;
  account: string;
  name: string;
  password: string;
  funcs: string;
  gpsInterval: string;
  onLine: string;
  imei: string;
  type: number;
  createBy: number;
  defaultGid: any;
  defaultGroupName: number;
  createData: string;
  validDate: string;
  oldType: any;
}
interface deleteList {
  uids: any;
}
interface importUserinfo {
  months: number;
  gpsInterval: string;
  fgid: number;
  defaultGid: any;
}
interface statefuncedit {
  id: any;
  name: any;
}

interface userfriends {
  data: { key: any; name: any; label: any }[];
}
interface funclistTypes {
  funcList: { id: number; name: String }[];
}
interface joinfuncType {
  joinFuncList: { id: Number; name: String; remarks: String }[];
}
export default defineComponent({
  setup() {
    const { t } = useI18n();
    const dialogTableVisible = ref(false);
    const gridData = [];
    const formdistribute = ref();
    const clearUserList = ref();
    const clearIccidList = ref();
    const importFormRef = ref();
    const importICCIDRef = ref();
    const formcardbute = ref();
    const currentRow = ref();
    const singleTableRef = ref<InstanceType<typeof ElTable>>();
    const rulesFunc = reactive({
      fids: [
        {
          required: true,
          message: t("input.Please tick the function"),
          trigger: "change"
        }
      ]
    });
    const ruluesFuncset = reactive({
      funcValueList: [
        {
          required: true,
          message: t("input.Please tick the function"),
          trigger: "change"
        }
      ]
    });
    const importUserRules = reactive({
      fgid: [
        {
          required: true,
          message: t("input.Please select function group"),
          trigger: "change"
        }
      ],
      defaultGid: [
        {
          required: true,
          message: t("input.Please select a default group"),
          trigger: "change"
        }
      ],
      file: [
        {
          required: true,
          message: t("input.Please select File Upload"),
          trigger: "change"
        }
      ]
    });
    const importICCID = reactive({
      fgid: [
        {
          required: true,
          message: t("input.Please select function group"),
          trigger: "change"
        }
      ],
      defaultGid: [
        {
          required: true,
          message: t("input.Please select a default group"),
          trigger: "change"
        }
      ],
      agentpwd: [
        {
          required: true,
          message: t("input.Please enter the angent password"),
          trigger: "blur"
        },
        {
          min: 6,
          max: 12,
          message: t(
            "input.Please enter 6-12 digits or a combination of numbers and English"
          ),
          trigger: "blur"
        }
      ],
      file: [
        {
          required: true,
          message: t("input.Please select File Upload"),
          trigger: "change"
        }
      ]
    });
    const cardRules = reactive({
      masterIccid: [
        {
          required: false,
          message: t("input.Please enter the ICCID primary card"),
          trigger: "blur"
        },
        {
          min: 19,
          max: 20,
          message: t(
            "input.Please enter 19~20 digits or a combination of numbers and English"
          ),
          trigger: "blur"
        }
      ],
      slaveIccid: [
        {
          required: false,
          message: t("input.Please enter the ICCID secondary card"),
          trigger: "blur"
        },
        {
          min: 19,
          max: 20,
          message: t(
            "input.Please enter 19~20 digits or a combination of numbers and English"
          ),
          trigger: "blur"
        }
      ]
    });
    // const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const getloginName = getLoginName();
    const dialogFormtoggle = ref(false);
    const dialogFormCard = ref(false);
    const dialogFormGroup = ref(false);
    const getloginId = getLoginId();
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleSelection = ref(Array<userListModel>());
    const mutilGroupSeclection = ref([]);
    const formRef = ref();
    const renewformRef = ref();
    const vipformRef = ref();
    const permessionList: any = ref([]);
    const permessionListInit = JSON.parse(
      localStorage.getItem("perrsion") || ""
    );
    const isRenew = ref(false)
    const isRenewType = ref(false)
    
    /**
     *  201 修改用户权限
     *  202 删除用户权限
     *  203 查看密码权限
     *  204 远程放号权限
     *  205 功能设置权限
     */
    permessionListInit.map((item: any) => {
      permessionList.value.push(item.code);
    });
    console.log(permessionList.value.includes(203), "perssion");
    const deletePermession = permessionList.value.includes(202);
    const deleteList = reactive<deleteList>({
      uids: []
    });
    const friendsDataList = reactive<userfriends>({
      data: []
    });
    const funcGroupList = reactive<funclistTypes>({
      funcList: []
    });
    const joinFuncGroupsList = reactive<joinfuncType>({
      joinFuncList: []
    });
    // iccid卡号对话框中复选框触发事件
    const handleCardCheck = (val: boolean) => {
      console.log("check_card", val);
      if (val) {
        dataMap.cardForm.iccidPri = 1;
      } else {
        dataMap.cardForm.iccidPri = 0;
      }
      console.log(dataMap.cardForm);
    };
    // 页面对话框初始状态
    const showDialogUpload = ref(false);
    const showDialogUploadIccid = ref(false);
    const showDialogFormRenewal = ref(false);
    const showDialogFormVip = ref(false);
    const showDialogUserInfo = ref(false);
    const showDialogFriendsInfo = ref(false);

    const checkType_card = ref(false);
    const dataMap = reactive({
      GroupList: [],
      totalSelectGroup: 0,
      tableKey: 0,
      gids: Array<any>(),
      list: Array<userListModel>(),
      groupList: ref<any>(),
      uids: Array<userSelectUid>(),
      total: 0,
      funcValueList: [],
      amount: "",
      threetotalCount: 0, // 三年虚拟币总数
      threeusedCount: 0, // 三年虚拟币已用
      virtualTotal: 0, // 当前管理员虚拟币(三年类型)

      PreferensurplusCount: 0, // 优惠账号续费币 可用
      PreferentotalCount: 0, // 优惠账号续费币总数
      PreferenusedCount: 0, // 优惠账号续费币已用

      tradeTotal: 0, // 可用管理员行业币数量
      tradetotalCount: 0, // 行业币总数
      tradeusedCount: 0, // 行业币已用

      virtualOneTotalCount: 0, // 一年虚拟币总数
      virtualOneTotalUsed: 0, // 一年虚拟币已用
      virtualOneTotal: 0, // 可用管理员虚拟币(一年类型)

      virtualOneRenewTotal: 0, // 当前管理员下的一年期续费币
      virtualOnetotalCount: 0, // 一年续费币总数
      virtualOneusedCount: 0, // 一年续费币已用

      virtualRenewTotal: 0, // 当前管理员三年期续费币
      virtualRenewtotalCount: 0, // 三年续费币总数
      virtualRenewusedCount: 0, // 三年续费币已用

      permanentCount: 0, // 当前管理员下的终身币可用数量
      permanentTotalCount: 0, // 终身币总数
      permanentUsedCount: 0, // 终身币已用

      vipTotal: 0, // 可用管理员vip币数量
      viptotalCount: 0, // vip币总数
      vipusedCount: 0, // vip币已用
      noonline: "",
      name: null,
      listLoading: false,
      filename: "",
      val: 10,
      radio: true,
      radioTable: "",
      keyValue: {
        value: "account",
        label: ""
      },
      listQuery: {
        page: {
          pageNo: 1,
          pageSize: 10
        },
        pttUserEntity: {}
      },
      listGroupQuery: {
        page: {
          pageNo: 1,
          pageSize: 10
        },
        pttGroupEntity: {
          idOrName: ""
        }
      },
      listGroupSelectQuery: {
        pageNo: 1,
        pageSize: 10,
        uids: [],
        gname: ""
      },
      downloadLoading: false,
      totalGroup: 0,
      downallloadLoading: false,
      funcForm: {
        name: "",
        account: "",
        uid: "",
        total: 0,
        funcValueList: ref<any>([])
      },
      groupForm: {
        defaultGroupGid: "",
        uids: []
      },
      cardForm: {
        masterIccid: "",
        slaveIccid: "",
        agentpwd: "",
        uid: "",
        iccidPri: 0
      },
      accountList: [
        {
          value: "account",
          label: t("table.Account")
        },
        {
          value: "uid",
          label: t("table.ID")
        },
        {
          value: "name",
          label: t("table.name")
        },
        {
          value: "gid",
          label: t("table.groupId")
        },
        {
          value: "remarks",
          label: t("table.remarks")
        },
        {
          value: "imei",
          label: t("table.IMEI")
        },
        {
          value: "defaultGroupName",
          label: t("table.defaultgroup")
        },
        {
          value: "expireAccount",
          label: t("table.Expiringusers")
        }
      ],
      friendsform: {
        uid: "",
        account: "",
        name: "",
        friends: ref<any>([])
      },
      uploadform: {
        type: ref(3),
        defaultGid: [],
        gpsInterval: 0,
        fgid: ref<any>([]),
        months: 0,
        file: "1"
      },
      uploadIccidform: {
        type: ref(3),
        defaultGid: ref<any>([]),
        gpsInterval: 0,
        fgid: ref<any>([]),
        months: 0,
        agentpwd: "",
        file: "1"
      },

      funcFidsList: ref<any>([]),
      validMonth: ref<any>(),

      registerCount: 0,
      onlineCount: 0,
      onlinefalse: 0,

      renewalType: 0, // 续费时使用的币类型
      surlpusCount: 0, // 管理员虚拟币数量
      renewSurlpusCount: 0, // 管理员续费币数量
      tradeSurlpusCount: 0, // 管理员行业币数量
      funceditList: Array<statefuncedit>(),
      multi: false, // 批量修改用户信息按钮状态
      multiImei: false, // 批量修改用户imei按钮状态
      fileList: {},
      systempremmsion: localStorage.getItem("systempremmsion"),
      toggle: {
        adminId: "",
        domain: ""
      },
      // 表头颜色
      headerColor() {
        return 'row-table-header';
      },
      // 表格颜色
      rowColor(val: any) {
        if (val.row.type == 1) {
              // 快过期
              return 'row-table-expiring'
            } else if (val.row.type == -1) {
              // 已经到期
              return 'row-table-maturity'
            }
        return '';
      },
      // 数据列颜色
      tableDataRow(){
        return 'table-row-data'
      },
      handleCurrentRiadioChange(val: any) {
        mutilGroupSeclection.value = val;
        console.log(val);
        dataMap.groupForm.defaultGroupGid = val.gid;
      },
      // 群组添加分页
      handlegroupSizeSelectChange(val?: any) {
        dataMap.getbatchGroupList(1, val);
      },
      handleCurrentSelectChange(val: any) {
        console.log(val);
        dataMap.gids = []
        for (let item of val) {
          dataMap.gids.push(item.gid);
        }
        console.log(dataMap.gids);
      },
      remoteMethod(query: string) {
        console.log(query, 111);
        if (query) {
          setTimeout(() => {
            dataMap.toUpload(query);
          });
        } else {
          dataMap.toUpload(query);
        }
      },
      remoteMethodICCID(query: string) {
        if (query) {
          setTimeout(() => {
            dataMap.toUploadICCID(query);
          });
        } else {
          dataMap.toUploadICCID(query);
        }
      },
      // 批量添加群组确定
      async submitGroupSelectToggle() {
        const { data }: any = await api.list.createSelectGroup({
          uids: dataMap.uids,
          gids: dataMap.gids
        });
        if (data.status == 200) {
          ElMessage.success(t("table.success"));
          dialogTableVisible.value = false;
        }
        console.log(data);
      },
      // 修改默认群组确定
      async submitGroupToggle() {
        if (dataMap.groupForm.defaultGroupGid == "") {
          ElMessage.error(t("input.Please select a default group"));
        } else {
          dialogFormGroup.value = false;
          const { data }: any = await api.list.updateDefaultGroup({
            defaultGroupGid: dataMap.groupForm.defaultGroupGid,
            uids: dataMap.uids
          });
          if (data.status === 200) {
            dialogFormGroup.value = false;
            ElMessage.success(t("table.modify success"));
            dataMap.getList(1, 10);
          }
        }
      },
      // 卡号确定
      submitCardToggle() {
        formcardbute.value.validate(async (valid: any) => {
          if (valid) {
            const { data }: any = await api.table.cardset(dataMap.cardForm);
            if (data.status === 200) {
              dialogFormCard.value = false;
              formcardbute.value.resetFields();
              ElMessage.success(t("table.modify success"));
              dataMap.getList(1, 10);
            }
          } else {
            return false;
          }
        });
      },
      // 切换vip功能组
      async changeFuncVip(value: any) {
        if(value.length == 0 ){
          vipform.useVirtuaOneRenew = 0
          vipform.useVipTradeCount = 0
        } else {
          // 查询所勾选账号续费时所消耗的所有币类型和数量
          const count: any = await api.detail.currencyretrievevip({
            uids: dataMap.uids,
            fgids: value
          });
          vipform.useVirtuaOneRenew = count.data.data.vipRenewSum;
          vipform.useVipTradeCount = count.data.data.industrySum
        }
      },
      // 切换功能组
      async changeFunc(value: any) {
        if(value.length == 0 ){
          console.log('true',value);
          renewform.useVirtuaOneRenew = 0;
          renewform.useVirtuaRenew = 0;
          renewform.useRenew = 0;
          renewform.useTradeCount = 0;
        } else {
          // 查询所勾选账号续费时所消耗的所有币类型和数量
          const count: any = await api.revise.renewCount({
            uids: dataMap.uids,
            fgids: value
          });
          renewform.useVirtuaOneRenew = count.data.data.oneRenewSum;
          renewform.useVirtuaRenew = count.data.data.threeRenewSum;
          renewform.useRenew = count.data.data.discountRenewSum;
          renewform.useTradeCount = count.data.data.industrySum;
        }
      },
      getCurrentRow(row: any) {
        console.log(row );
      },
      async searchadmin(id?: any) {
        const userId = localStorage.getItem("loginId");
        let adminId: any = "";
        console.log(id, 111);
        if (id) {
          adminId = id;
        } else {
          adminId = userId;
        }
        const { data }: any = await api.list.serarchadmin({ id: adminId });
        console.log('各种币信息',data.data, "data");
        dataMap.threetotalCount = data.data.currency1.totalCount;
        dataMap.threeusedCount = data.data.currency1.usedCount;
        dataMap.virtualTotal = data.data.currency1.surplusCount;

        dataMap.PreferensurplusCount = data.data.currency2.surplusCount;
        dataMap.PreferentotalCount = data.data.currency2.totalCount;
        dataMap.PreferenusedCount = data.data.currency2.usedCount;

        dataMap.tradeTotal = data.data.currency3.surplusCount;
        dataMap.tradetotalCount = data.data.currency3.totalCount;
        dataMap.tradeusedCount = data.data.currency3.usedCount;

        dataMap.virtualOneTotal = data.data.currency11.surplusCount;
        dataMap.virtualOneTotalCount = data.data.currency11.totalCount;
        dataMap.virtualOneTotalUsed = data.data.currency11.usedCount;

        dataMap.virtualOneRenewTotal = data.data.currency12.surplusCount;
        dataMap.virtualOnetotalCount = data.data.currency12.totalCount;
        dataMap.virtualOneusedCount = data.data.currency12.usedCount;

        dataMap.virtualRenewTotal = data.data.currency32.surplusCount;
        dataMap.virtualRenewtotalCount = data.data.currency32.totalCount;
        dataMap.virtualRenewusedCount = data.data.currency32.usedCount;

        // 终身币数量
        // dataMap.permanentCount = data.data.currency4.surplusCount; // 可用
        // dataMap.permanentTotalCount = data.data.currency4.totalCount; // 总数
        // dataMap.permanentUsedCount = data.data.currency4.usedCount; // 已用

        dataMap.vipTotal = data.data.currency5.surplusCount; // 可用
        dataMap.viptotalCount = data.data.currency5.totalCount; //总数
        dataMap.vipusedCount = data.data.currency5.usedCount; // 已用
      },
      // 功能确定
      submitDisToggle() {
        formdistribute.value.validate(async (valid: any) => {
          if (valid) {
            const { data }: any = await api.table.setfunction({
              uid: dataMap.funcForm.uid,
              funcValueList: dataMap.funcForm.funcValueList
            });
            if (data.status === 200) {
              dialogFormtoggle.value = false;
              formdistribute.value.resetFields();
              ElMessage.success(t("table.modify success"));
              dataMap.funceditList = [];
              dataMap.getList(1, 10);
            }
          } else {
            return false;
          }
        });
      },
      async getamount() {
        const { data }: any = await api.detail.aggrefateInfo();
        if(data){
       dataMap.amount = data.data.registerCount;
        dataMap.noonline = data.data.onlineCount;

        dataMap.registerCount = Number(data.data.registerCount);
        dataMap.onlineCount = Number(data.data.onlineCount);

        let acount = 0;
        acount = dataMap.registerCount - dataMap.onlineCount;
        dataMap.onlinefalse = Number(acount);
        }else{
          return 
        }
 
      },
      // 批量修改群组内的条件查询
      toGroupSearch() {
        dataMap.getGroupList(1, 10, dataMap.listGroupQuery.pttGroupEntity);
      },
      // 批量添加群组内查询
      toGroupSelectSearch() {
        dataMap.getbatchGroupList(1, 10, dataMap.listGroupSelectQuery.gname);
      },
      // 批量添加群组重置
      toGroupSelectReset() {
        dataMap.listGroupSelectQuery.gname = "";
        dataMap.getbatchGroupList(1, 10, dataMap.listGroupSelectQuery.gname);
      },
      toGroupReset() {
        dataMap.listGroupQuery.pttGroupEntity.idOrName = "";
      },
      closeGroupdialog() {
        dialogFormGroup.value = false;
        dataMap.listGroupQuery.pttGroupEntity.idOrName = "";
      },
      closeGroupSelectdialog() {
        dialogTableVisible.value = false;
        dataMap.listGroupSelectQuery.gname = "";
      },
      closeCarddialog() {
        dialogFormCard.value = false;
        formcardbute.value.resetFields();
      },
      closedialog() {
        dialogFormtoggle.value = false;
        formdistribute.value.resetFields();
      },
      // 分页
      handleSizeChange(val: any) {
        dataMap.val = val;
        dataMap.getList(
          1,
          val,
          dataMap.listQuery.pttUserEntity,
          route.query.adminId
        );
      },
      //
      handlegroupSizeChange(val: any) {
        dataMap.getGroupList(1, val);
      },
      handlegroupCurrentChange(page?: any) {
        dataMap.getGroupList(page);
      },
      handlegroupCurrentSelectChange(page?: any) {
        dataMap.getbatchGroupList(page, dataMap.listGroupSelectQuery.pageSize);
      },
      handleCurrentChange(page?: any) {
        dataMap.listQuery.page.pageNo = page
        localStorage.setItem("userListPageNo", page);
        dataMap.listQuery.pttUserEntity = {
          [dataMap.keyValue.value]: dataMap.keyValue.label
        };
        console.log('翻页信息', page, dataMap.val, dataMap.listQuery.pttUserEntity);

        dataMap.getList(
          page,
          dataMap.val,
          dataMap.listQuery.pttUserEntity,
          route.query.adminId
        );
      },
      // 跳转添加
      toRouterUserAdd() {
        console.log(router);
        router.push({ path: "/useradd" });
      },
      // 表格勾选列
      handleSelectionChange: (val: any) => {
        multipleSelection.value = val;
        dataMap.uids = [];
        for (const selecteuid of val) {
          dataMap.uids.push(selecteuid.uid);
        }
        console.log(toRaw(dataMap.uids));
      },
      // 激活/暂停操作
      handleActions(e: any) {
        console.log(e, "type?", dataMap.uids.length, "key.length");
        if (dataMap.uids.length < 1) {
          ElMessage({
            message: t("input.Please tick Users"),
            type: "warning"
          });
        } else {
          ElMessageBox.confirm(t("input.Whether to continue"))
            .then(() => {
              // dataMap.listLoading = true;
              var res: any;
              if (e == "suspend") {
                res = api.list.suspend({ uids: dataMap.uids });
              } else {
                res = api.list.activation({ uids: dataMap.uids });
              }
              dataMap.getList(1, 10, {});

              if (res && res.value.status == 200) {
                ElMessage({
                  message: t("input.success"),
                  type: "success"
                });
              } else {
                ElMessage.error(res.message);
              }
            })
            .catch(() => {});
        }
      },
      async toICCIDUpload() {
        router.push({ path: "/iccidadd" });
      },
      // 导入表格创建用户
      async toUpload(gname?: any) {
        // 查询管理员下可加入的群组
        const userNoJoinGroupsList: any = await api.list.userNoJoinGroupsList({
          pageNo: 1,
          pageSize: 1000,
          gname: gname
        });
        const lList: any[] = [];
        for (const i of userNoJoinGroupsList.data.data.resultList) {
          lList.push({
            key: i?.gid,
            name: i?.name,
            label: i?.name
          });
        }
        dataMap.groupList = lList;

        await dataMap.getAdminFuncGroupList();
        await dataMap.getAdminValidYears();
        dataMap.uploadform.months = dataMap.validMonth;
        showDialogUpload.value = true;
      },
      closeImportUser() {
        clearUserList.value.clearFiles();
        importFormRef.value.resetFields();
        dataMap.uploadform.file = "";
        showDialogUpload.value = false;
      },
      closeImportICCID() {
        clearIccidList.value.clearFiles();
        importICCIDRef.value.resetFields();
        showDialogUploadIccid.value = false;
      },

      // 导入表格创建用户(根据ICCID创建)
      async toUploadICCID(gname?: any) {
        // 查询管理员下可加入的群组
        const userNoJoinGroupsList: any = await api.list.userNoJoinGroupsList({
          pageNo: 1,
          pageSize: 1000,
          gname: gname
        });
        const lList: any[] = [];
        for (const i of userNoJoinGroupsList.data.data.resultList) {
          lList.push({
            key: i?.gid,
            name: i?.name,
            label: i?.name
          });
        }
        dataMap.groupList = lList;

        await dataMap.getAdminFuncGroupList();
        await dataMap.getAdminValidYears();
        dataMap.uploadIccidform.months = dataMap.validMonth;
        showDialogUploadIccid.value = true;
      },

      // 提交导入ICCID
      submitUploadIccid() {
        importICCIDRef.value.validate(async (valid: any) => {
          if (valid) {
            dataMap.importIccidData();
          } else {
            return false;
          }
        });
      },
      // 提交导入用户
      submitUpload() {
        importFormRef.value.validate(async (valid: any) => {
          console.log('????:',valid)
          if (valid) {
            console.log('导入user提交:',valid)
            dataMap.importUsersData();
          } else {
            return false;
          }
        });
      },

      // 批量绑定IMEI
      bindingIMEI() {
        if (dataMap.uids.length < 1) {
          // 判断是否有勾选账号
          ElMessage({
            message: t("input.Please tick Users"),
            type: "warning"
          });
        } else {
          console.log(toRaw(dataMap.uids).join(","));
          const uidsIMEI = toRaw(dataMap.uids).join(",");
          router.push({ path: "/userbatchImei", query: { uids: uidsIMEI } });
        }
      },
      // 批量加入群组
      async addselectGroup() {
        if (dataMap.uids.length < 1) {
          // 判断是否有勾选账号
          ElMessage({
            message: t("input.Please tick Users"),
            type: "warning"
          });
        } else {
          dataMap.getbatchGroupList(1, 10, "", dataMap.uids);
          dialogTableVisible.value = true;
        }
      },
      // 获取批量添加群组中群组的接口
      async getbatchGroupList(
        page?: any,
        pageSize?: any,
        gname?: any,
        uids?: any
      ) {
        if (page) {
          dataMap.listGroupSelectQuery.pageNo;
        }
        if (pageSize) {
          dataMap.listGroupSelectQuery.pageSize;
        }
        if (gname) {
          dataMap.listGroupSelectQuery.gname = gname;
        }
        if (uids) {
          dataMap.listGroupSelectQuery.uids = uids;
        }
        dataMap.listGroupSelectQuery.pageNo = page;
        dataMap.listGroupSelectQuery.pageSize = pageSize;
        const data: any = await api.list.searchGroupCreate(
          dataMap.listGroupSelectQuery
        );
        dataMap.GroupList = data?.data.data.resultList;
        dataMap.totalSelectGroup = data?.data.data.totalCount;
        console.log(dataMap.totalSelectGroup);
      },
      // vip 批量续费初始化
      async cuurencygrantvip() {
        const unExpiredAccountList = multipleSelection.value.filter(
          item => item.type != 1 && item.type != -1
        );
        if (dataMap.uids.length < 1) {
          // 判断是否有勾选账号
          ElMessage({
            message: t("input.Please tick Users"),
            type: "warning"
          });
        } else {
          // 查询管理员设置的有效年份
          const validYears: any = await api.detail.validYears();
          const searchfuncList: any = await api.detail.seacrchfuncgropList({
            pageNo: 1,
            pageSize: 10
          });
          dataMap.funcFidsList = searchfuncList.data.data;
          // 查询管理员下所有币数量
          const Allcount: any = await api.detail.adminAllVirtua({});
          vipform.virtua = Allcount.data.data[5];
          vipform.tradeCount = Allcount.data.data[3]

          // 勾选的账号总数
          vipform.userCount = multipleSelection.value.length;
          vipform.nameList = multipleSelection.value;

          showDialogFormVip.value = true;
        }
      },
      // 批量续费初始化
      async batchRenewal() {
        // 查询未到期的账号 type=1为快到期账号 type=-1为已到期账号
        const unExpiredAccountList = multipleSelection.value.filter(
          item => item.type != 1 && item.type != -1
        );
        console.log('??~~',unExpiredAccountList)
        // 判断是否是相同类型的账号（1年或3年）续费 并且排除终身账号类型  oldType [0为三年期,1为绑定三级管理员的特殊账号,3为一年期,4为终身,6为三年拆分一年,7为终身拆分一年,8为其他类型预留的]
        const oneYearAccountList = multipleSelection.value.filter(item => item.oldType == 3)  // 一年期账号
        const preferentialAccountList = multipleSelection.value.filter(item => item.oldType == 6) // 优惠账号
        const lifelongAccountList = multipleSelection.value.filter(item => item.oldType == 4)   // 终身账号
        const lifelong2AccountList = multipleSelection.value.filter(item => item.oldType == 7)  // 终身拆分账号

        // console.log('??',multipleSelection.value)
        // console.log('一年期:', oneYearAccountList, "终身:",lifelongAccountList, "终身拆分",lifelong2AccountList,'账号数量:',multipleSelection.value.length)
        // console.log('一年长度',oneYearAccountList.length)


        if (dataMap.uids.length < 1) {
          // 判断是否有勾选账号
          ElMessage({
            message: t("input.Please tick Users"),
            type: "warning"
          });
        }
        else if (unExpiredAccountList.length > 0) {
          // 判断所勾选用户中是否有未到期账号(账号时常小于3个月)
          ElMessage({
            message: '请选择快到期用户进行续费.',
            type: 'warning'
          })
        }
        else if (oneYearAccountList.length != 0 && oneYearAccountList.length != multipleSelection.value.length){
          ElMessage({
            message: '请选择相同类型的账号续费1',
            type: "warning"
          })
        }
        else if (preferentialAccountList.length != 0 && preferentialAccountList.length != multipleSelection.value.length){
          ElMessage({
            message: '请选择相同类型的账号续费2',
            type: "warning"
          })
        }

        else if (lifelongAccountList.length != 0) {
          ElMessage({
            message: "终身账号不支持续费",
            type: "warning"
          })
        }
        else if (lifelong2AccountList.length != 0) {
          ElMessage({
            message: "暂不支持续费",
            type: "warning"
          })
        }
        else {
          renewform.fids = [];
          renewform.useVirtuaOneRenew = 0;
          renewform.useVirtuaRenew = 0;
          renewform.useRenew = 0;
          renewform.useTradeCount = 0;
          // 查询管理员设置的有效年份
          const validYears: any = await api.detail.validYears();
          const searchfuncList: any = await api.detail.seacrchfuncgropList({
            pageNo: 1,
            pageSize: 10
          });
          dataMap.funcFidsList = searchfuncList.data.data;
          console.log(dataMap.funcFidsList, "searchfuncList");

          const validMonth: any = validYears.data.data == 3 ? 36 : 12;
          renewform.validMonths = validMonth;

          // 查询管理员下所有币数量
          const Allcount: any = await api.detail.adminAllVirtua({});
          console.log(Allcount, "总数");
          renewform.virtua = Allcount.data.data[1];
          renewform.renew = Allcount.data.data[2];
          renewform.virtuaRenew = Allcount.data.data[32];
          renewform.virtuaOneRenew = Allcount.data.data[12];
          renewform.tradeCount = Allcount.data.data[3]
          // 勾选的账号总数
          renewform.userCount = multipleSelection.value.length;
          renewform.nameList = multipleSelection.value;
          console.log(renewform, "数据源");
          console.log(renewform.nameList, "类型",renewform.nameList[0].oldType);
          if(renewform.nameList[0].oldType == 0 ){
            console.log('介是?',isRenewType)
            isRenewType.value = true
          }
          showDialogFormRenewal.value = true;
        }
      },
      // 切换续费模式
      async clickRenewType(){
        const fids: any = renewform.fids;
        console.log('切?',isRenew.value)
        console.log('查',fids, fids.length)
        if(fids.length<=0){
          console.log('查111',fids, fids.length)
        }else {
          // 查询所勾选账号续费时所消耗的所有币类型和数量
          const count: any = await api.revise.renewCount({
            uids: dataMap.uids,
            fgids: fids,
            tag: isRenew.value
          });
          renewform.useVirtuaOneRenew = count.data.data.oneRenewSum;
          renewform.useVirtuaRenew = count.data.data.threeRenewSum;
          renewform.useRenew = count.data.data.discountRenewSum;
          renewform.useTradeCount = count.data.data.industrySum;
        } 
      },
      // 提交批量续费
      async submitRenewal() {
        const fids: any = renewform.fids;
        console.log("续费", dataMap.uids,isRenew.value,fids);
        
        const res: any = await api.revise.batchRenewal({
          uids: dataMap.uids,
          fgids: fids,
          tag: isRenew.value,
        });
        console.log(res, "res");
        if (res.data.status == 200) {
          ElMessage.success(t("input.Batch renewal successful"));
          dataMap.getList(1, 10);
        }
        showDialogFormRenewal.value = false;
      },
      // 提交viP续费币
      async submitVip() {
        console.log("vip续费", dataMap.uids);
        const fids: any = vipform.fids;
        const res: any = await api.detail.cuurencygrantvip({
          uids: dataMap.uids,
          fgids: fids
        });
        console.log(res, "res");
        if (res.data.status == 200) {
          ElMessage.success(t("input.Batch renewal successful"));
          dataMap.getList(1, 10);
        }
        showDialogFormVip.value = false;
      },
      // 可用虚拟币
      async getsurlpusCount() {
        const surlpusCount: any = await api.detail.adminVirtua({});
        dataMap.surlpusCount = surlpusCount.data.data;
      },
      // 可用续费币
      async getRenewSurlpusCount() {
        const renewCount: any = await api.detail.renewSurlpusCount({
          adminId: getLoginId()
        });
        dataMap.renewSurlpusCount = renewCount.data.data;
      },
      // 可用行业币
      async getTradeSurlpusCount() {
        const tradeSurlpusCount: any = await api.detail.tradeSurlpusCount({
          adminId: getLoginId()
        });
        dataMap.tradeSurlpusCount = tradeSurlpusCount.data.data;
      },

      // 获取管理员功能组
      async getAdminFuncGroupList() {
        const funcGroupData: any = await api.detail.groupFunList({
          pageNo: 1,
          pageSize: 1000
        });
        const funcGroup = funcGroupData.data.data;
        funcGroupList.funcList = funcGroup;
      },
      // 获取管理员设置的的有效年份
      async getAdminValidYears() {
        const validYears: any = await api.detail.validYears();
        dataMap.validMonth = validYears.data.data == 3 ? 36 : 12;
      },
      // 批量修改默认群组
      editGroup() {
        if (dataMap.uids.length < 1) {
          ElMessage.warning(t("input.Please tick Users"));
        } else {
          // 初始化数据
          dataMap.groupForm.defaultGroupGid = "";
          mutilGroupSeclection.value = [];
          dataMap.radioTable = "";
          dialogFormGroup.value = true;
        }
      },
      // 批量修改用户基础信息
      batchEditUserInfo() {
        if (dataMap.uids.length < 1) {
          ElMessage({
            message: t("input.Please tick Users"),
            type: "warning"
          });
        } else {
          multipleSelection.value.map((i: any) => {
            // 遍历选中的行,将部分列改为可编辑状态
            i.show = true;
          });
          dataMap.multi = true;
        }
      },
      // 批量删除
      batchDeleteUserInfo() {
        if (dataMap.uids.length < 1) {
          ElMessage({
            message: t("input.Please tick Users"),
            type: "warning"
          });
        } else {
          ElMessageBox.confirm(t("input.Are you sure to delete"), {
            confirmButtonText: t("table.confirm"),
            cancelButtonText: t("table.cancel"),
            type: "warning"
          })
            .then(async () => {
              console.log(dataMap.uids);
              const data: any = await api.table.userpttdelete({
                uids: dataMap.uids
              });
              console.log(data);
              if (data.data.status == 200) {
                ElMessage({
                  type: "success",
                  message: t("table.delete success")
                });
                dataMap.getList(1, 10);
              }
            })
            .catch(() => {
              // ElMessage({
              //   type: "info",
              //   message: "已取消"
              // });
            });
        }
      },

      // 提交批量修改账号信息
      async handleBatchEditUserInfo() {
        const userData: any = [];
        multipleSelection.value.map((i: any) => {
          userData.push(i);
        });
        const { data }: any = await api.revise.batchUserUpdate(userData);
        // 604错误被未拦截,此处无法处理, 200可以正常处理
        if (data.status == 604) {
          ElMessage.error(t("input.The modified account already exists"));
          dataMap.getList(1, 10);
        } else if (data.status == 200) {
          ElMessage.success(t("input.The batch modification is successful"));
          dataMap.getList(1, 10);
          multipleSelection.value.map((i: any) => {
            i.show = false;
          });
        }
        dataMap.multi = false;
      },
      // 取消批量修改账号信息
      cancelmutil() {
        dataMap.multi = false;
        dataMap.getList(1, 10, {});
      },

      // 双击可编辑
      async openDetails(row: any) {
        console.log(row);
        const day =
          new Date(JSON.parse(JSON.stringify(row.validDate))).getTime() -
          new Date().getTime();
        const dayCount = Math.floor(day / 86400000);
        const systempremmsion = localStorage.getItem("systempremmsion");
        if (systempremmsion === "二级管理" && dayCount < 89) {
          ElMessage.error(t("input.Valid for less than 90 days"));
        } else if (
          systempremmsion === "一级管理" ||
          systempremmsion === "高级管理"
        ) {
          ElMessage.error(t("input.Insufficient permissions"));
        } else if (
          (row.type == -1 || row.type == 1) &&
          systempremmsion != "总管理"
        ) {
          ElMessage.error(t("input.Expiring/expired cannot be modified"));
        } else {
          row.showImei = true;
        }
      },
      // 失去焦点自动提交
      async updateIMEI(row: any) {
        if (dataMap.multiImei == true) {
        } else {
          const userData = [];
          userData.push(row);
          const { data }: any = await api.revise.batchUserImeiUpdate(userData);
          if (data.status == 200) {
            ElMessage.success(t("table.modify success"));
            dataMap.getList(1, 10);
            row.showImei = false;
          } else {
            ElMessage.error(data.message);

            dataMap.getList(1, 10);
          }
        }
      },
      // 批量修改账号IMEI(不放号)
      batchEditUserIMEI() {
        if (dataMap.uids.length < 1) {
          ElMessage({
            message: t("input.Please tick Users"),
            type: "warning"
          });
        } else {
          multipleSelection.value.map((i: any) => {
            // 遍历选中的行,将部分列改为可编辑状态
            i.showImei = true;
          });
          dataMap.multiImei = true;
        }
      },
      // 提交批量修改账号IMEI(不放号)
      async handleBatchEditUserIMEI() {
        const userData: any = [];
        multipleSelection.value.map((i: any) => {
          userData.push(i);
        });
        const { data }: any = await api.revise.batchUserImeiUpdate(userData);
        if (data.status == 200) {
          ElMessage.success(t("input.The batch modification is successful"));
          dataMap.getList(1, 10);
          multipleSelection.value.map((i: any) => {
            i.showImei = false;
          });
        } else {
          ElMessage.error(data.message);
          dataMap.getList(1, 10);
        }
        dataMap.multiImei = false;
      },
      // 取消批量修改账号imei
      cancelmutilIemi() {
        dataMap.multiImei = false;
        dataMap.getList(1, 10, {});
      },
      // 查询
      toSearch() {
        this.listQuery.pttUserEntity = {
          [this.keyValue.value]: this.keyValue.label
        };
        localStorage.setItem("keyValueValue", this.keyValue.value);
        localStorage.setItem("keyValueLabel", this.keyValue.label);
        dataMap.getList(
          1,
          this.listQuery.page.pageSize,
          this.listQuery.pttUserEntity
        );
      },
      // 重置
      toReload() {
        this.keyValue.value = "account";
        this.keyValue.label = "";
        localStorage.removeItem("userListPageNo");
        localStorage.removeItem("keyValueLabel");
        localStorage.removeItem("keyValueValue");
        dataMap.getList(1, 10, {});
      },
      // 详情
      async showUserInfo(row: any) {
        console.log(row, "row?");
        ruleForm.uid = row.uid;
        ruleForm.account = row.account;
        ruleForm.password = row.password;
        ruleForm.name = row.name;
        ruleForm.defaultGroupName = row.defaultGroupName;
        ruleForm.gpsInterval = row.gpsInterval;
        ruleForm.createDate = row.createDate;
        ruleForm.onLine = row.onLine;
        ruleForm.validDate = row.validDate;
        ruleForm.usrValid = row.usrValid;
        ruleForm.defaultGid = row.defaultGid;
        ruleForm.imei = row.imei;
        ruleForm.remarks = row.remarks;
        // 查询当前账号所有的功能组
        const { data }: any = await api.detail.joinFuncGroupsList({
          uid: row.uid
        });
        console.log(data);
        joinFuncGroupsList.joinFuncList = data.data;
        showDialogUserInfo.value = true;
      },
      // 卡号
      async toCardEdit(row: any) {
        dialogFormCard.value = true;
        checkType_card.value = false;
        const { data }: any = await api.table.cardget({ uid: row.uid });
        dataMap.cardForm = data.data || {};
        dataMap.cardForm.uid = row.uid;
        if (data.data && data.data.iccidPri == 1) {
          checkType_card.value = true;
        }
        console.log("data?", dataMap.cardForm);
      },
      // 功能
      async tofuncEdit(row: any) {
        dialogFormtoggle.value = true;
        dataMap.getTradeSurlpusCount();

        dataMap.funcForm.account = row.account;
        dataMap.funcForm.name = row.name;
        dataMap.funcForm.uid = row.uid;
        dataMap.funcForm.funcValueList = row.funcsList || [];
      },

      // 修改好友
      async showUserFriends(row: any) {
        console.log(row, "row?", row.uid);
        dataMap.friendsform.uid = row.uid;
        dataMap.friendsform.account = row.account;
        dataMap.friendsform.name = row.name;
        // 查询用户好友数据
        const friendsList: any = await api.list.friendsList({
          uid: row.uid,
          page: { pageNo: 1, pageSize: 3000 }
        });
        // 查询用户非好友数据
        const noFriendsList: any = await api.list.noFriendsList({
          uid: row.uid,
          page: { pageNo: 1, pageSize: 3000 }
        });
        // 初始化穿梭框数据
        friendsDataList.data = [];
        const friends: any = friendsList.data.data.resultList;
        const nofriends: any = noFriendsList.data.data.resultList;
        const concatList: any = friends.concat(nofriends);
        for (const i of concatList) {
          friendsDataList.data.push({
            key: i?.uid,
            name: i?.name,
            label: i?.account
          });
        }
        for (const i of friendsList.data.data.resultList) {
          dataMap.friendsform.friends.push(i?.uid);
        }
        showDialogFriendsInfo.value = true;
      },
      // 好友修改提交
      async handleFriends() {
        const data: any = await api.revise.userAddFriends({
          uid: dataMap.friendsform.uid,
          friends: dataMap.friendsform.friends
        });
        if (data.status === 200) {
          ElMessage.success(t("table.modify success"));
          dataMap.getList(1, 10, {});
          showDialogFriendsInfo.value = false;
        } else {
          ElMessage.warning(data.message);
        }
      },
      // 用户列表是否显示"远程"按钮
      isShow() {
        // const systempremmsion = localStorage.getItem("systempremmsion");
        // if(systempremmsion != '总管理') {
        //   if (permessionList.value.includes('204')){
        //     return true
        //   } else {
        //     return false
        //   }
        // } else {
        //    return true
        // }
        return true
      },
      // 远程放号
      handleRemote(row: any) {
        // type: 已到期 -1, 正常 0, 快要到期 1, 续费过正常使用 2, 续费了vip的正常使用 3,
        //  续过费 并且快到期 4, 续费过vip 并且快到期 5
        const systempremmsion = localStorage.getItem("systempremmsion");
        if ((row.type == 1 || row.type == -1 ) && systempremmsion != "总管理") {
          ElMessage.error(
            t(
              "input.The account is about to expire or has expired, and remote number release is prohibited"
            )
          );
        } else {
          router.push({ path: "/userremote", query: { uid: row.uid } });
        }
      },
      // 跳转修改界面
      handleEdit(row: any) {
        console.log(this.keyValue.label);

        router.push({ path: "/userupdate", query: { uid: row.uid } });
      },

      // 单行删除
      async toRowDelete(row: any) {
        deleteList.uids.push(row.uid);
        const { data }: any = await api.table.usersDelete({
          uids: deleteList.uids
        });
        if (data.status === 200) {
          ElMessage.success(t("table.delete success"));
          dataMap.getList(1, 10, {});
        }
      },
      importUsersData() {
        const formData: any = new FormData();
        formData.append("file", dataMap.fileList);
        formData.append("months", dataMap.uploadform.months);
        formData.append("fgrps", dataMap.uploadform.fgid);
        formData.append("defaultGid", dataMap.uploadform.defaultGid);
        formData.append("gpsInterval", dataMap.uploadform.gpsInterval);
        formData.append("type", dataMap.uploadform.type);
        console.log('导入?',formData)
        axios
          .post(
            `${process.env.VUE_APP_BASE_API}api/user/ptt/import2`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "pc-access-token": localStorage.getItem("token"),
                "manager-web-session": localStorage.getItem("tokensession"),
                lang: getLanguage()
              }
            }
          )
          .then(res => {
            if (res.data.status !== 200) {
              ElMessage.error(res.data.message);
            } else {
              ElMessage.success(t("input.success"));
              clearUserList.value.clearFiles();
              dataMap.getList(1, 10);
            }
            showDialogUpload.value = false;
          });
      },
      httpRequest(item: any) {
        if (!/\.(xlsx|xls|XLSX|XLS)$/.test(item.file.name)) {
          ElMessage.error(
            t(
              "input.Uploaded files can only be excel files, and are in xlsx, xls format"
            )
          );
        } else {
          dataMap.fileList = {};
          dataMap.fileList = item.file;
        }
      },
      importIccidData() {
        const formData: any = new FormData();
        formData.append("file", dataMap.fileList);
        formData.append("type", dataMap.uploadIccidform.type);
        formData.append("months", dataMap.uploadIccidform.months);
        formData.append("fgrps", dataMap.uploadIccidform.fgid);
        formData.append("defaultGid", dataMap.uploadIccidform.defaultGid);
        formData.append("gpsInterval", dataMap.uploadIccidform.gpsInterval);
        formData.append("agentpwd", dataMap.uploadIccidform.agentpwd);

        axios
          .post(
            `${process.env.VUE_APP_BASE_API}api/user/ptt/import/iccid`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "pc-access-token": localStorage.getItem("token"),
                "manager-web-session": localStorage.getItem("tokensession"),
                lang: getLanguage()
              }
            }
          )
          .then(res => {
            if (res.data.status !== 200) {
              ElMessage.error(res.data.message);
            } else {
              ElMessage.success(t("input.success"));
              clearIccidList.value.clearFiles();
              dataMap.getList(1, 10);
            }
          });
        showDialogUploadIccid.value = false;
      },
      // 导出
      handleDownload() {
        console.log(multipleSelection.value);
        // const from = unref(multipleTableNode)
        if (
          multipleSelection.value != undefined &&
          multipleSelection.value.length !== 0
        ) {
          dataMap.downloadLoading = true;
          const tHeader = [
            "账号",
            "名字",
            "密码",
            "GPS上报周期",
            "默认群组",
            "状态",
            "过期时间",
            "在线(离线)时间"
          ];
          const filterVal = [
            "account",
            "name",
            "password",
            "gpsInterval",
            "defaultGroupName",
            "usrValid",
            "validDate",
            "lastStatusDate"
          ];
          const list = multipleSelection.value;
          const data = formatJson(filterVal, list);
          exportJson2Excel(
            tHeader,
            data,
            dataMap.filename !== "" ? dataMap.filename : undefined
          );
          // from.clearSelection()
          dataMap.downloadLoading = false;
        } else {
          ElMessage.warning(
            t("input.Please check the options you want to export first")
          );
        }
      },
      // 导出用户
      async handleallDownload() {
        const obj: any = {};
        dataMap.downallloadLoading = true;
        axios
          .post(
            `${process.env.VUE_APP_BASE_API}api/user/ptt/choose/export`,
            obj,
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "pc-access-token": localStorage.getItem("token"),
                "manager-web-session": localStorage.getItem("tokensession"),
                lang: getLanguage()
              },
              responseType: "arraybuffer"
            }
          )
          .then(res => {
            console.log(res);
            const blob: any = new Blob([res.data]);
            const downloadElement = document.createElement("a");
            const href = window.URL.createObjectURL(blob); // 创建下载的链接
            downloadElement.href = href;
            downloadElement.download = "用户列表.xlsx"; // 下载后文件名
            document.body.appendChild(downloadElement);
            downloadElement.click(); // 点击下载
            document.body.removeChild(downloadElement); // 下载完成移除元素
            window.URL.revokeObjectURL(href);
            dataMap.downallloadLoading = false;
          });
      },
      // 群组list
      async getGroupList(page?: any, pageSize?: any, pttGroupEntity?: any) {
        if (page) {
          dataMap.listGroupQuery.page.pageNo;
        }
        if (pageSize) {
          dataMap.listGroupQuery.page.pageSize;
        }
        if (pttGroupEntity) {
          dataMap.listGroupQuery.pttGroupEntity = pttGroupEntity;
        }

        dataMap.listGroupQuery.page.pageNo = page;
        dataMap.listGroupQuery.page.pageSize = pageSize;
        dataMap.listLoading = true;
        const data: any = await api.list.groupList(dataMap.listGroupQuery);
        dataMap.groupList = data?.data.data.resultList;
        dataMap.totalGroup = data?.data.data.totalCount;
        setTimeout(() => {
          dataMap.listLoading = false;
        }, 0.3 * 1000);
      },
      // 页面初始化数据
      async getList(
        page?: any,
        pageSize?: any,
        pttUserEntity?: any,
        adminId?: any
      ) {
        console.log('查分页用户-参数',page,pageSize,pttUserEntity)
        if (page) {
          dataMap.listQuery.page.pageNo;
        }
        if (pageSize) {
          dataMap.listQuery.page.pageSize = pageSize;
        }

        dataMap.listQuery.pttUserEntity = pttUserEntity;

        dataMap.listQuery.page.pageNo = page;
        dataMap.listQuery.page.pageSize = pageSize;
        dataMap.listLoading = true;
        if (route.query.adminId) {
          const data: any = await api.list.adminIdList({
            page: { pageNo: page, pageSize: pageSize },
            adminId: adminId
          });
          dataMap.list = data?.data.data.resultList;
          dataMap.total = data?.data.data.totalCount;
        } else {
          console.log('查询用户列表~',dataMap.listQuery)
          const data: any = await api.list.userList(dataMap.listQuery);
          dataMap.list = data?.data.data.resultList;
          dataMap.total = data?.data.data.totalCount;
        }
        dataMap.getamount();
        dataMap.getGroupList(1, 10);
        // 查询行业功能组
        // const datafunlist: any = await api.table.tradegrouplist();

        // 查询所有功能组
        const datafunlist: any = await api.detail.seacrchfuncgropList({
          pageNo: 1,
          pageSize: 100
        });
        dataMap.funceditList = [];
        for (const i of datafunlist.data.data) {
          dataMap.funceditList.push({
            id: String(i?.id),
            name: i?.name
          });
        }

        console.log(dataMap.funceditList);
        localStorage.removeItem("userListPageNo");
        setTimeout(() => {
          dataMap.listLoading = false;
        }, 0.3 * 1000);
      }
    });
    onMounted(() => {
      console.log('vue 初始化');
      let keyValueLabel = localStorage.getItem("keyValueLabel");
      let keyValueValue = localStorage.getItem("keyValueValue");
      let pageNumber = localStorage.getItem('keyValueValue');
      console.log('keyValueLabel',keyValueLabel);
      console.log('keyValueValue',keyValueValue);
      console.log('所有参数',dataMap.listQuery);
      console.log('第几页',dataMap.listQuery.page.pageNo );
      if (keyValueLabel) {
        dataMap.keyValue.label = keyValueLabel;
        dataMap.keyValue.value = keyValueValue || "account";
        dataMap.listQuery.pttUserEntity = {
          [dataMap.keyValue.value]: dataMap.keyValue.label
        };
        dataMap.getList(
          dataMap.listQuery.page.pageNo? dataMap.listQuery.page.pageNo:1,
          10,
          dataMap.listQuery.pttUserEntity,
          route.query.adminId
        );
      } else {
        dataMap.getList(1, 10, {}, route.query.adminId);
      }

      dataMap.getamount();
      dataMap.getGroupList(1, 10);
      console.log(route.query.adminId);
      dataMap.searchadmin(route.query.adminId);
    });

    // 表单验证
    const rules = reactive({});
    // 表单数据源
    const ruleForm = reactive({
      uid: null,
      account: "",
      password: null,
      name: "",
      defaultGroupName: "",
      gpsInterval: null,
      createDate: null,
      onLine: null,
      validDate: null,
      defaultGid: null,
      usrValid: null,
      imei: "",
      remarks: ""
    });
    const renewform = reactive({
      virtua: 0, // 三年期虚拟币
      renew: 0, // 优惠账号续费币
      userCount: 0, // 勾选的账号数量
      nameList: ref<any>([]),
      useVirtua: 0, // (旧)续费时消耗的虚拟币数量
      useRenew: 0, // 续费时消耗的优惠账号续费币数量
      validMonths: 0, // 续费月份
      virtuaRenew: 0, // 三年期续费币
      virtuaOneRenew: 0, // 一年期续费币
      useVirtuaRenew: 0, // 续费时消耗的三年期续费币数量
      useVirtuaOneRenew: 0, // 续费时消耗的一年期续费币数量
      tradeCount: 0, // 行业币总数
      useTradeCount: 0, // 续费时使用的行业币
      fids: []
    });
    const vipform = reactive({
      virtua: 0,
      userCount: 0,
      fids: [],
      useVirtuaOneRenew: 0,
      nameList: ref<any>([]),
      tradeCount:0,
      useVipTradeCount: 0,
    });
    return {
      t,
      ...toRefs(dataMap),
      showDialogUpload,
      showDialogUploadIccid,
      showDialogFormRenewal,
      showDialogFormVip,
      showDialogUserInfo,
      showDialogFriendsInfo,
      checkType_card,
      getloginName,
      getloginId,
      friendsDataList,
      funcGroupList,
      joinFuncGroupsList,
      handleCardCheck,
      rules,
      formRef,
      renewformRef,
      vipformRef,
      permessionList,
      deletePermession,
      dialogFormtoggle,
      dialogFormCard,
      dialogFormGroup,
      formdistribute,
      dialogTableVisible,
      importFormRef,
      importICCIDRef,
      formcardbute,
      ruluesFuncset,
      rulesFunc,
      cardRules,
      importUserRules,
      importICCID,
      singleTableRef,
      mutilGroupSeclection,
      clearUserList,
      currentRow,
      clearIccidList,
      ruleForm,
      renewform,
      vipform,
      isRenew,
      isRenewType,
    };
  }
});
