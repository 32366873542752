
import { computed, defineComponent } from "vue";
import SidebarItem from "./SidebarItem.vue";
import SidebarLogo from "./SidebarLogo.vue";
import variables from "@/styles/_variables.scss";
import { useStore } from "@/store";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    SidebarItem,
    SidebarLogo
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const sidebar = computed(() => {
      return store.state.app.sidebar;
    });
    const routes = computed(() => {
      return store.state.permission.routes;
    });
    const showLogo = computed(() => {
      return store.state.settings.showSidebarLogo;
    });

    const menuActiveTextColor = computed(() => {
      if (store.state.settings.sidebarTextTheme) {
        return "#57CAEB";
        // return store.state.settings.theme
      } else {
        return variables.menuActiveText;
      }
    });

    const activeMenu = computed(() => {
      const { meta, path } = route;
      if (meta !== null || meta !== undefined) {
        if (meta.activeMenu) {
          return meta.activeMenu;
        }
      }
      return path;
    });

    const isCollapse = computed(() => {
      return sidebar.value.opened;
    });

    return {
      sidebar,
      routes,
      showLogo,
      menuActiveTextColor,
      variables,
      activeMenu,
      isCollapse
    };
  }
});
