
import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { api } from "@/apis/user";
import { getLoginName, getLoginId } from "@/utils/cookies";
import { ElMessage } from "element-plus";
interface groupFunListInter {
  id: "";
  name: "";
  gid: "";
  loginName: "";
}
interface transferFunListInter {
  gid: "";
  name: "";
  id: "";
}
interface fgidsInter {
  months: any;
  targetId: any;
  fgids: any;
}
interface stateTransferForm {
  uids: any;
  gid: any;
  fromId: any;
  selectGid: any;
  targetId: any;
}
interface stateTypes {
  data: { key: String; label: String; name: String }[];
}

export default defineComponent({
  setup() {
    const getloginName = getLoginName();
    const route = useRoute();

    const getloginId = getLoginId();
    const router = useRouter();
    const { t } = useI18n();
    const yeardistribute = ref();
    const yearRules = reactive({
      years: [
        {
          required: true,
          message: t("input.Please select account valid time"),
          trigger: "change"
        }
      ]
    });
    const transferFormdataList = reactive<stateTypes>({
      data: []
    });
    const transferRules = reactive({
      fromId: [
        {
          required: true,
          message: t("input.Please select a group to migrate users"),
          trigger: "change"
        }
      ],
      targetId: [
        {
          required: true,
          message: t("input.Please select the target administrator"),
          trigger: "change"
        }
      ],
      gid: [
        {
          required: true,
          message: t("input.Please select target group"),
          trigger: "change"
        }
      ],
      uids: [
        {
          required: true,
          message: t("input.Please select the group"),
          trigger: "change"
        }
      ]
    });
    const transferdistribute = ref();
    const dialogtarbsfetdistribute = ref(false);
    const formRef = ref();
    const formdistribute = ref();
    const dialogFormVisible = ref(false);
    const dialogFormdistribute = ref(false);
    const dialogyearsdistribute = ref(false);

    // 表单验证
    const rules = reactive({
      count: [
        { required: true, message: t("input.Please input number") },
        { type: "number", message: t("input.Please input number") }
      ]
    });
    // 表单数据源
    const ruleForm = reactive({
      count: "",
      targetId: null,
      type: 1
    });
    const distributeForm = reactive<fgidsInter>({
      months: "",
      targetId: "",
      fgids: ""
    });
    const yearbuteForm = reactive({
      years: "",
      targetId: ""
    });
    // 表格数据源
    const dataMap = reactive({
      tableKey: 0,
      list: [],
      transferid: "",
      transferFormList: Array<transferFunListInter>(),
      lowestlevelList: Array<groupFunListInter>(),
      targetGroupList: Array<groupFunListInter>(),
      total: 0,
      title: "",
      transferForm: reactive<stateTransferForm>({
        gid: "",
        fromId: "",
        selectGid: "",
        targetId: "",
        uids: ref([])
      }),
      name: null,
      targetVirtualTotal: 0, // 目标管理下的虚拟币 (下发/回收时选择的管理员,三年类型)
      targetVirtualOneTotal: 0, // 目标管理下的虚拟币(一年类型)
      targetVirtualRenewTotal: 0, // 目标管理下的三年期续费币
      targetVirtualOneRenewTotal: 0, // 目标管理下的一年期续费币
      renewTotal: 0, //
      rowName: "",
      val: 10,
      targetTradeTotal: 0, // 目标管理下的行业币
      renewSurlpusCount: 0, // 当前管理员优惠账号续费币(三年拆分一年账号)
      targetRenewSurlpusCount: 0, // 目标管理下的优惠账号续费币

      threetotalCount: 0, // 三年虚拟币总数
      threeusedCount: 0, // 三年虚拟币已用
      virtualTotal: 0, // 当前管理员虚拟币(三年类型)

      PreferensurplusCount: 0, // 优惠账号续费币 可用
      PreferentotalCount: 0, // 优惠账号续费币总数
      PreferenusedCount: 0, // 优惠账号续费币已用

      tradetotalCount: 0, // 行业币总数
      tradeusedCount: 0, // 行业币已用
      tradeTotal: 0, // 当前管理员行业币数量

      virtualOneTotalCount: 0, // 一年虚拟币总数
      virtualOneTotalUsed: 0, // 一年虚拟币已用
      virtualOneTotal: 0, // 当前管理员虚拟币(一年类型)

      virtualOnetotalCount: 0, // 一年续费币总数
      virtualOneusedCount: 0, // 一年续费币已用
      virtualOneRenewTotal: 0, // 当前管理员下的一年期续费币

      virtualRenewTotal: 0, // 当前管理员三年期续费币

      virtualRenewtotalCount: 0, // 三年续费币总数
      virtualRenewusedCount: 0, // 三年续费币已用
      groupList: Array<groupFunListInter>(),
      yearsList: [
        {
          value: 1,
          label: t("table.oneyear")
        },
        {
          value: 3,
          label: t("table.threeyear")
        }
      ],
      options: [
        {
          value: 1,
          label: t("statistics.threeyearsvirtualcurrency")
        },
        {
          value: 11,
          label: t("statistics.Oneyearvirtualcurrency")
        },
        {
          value: 2,
          label: t("statistics.PromotionalAccountRenewalCoin")
        },
        {
          value: 3,
          label: t("statistics.Industrycurrency")
        },
        {
          value: 12,
          label: t("statistics.Oneyearrenewalcurrency")
        },
        {
          value: 32,
          label: t("statistics.Threeyearrenewalcurrency")
        },
        {
          value: 5,
          label: t("statistics.VIPrenewalcurrency")
        }
      ],
      listLoading: false,
      downloadLoading: false,
      loginName: "",
      // 分页查询参数
      listQuery: {
        page: {
          pageNo: 1,
          pageSize: 10
        },
        loginName: ""
      },

      //////////////////////////////////

      // 分页
      handleSizeChange(val: any) {
        dataMap.val = val;
        dataMap.getList(1, val, route.query.loginName);
      },
      filterMethod(query: any, item: any) {
        return item.name.indexOf(query) > -1 || item.label.indexOf(query) > -1;
      },
      // 查询管理详细数据
      async serarchadmin(id: any) {
        const { data }: any = await api.list.serarchadmin({
          id: id
        });
        dataMap.threetotalCount = data.data.currency1.totalCount;
        dataMap.threeusedCount = data.data.currency1.usedCount;
        dataMap.virtualTotal = data.data.currency1.surplusCount;

        dataMap.PreferensurplusCount = data.data.currency2.surplusCount;
        dataMap.PreferentotalCount = data.data.currency2.totalCount;
        dataMap.PreferenusedCount = data.data.currency2.usedCount;

        dataMap.tradeTotal = data.data.currency3.surplusCount;
        dataMap.tradetotalCount = data.data.currency3.totalCount;
        dataMap.tradeusedCount = data.data.currency3.usedCount;

        dataMap.virtualOneTotal = data.data.currency11.surplusCount;
        dataMap.virtualOneTotalCount = data.data.currency11.totalCount;
        dataMap.virtualOneTotalUsed = data.data.currency11.usedCount;

        dataMap.virtualOneRenewTotal = data.data.currency12.surplusCount;
        dataMap.virtualOnetotalCount = data.data.currency12.totalCount;
        dataMap.virtualOneusedCount = data.data.currency12.usedCount;

        dataMap.virtualRenewTotal = data.data.currency32.surplusCount;
        dataMap.virtualRenewtotalCount = data.data.currency32.totalCount;
        dataMap.virtualRenewusedCount = data.data.currency32.usedCount;
        console.log(data.data.currency1, "data");
      },
      //下发虚拟币
      handleIssued(row: any) {
        dataMap.loginName = row.loginName;
        ruleForm.targetId = row.id;
        dialogFormVisible.value = true;
        // // 查询目标管理员的虚拟币数量
        // dataMap.getTargetVirtuaCount(row.id)
        // // 查询目标管理员的行业币数量
        // dataMap.gettradeSurpusUser(row.id)
        // // 查询目标管理员的续费币数量
        // dataMap.getRenewSurlpusCount(row.id)
        console.log("row.id", row.id);
        dataMap.getAllVirtuaCountByAdmin(row.id);
        dataMap.title = t("table.Issue Credit");
      },
      // 回收虚拟币
      handleRecycle(row: any) {
        dataMap.loginName = row.loginName;
        ruleForm.targetId = row.id;
        dialogFormVisible.value = true;
        // // 查询目标管理员的虚拟币数量
        // dataMap.getTargetVirtuaCount(row.id)
        // // 查询目标管理员的行业币数量
        // dataMap.gettradeSurpusUser(row.id)
        // // 查询目标管理员的续费币数量
        // dataMap.getRenewSurlpusCount(row.id)
        console.log("row.id", row.id);
        dataMap.getAllVirtuaCountByAdmin(row.id);
        dataMap.title = t("table.Recover Credit");
      },
      // 迁移账号
      async handletransferSetting(row: any) {
        dataMap.rowName = row.loginName;
        dataMap.transferid = row.id;
        dialogtarbsfetdistribute.value = true;
        const groupListFromQuery = {
          adminId: row.id,
          page: {
            pageNo: 1,
            pageSize: 300,
            ttotalCount: 0
          }
        };
        const transferFormListdata: any = await api.table.grouoadminId(
          groupListFromQuery
        );
        dataMap.transferFormList = transferFormListdata.data.data.resultList;
        const lowestlevelListData: any = await api.table.lowestlevel({
          page: groupListFromQuery.page
        });
        let fliterAnyList: any[] = lowestlevelListData.data.data.resultList;
        const fileterLeveData = fliterAnyList.filter(
          item => item.loginName !== row.loginName
        );
        dataMap.lowestlevelList = fileterLeveData;
      },
      // 年限设置
      async handleyearSetting(row: any) {
        yearbuteForm.targetId = row.id;
        const { data }: any = await api.table.validyear({ targetId: row.id });
        yearbuteForm.years = data.data;
        dialogyearsdistribute.value = true;
      },
      // 分配功能组
      async handleDistribute(row: any) {
        dialogFormdistribute.value = true;
        distributeForm.targetId = localStorage.getItem("loginId");
        const allForm: any = await api.detail.allocation({ adminId: row.id });
        const fildsallForm: any[] = allForm.data.data.fgids;
        for (let i = 0; i < fildsallForm.length; i++) {
          distributeForm.fgids.push(fildsallForm[i].id);
        }
        distributeForm.months = allForm.data.data.months;
      },
      // 取消
      closeForm() {
        dialogFormVisible.value = false;
        formRef.value.resetFields();
      },
      closetransfer() {
        transferdistribute.value.resetFields();
        dialogtarbsfetdistribute.value = false;
        transferFormdataList.data = [];
      },
      // 提交下发虚拟币
      submit() {
        formRef.value.validate(async (valid: any) => {
          if (valid) {
            if (dataMap.title === t("table.Issue Credit")) {
              const { data }: any = await api.table.grant(ruleForm);
              if (data.status === 200) {
                dialogFormVisible.value = false;
                formRef.value.resetFields();
                dataMap.getList(1, 10);
                ElMessage.success(t("settings.success"));
              }
            } else if (dataMap.title === t("table.Recover Credit")) {
              const { data }: any = await api.table.recycleType(ruleForm);
              if (data.status === 200) {
                formRef.value.resetFields();
                dataMap.getList(1, 10);
                dialogFormVisible.value = false;
                ElMessage.success(t("settings.success"));
              }
            }
          } else {
            return false;
          }
        });
      },
      // 提交迁移用户
      submitTransferForm() {
        transferdistribute.value.validate(async (valid: any) => {
          if (valid) {
            dataMap.transferForm.fromId = dataMap.transferid;
            const status: any = await api.revise.tansferadminedit(
              dataMap.transferForm
            );
            if (status.data.status === 200) {
              ElMessage.success(t("settings.success"));
              transferdistribute.value.resetFields();
              dialogtarbsfetdistribute.value = false;

              dataMap.getList(1, 10);
            }
          } else {
            return false;
          }
        });
      },
      // 提交年限
      submityearForm() {
        yeardistribute.value.validate(async (valid: any) => {
          if (valid) {
            const { data }: any = await api.table.yearsEdit(yearbuteForm);
            if (data.status === 200) {
              dialogyearsdistribute.value = false;
              yeardistribute.value.resetFields();

              ElMessage.success(t("settings.success"));
            }
          }
        });
      },
      async toggeleLevelTraget(val: any) {
        const groupListFromQuery = {
          adminId: val,
          page: {
            pageNo: 1,
            pageSize: 100,
            ttotalCount: 0
          }
        };
        const { data }: any = await api.table.grouoadminId(groupListFromQuery);
        dataMap.targetGroupList = data.data.resultList;
      },
      async toggleTransfer(val: any) {
        transferFormdataList.data = [];
        const groupListFromQuery = {
          gid: val,
          pageNo: 1,
          pageSize: 100,
          ttotalCount: 0,
          type: 1
        };
        const rightList: any = await api.revise.groupmembers(
          groupListFromQuery
        );
        let rList: any = rightList.data.data.resultList;
        for (let i of rList) {
          transferFormdataList.data.push({
            key: i?.uid,
            name: i?.account,
            label: i?.name
          });
        }
      },
      // 提交功能组
      async submitDisForm() {
        if (distributeForm.fgids.length == 0) {
          ElMessage.error(t("input.Please select function group"));
        } else {
          const { data }: any = await api.table.allocationFrtp(distributeForm);
          if (data.status === 200) {
            dialogFormdistribute.value = false;
            ElMessage.success(t("settings.success"));
          }
        }
      },
      // 重置
      toReload() {
        dataMap.listQuery.loginName = "";
        dataMap.getList(1, 10);
      },
      // 添加

      toAdd() {
        router.push({ path: "/adminadd" });
      },
      handleCurrentChange(page: any) {
        dataMap.getList(page, dataMap.val, route.query.loginName);
      },
      toSearch() {
        dataMap.getList(
          1,
          this.listQuery.page.pageSize,
          dataMap.listQuery.loginName
        );
      },

      // 目标管理员下所有币数量
      async getAllVirtuaCountByAdmin(adminId: any) {
        const AllVirtua: any = await api.detail.adminAllVirtua({
          adminId: adminId
        });
        console.log("???ByAdmin", AllVirtua);
        dataMap.targetVirtualTotal = AllVirtua.data.data[1];
        dataMap.targetVirtualRenewTotal = AllVirtua.data.data[32];
        dataMap.targetRenewSurlpusCount = AllVirtua.data.data[2];
        dataMap.targetTradeTotal = AllVirtua.data.data[3];
        dataMap.targetVirtualOneTotal = AllVirtua.data.data[11];
        dataMap.targetVirtualOneRenewTotal = AllVirtua.data.data[12];
      },
      // 目标管理员的可用虚拟币
      async getTargetVirtuaCount(adminId: any) {
        const tradeCount: any = await api.detail.adminVirtua({
          adminId: adminId
        });
        dataMap.targetVirtualTotal = tradeCount.data.data;
      },

      // 目标管理可用行业币
      async gettradeSurpusUser(targetId: any) {
        const tradeCount: any = await api.detail.tradeSurlpusCount({
          adminId: targetId
        });
        dataMap.targetTradeTotal = tradeCount.data.data;
      },
      // 可用续费币
      async getRenewSurlpusCount(adminId: string) {
        const renewCount: any = await api.detail.renewSurlpusCount({
          adminId: adminId
        });
        dataMap.renewSurlpusCount = renewCount.data.data;
      },
      // 解冻
      async handleFreeze(index: number, row: any) {
        const { data }: any = await api.table.removeFreeze({ id: row.id });
        if (data.status == 200) {
          ElMessage.success(t("settings.success"));
        }
        dataMap.getList(1, 10);
      },
      //修改
      handleEdit(index: number, row: any) {
        router.push({ path: "/adminadd", query: { loginName: row.loginName } });
      },
      // 查询子集数据
      async nextLoginName(loginName: any, id: any) {
        router.push({
          path: "/sublist2",
          query: { loginName: loginName, adminId: id, type: 3 }
        });
      },
      // 页面初始化数据
      async getList(
        page?: any,
        pageSize?: any,
        loginName?: any,
        adminId?: any
      ) {
        if (page) {
          dataMap.listQuery.page.pageNo;
        }
        if (pageSize) {
          dataMap.listQuery.page.pageSize = pageSize;
        }
        dataMap.listQuery.page.pageNo = page;
        dataMap.listQuery.page.pageSize = pageSize;
        dataMap.listLoading = true;
        const data: any = await api.list.supadminList({
          page: { pageNo: page, pageSize: pageSize },
          loginName: loginName
        });
        dataMap.list = data?.data.data.resultList;

        dataMap.total = data?.data.data.totalCount;
        if (dataMap.list.length == 0) {
          router.push({
            path: "/userlist",
            query: { adminId: adminId, type: 4 }
          });
        }
        const detail: any = await api.detail.adminVirtua({}); // 查询可用的虚拟币
        const groupdata: any = await api.detail.groupFunList({
          pageNo: 1,
          pageSize: 1000
        });
        dataMap.groupList = groupdata.data.data;
        // dataMap.list = data?.data.data.resultList;
        // console.log("start", dataMap.list);
        dataMap.total = data?.data.data.totalCount;
        setTimeout(() => {
          dataMap.listLoading = false;
        }, 0.3 * 1000);
      }
    });
    onMounted(() => {
      dataMap.getList(1, 10, route.query.loginName, route.query.adminId);
      dataMap.serarchadmin(route.query.adminId);
    });

    return {
      t,
      ...toRefs(dataMap),
      transferRules,
      transferdistribute,
      dialogtarbsfetdistribute,
      dialogFormVisible,
      yearRules,
      dialogyearsdistribute,
      yeardistribute,
      yearbuteForm,
      getloginName,
      rules,
      formRef,
      ruleForm,
      formdistribute,
      distributeForm,
      dialogFormdistribute,
      router,
      transferFormdataList
    };
  }
});
