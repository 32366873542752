/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-28 17:18:17
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-23 09:47:33
 */

export default {
  route: {
    dashboard: "Dashboard",
    documentation: "Documentation",
    guide: "Guide",
    permission: "Permission",
    pagePermission: "Page Permission",
    rolePermission: "Role Permission",
    directivePermission: "Directive Permission",
    icons: "Icons",
    components: "Components",
    tinymce: "Tinymce",
    markdown: "Markdown",
    jsonEditor: "JSON Editor",
    splitPane: "SplitPane",
    avatarUpload: "Avatar Upload",
    dropzone: "Dropzone",
    sticky: "Sticky",
    countTo: "Count To",
    componentMixin: "Mixin",
    backToTop: "Back To Top",
    draggableDialog: "Draggable Dialog",
    draggableKanban: "Draggable Kanban",
    draggableList: "Draggable List",
    draggableSelect: "Draggable Select",
    charts: "Charts",
    barChart: "Bar Chart",
    lineChart: "Line Chart",
    mixedChart: "Mixed Chart",
    example: "Example",
    nested: "Nested Routes",
    menu1: "Menu 1",
    menu2: "Menu 2",
    table: "Table",
    dynamicTable: "Dynamic Table",
    draggableTable: "Draggable Table",
    inlineEditTable: "Inline Edit Table",
    complexTable: "Complex Table",
    tab: "Tab",
    form: "Form",
    createArticle: "Create Article",
    editArticle: "Edit Article",
    articleList: "Article List",
    errorPages: "Error Pages",
    page401: "401",
    page404: "404",
    errorLog: "Error Log",
    excel: "Excel",
    exportExcel: "Export Excel",
    selectExcel: "Export Selected",
    mergeHeader: "Merge Header",
    uploadExcel: "Upload Excel",
    zip: "Zip",
    pdf: "PDF",
    exportZip: "Export Zip",
    theme: "Theme",
    clipboard: "Clipboard",
    i18n: "I18n",
    externalLink: "External Link",
    profile: "Profile",
    userPages: "userPages",
    systemPages: "systemPages",
    domain: "domain",
    deployment: "deployment",
    userList: "userList",
    userAdd: "userAdd",
    userUpdate: "userUpdate",
    ICCIDADD: "ICCIDADD",
    groupUpdate: "groupUpdate",
    groupPages: "groupPages",
    groupList: "groupList",
    groupAdd: "groupAdd",
    GroupAdd: "GroupAdd",
    groupNested: "groupNested",
    logging: "logging",
    logsearch: "logsearch",
    statistics: "statistics",
    billinglog: "billinglog",
    changelog: "changelog",
    virtualCurrencyRecord: "virtualCurrencyRecord",
    virtualDetails: "virtualDetails",
    userLoginlog: "userLoginlog",
    administrator: "adminiStrator",
    adminlist: "adminList",
    adminadd: "adminAdd",
    transfervirtual: "transferVirtual",
    authorizationCode: "authorizationCode",
    parametersettingsPages: "parametersettingsPages",
    funclist: "funclist",
    function: "Function",
    funcgrouplist: "funcgrouplist",
    importcar: "importcar",
    importfile: "Import File",
    cardId: "CardId",
    fucgroupadd: "fucgroupadd",
    menuadministration: "menuadministration",
    roleadmin: "roleadmin",
    aboutus: "aboutus",
    "VIP batch renewal": "VIP batch renewal",
    "batch add groups": "Batch add groups",
    "Batch delete": "Batch delete",
    "Batch update IMEI": "Batch update IMEI"
  },
  navbar: {
    logOut: "Log Out",
    dashboard: "Dashboard",
    github: "Github",
    theme: "Theme",
    size: "Global Size",
    profile: "Profile"
  },
  login: {
    title: "Login Form",
    logIn: "Login",
    username: "Username",
    plaseusername: "plaseUsername",
    plasepassword: "plasePassword",
    plasecode:"please enter verification code",
    plaseinputcode: "Please enter correct verify code",
    "Remember Username":"Remember Username",
    password: "Password",
    any: "any",
    thirdparty: "Or connect with",
    thirdpartyTips:
      "Can not be simulated on local, so please combine you own business simulation! ! !"
  },
  dashboard: {
    totalusers: "TotalUsers",
    online: "on-line",
    offline: "off-line",
    avaiable: "Available virtual currency",
    all: "all",
    groupNum: "Number of groups"
  },
  documentation: {
    documentation: "Documentation",
    github: "Github Repository",
    "Management Platform Intercom Operation Instructions":
      "Management Platform Intercom Operation Instructions",
    "Open the Dispatcher": "Open the Dispatcher",
    "coding software": "coding software"
  },
  permission: {
    createRole: "New Role",
    editPermission: "Edit",
    roles: "Your roles",
    switchRoles: "Switch roles",
    tips:
      "In some cases, using v-permission will have no effect. For example: Element-UI  el-tab or el-table-column and other scenes that dynamically render dom. You can only do this with v-if.",
    delete: "Delete",
    confirm: "Confirm",
    cancel: "Cancel"
  },
  guide: {
    description:
      "The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on ",
    button: "Show Guide"
  },
  components: {
    documentation: "Documentation",
    tinymceTips:
      "Rich text is a core feature of the management backend, but at the same time it is a place with lots of pits. In the process of selecting rich texts, I also took a lot of detours. The common rich texts on the market have been basically used, and I finally chose Tinymce. See the more detailed rich text comparison and introduction.",
    stickyTips:
      "when the page is scrolled to the preset position will be sticky on the top.",
    backToTopTips1:
      "When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner",
    backToTopTips2:
      "You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally",
    imageUploadTips:
      "Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version."
  },
  table: {
    ID: "Acoount ID",
    comfirm: "comfirm",
    Account: "Account",
    oneyear: "one-year",
    AccountType: "Account Type",
    version: "Version",
    name: "Name",
    createBy: "createBy",
    Creator: "Superior Account",
    IP: "IP",
    IMEI: "IMEI",
    password: "Password",
    "Confirm password": "Confirm password",
    Company: "Company",
    Email: "Email",
    "Location save time": "Location save time",
    Mobile: "Mobile",
    Telephone: "Telephone",
    QQ: "QQ",
    "Manufactor code": "Manufactor code",
    "Allow login": "Allow login",
    allow: "allow",
    "not allowed": "Not allowed",
    Total: "Total",
    Used: "Used",
    Unused: "Available",
    onyear: "one-year",
    threeyear: "three-year",
    "Issue Credit": "Issue Credit",
    "Recover Credit": "Recover Credit",
    "Wait renewals": "Continued Renewals",
    "Set Years": "Set Years",
    "Set Permissions": "Set Permissions",
    Issue: "Issue",
    Recover: "Recover",
    "Migrate users": "Migrate users",
    "Migration administrator": "Migration administrator",
    "Target administrator": "Target administrator",
    "Migrate group": "Migrate group",
    "Target group": "Target group",
    designation: "Name",
    Members: "Group Members",
    Items: "Unselect",
    Selected: "Selected",
    Unselected: "Unselected",
    unfreeze: "unfreeze",
    "Are you sure to recycle": "Are you sure to recycle？",
    "account limited time": "Account limited time",
    Basic: "Basic",
    VIP: "VIP",
    Renewals: "Total Renewals",
    Remark: "Remark",
    dynamicTips1: "Fixed header, sorted by header order",
    dynamicTips2: "Not fixed header, sorted by click order",
    dragTips1: "The default order",
    dragTips2: "The after dragging order",
    title: "Title",
    importance: "Importance",
    type: "Type",
    remark: "Remark",
    search: "Search",
    add: "Add",
    export: "Export",
    exportall: "Export All",
    BatchName: "BatchName",
    defaultgroupID: "Default GroupID",
    defaultgroup: "Default Group",
    reviewer: "Reviewer",
    id: "ID",
    date: "Date",
    author: "Author",
    readings: "Readings",
    status: "Status",
    actions: "Actions",
    edit: "Edit",
    publish: "Publish",
    draft: "Draft",
    delete: "Delete",
    cancel: "Cancel",
    confirm: "Confirm",
    submit: "Submit",
    back: "Back",
    reset: "Reset",
    activation: "Activation",
    suspend: "Suspend",
    upload: "Upload",
    uploadICCID: "UploadICCID",
    bindingIMEI: "BindingIMEI",
    BatchRenewal: "BatchRenewal",
    editgroup: "EditGroup",
    BatchEdit: "BatchEdit",
    registerterminal: "RegisterTerminal",
    onlineterminal: "OnlineTerminal",
    online: "on-line",
    onlineStatus: "OnlineStatus",
    expireTime: "ExpireTime",
    accountnumber: "AccountNumber",
    uId: "user ID",
    validDate: "ValidDate",
    createTime: "CreateTime",
    lastTiem : "Online/offline time",
    sourceServer: "SourceServer",
    targetServer: "TargetServer",
    operationType: "The type of operation",
    actionobjects: "Action Objects",
    Downtimemigration: "Downtime migration",
    loadmigration: "Load migration",
    operatorAdmin: "Operations Administrator",
    loginImei: "IMEI",
    groupId: "GroupId",
    groupName: "GroupName",
    remarks: "Remarks",
    modify: "Modify",
    "Member priority": "Member priority",
    priority: "priority",
    primary: "Primary",
    mid: "Mid ",
    senior: "Senior",
    "modify success": "The modification was successful",
    "delete success": "The deletion was successful",
    detail: "Detail",
    friend: "Friend",
    success: "The addition succeeded",
    "Online (offline) time": "Online (offline) time",
    remotr: "Remotr",
    select: "Select",
    selectGroup: "Select group",
    Expiringusers: "Expiring users",
    Child: "Child",
    Parent: "Parent",
    Debriefingtype: "Debriefing type",
    Directivetype: "Directive type",
    remotrnum: "Remote number placement"
  },
  example: {
    warning:
      "Creating and editing pages cannot be cached by keep-alive because keep-alive's 'include' currently does not support caching based on routes, so now it's cached based on component name. If you want to achieve a similar caching effect, you can use a browser caching scheme such as localStorage. Or don't use keep-alive's 'include' to cache all pages directly. See details"
  },
  errorLog: {
    tips: "Please click the bug icon in the upper right corner",
    description:
      "Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.",
    documentation: "Document introduction"
  },
  excel: {
    export: "Export",
    selectedExport: "Export Selected Items",
    placeholder: "Please enter the file name (default excel-list)"
  },
  zip: {
    export: "Export",
    placeholder: "Please enter the file name (default file)"
  },
  pdf: {
    tips:
      "Here we use window.print() to implement the feature of downloading PDF."
  },
  theme: {
    change: "Change Theme",
    documentation: "Theme documentation",
    tips:
      "Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.",
    loading: "Theme change loading..."
  },
  tagsView: {
    refresh: "Refresh",
    close: "Close",
    closeOthers: "Close Others",
    closeAll: "Close All"
  },
  settings: {
    success: "success",
    title: "Page style setting",
    theme: "Theme Color",
    showTagsView: "Open Tags-View",
    showSidebarLogo: "Sidebar Logo",
    fixedHeader: "Fixed Header",
    sidebarTextTheme: "Sidebar Text Theme"
  },
  search: {
    adminId: "AdminID",
    account: "Account",
    remark: "Remark",
    startTime: "StartTime",
    endTime: "EndTime",
    onlinestatus: "Online Status",
    Time: "Time"
  },
  input: {
    enterAdminID: "Please enter the admin ID",
    "Please select the number of years": "请选择年限",
    Note:
      "The number of years is set to the validity period when the account is created",
    "Please select the group of the migrated user":
      "Please select the group of the migrated user",
    "Please select a group to migrate users":
      "Please select a group to migrate users",
    "Please select the target administrator":
      "Please select or input the target administrator",
    "Please select bill": "Please select bill",
    "Please select gps period": "Please select GPS interval time",
    "Please select permissions": "Please select authorization",
    "Please select default group": "Please select default group",
    "Please select the group": "Please select the affiliated group",
    "Please select the subgroups": "Please select the subgroup",
    "Please select the parent group": "Please select the parent group",
    "Please select the users": "Please select the users",
    "Please input IMEI": "Please input IMEI",
    "Please enter the 12~16 digit IMEI number of the device":
      "Please enter the 12~16 digit IMEI number of the device",
    "Please input Name Starting number": "Please input Name Starting number",
    "Please enter an account consisting of 2 to 15 digits or letters":
      "Please enter an account consisting of 2 to 15 digits or letters",
    "Please enter 6 to 12 characters to support letters and numbers":
      "Please enter 6 to 12 characters to support letters and numbers",
    "The name consists of 15 digits or letters and underlined lines":
      "The name consists of 15 digits or letters and underlined lines",
    "The account number can only contain Chinese alphanumeric underscores, no more than 12 characters":
      "The account number can only contain Chinese alphanumeric underscores, no more than 12 characters",
    "Please enter Chinese or letters, the length should not exceed 6 characters":
      "Please enter Chinese or letters, the length should not exceed 6 characters",
    "Account length should be between 2 and 12 characters":
      "Account length should be between 2 and 12 characters",
    "Please enter a password, the length of which should not exceed 20 characters":
      "Please enter a password, the length of which should not exceed 20 characters",
    "Password length should be between 6 and 12 characters":
      "Password length should be between 6 and 12 characters",
    "Please enter password again": "Please enter password again",
    "The two passwords do not match": "The two passwords do not match",
    "Please input password": "Please input password",
    "Please enter a name to search": "Please enter a name to search",
    "Please enter a name": "Please enter a name",
    "Please input number": "Please input number",
    "Please select target group": "Please select target group",
    "Please select account valid time": "Please select account valid time",
    "Please select function group": "Please select function group",
    "Please select Availability": "Please select Availability",
    "Please enter a Remark": "Please enter a comment",
    "Group names can contain only Chinese alphanumeric underscores and no more than 11 characters":
      "Group names can contain only Chinese alphanumeric underscores and no more than 11 characters",
    "Please enter the number of groups created in bulk":
      "Please enter the number of groups created in bulk",
    "Less than two hundred words": "Less than two hundred words",
    "Please fill in the account number": "Please fill in the account number",
    "The account length should be between 2 and 16 digits":
      "The account length should be between 2 and 16 digits",
    "Are you sure to delete": "Are you sure to delete",
    "Please tick first": "Please tick first",
    "The batch modification is successful":
      "The batch modification is successful",
    "Specify the default group that users change":
      "Specify the default group that users change",
    "Please enter the angent password": "Please enter the angent password",
    "Preferential accounts that have not reached three years will be deducted preferential coins, and three-year renewal coins will be deducted after three years":
      "Preferential accounts that have not reached three years will be deducted preferential coins, and three-year renewal coins will be deducted after three years",
    "Please tick the function": "Please tick the function",
    "Please select a default group": "Please select a default group",
    "Please select File Upload": "Please select File Upload",
    "Please enter 6-12 digits or a combination of numbers and English":
      "Please enter 6-12 digits or a combination of numbers and English",
    "Please enter the ICCID primary card":
      "Please enter the ICCID primary card",
    "Please enter 19~20 digits or a combination of numbers and English":
      "Please enter 19~20 digits or a combination of numbers and English",
    "Please enter the ICCID secondary card":
      "Please enter the ICCID secondary card",
    "Please tick Users": "Please tick Users",
    "Whether to continue": "Whether to continue",
    success: "The operation succeeded",
    "Batch renewal successful": "Batch renewal successful",
    "The modified account already exists":
      "The modified account already exists",
    "Valid for less than 90 days": "Valid for less than 90 days",
    "Insufficient permissions": "Insufficient permissions",
    "Expiring/expired cannot be modified":
      "Expiring/expired cannot be modified",
    "The account is about to expire or has expired, and remote number release is prohibited":
      "The account is about to expire or has expired, and remote number release is prohibited",
    "Uploaded files can only be excel files, and are in xlsx, xls format":
      "Uploaded files can only be excel files, and are in xlsx, xls format",
    "Allow child groups to report to the parent group":
      "Allow child groups to report to the parent group",
    "Groups that become child groups cannot be added as parent groups":
      "Groups that become child groups cannot be added as parent groups",
    "A member of a child group cannot be a member of the parent group at the same time, otherwise the user cannot form a parent-child relationship":
      "A member of a child group cannot be a member of the parent group at the same time, otherwise the user cannot form a parent-child relationship",
    "Please enter an ID or name": "Please enter an ID or name",
    "Please select a group": "Please select a group",
    "Please select the corresponding permissions":
      "Please select the corresponding permissions",
    "Please enter a 20-digit ICCID starting number":
      "Please enter a 20-digit ICCID starting number",
    "Please enter a 20-digit ICCID number":
      "Please enter a 20-digit ICCID number",
    "Please enter the number of ICCID numbers":
      "Please enter the number of ICCID numbers",
    "Please enter letters or numbers or a combination":
      "Please enter letters or numbers or a combination",
    "The ICCID number should be 19 or 20 digits long":
      "The ICCID number should be 19 or 20 digits long",
    "Enter the number of accounts you want to create":
      "Enter the number of accounts you want to create",
    "Batch binding IMEI is successful": "Batch binding IMEI is successful",
    "If the remote number is successfully released, please start the computer to verify":
      "If the remote number is successfully released, please start the computer to verify",
    "The dealer password was successfully modified":
      "The agent password was successfully modified"
  },
  // 统计
  statistics: {
    statistics: "Statistics",
    threeyearsvirtualcurrency: "Three years virtual currency",
    available: "Available",
    used: "Used",
    total: "Total",
    totalnumofconsumable: "Total number of consumable currency",
    PromotionalAccountRenewalCoin: "Promotional Account Renewal Coin",
    Industrycurrency: "Industry Currency",
    Permanentcurrency:"PermanentCurrency",
    Oneyearvirtualcurrency: "One-year virtual currency",
    Oneyearrenewalcurrency: "One-year renewal currency",
    Threeyearrenewalcurrency: "Three-year renewal currency",
    VIPrenewalcurrency: "VIP renewal currency",
    "Currency type": "Currency type",
    "Please select currency type": "Please select currency type",
    Number: "Number",
    Remark: "Remark",
    piece: "piece",
    RenewCurrency: "Renew Currency",
    availableIndustrycurrency: "Industry currency available",
    "One year of renewal currency remaining":
      "One year of renewal currency remaining",
    "Three years of renewal currency remaining":
      "Three years of renewal currency remaining",
    "Remaining promotional account renewal currency":
      "Remaining promotional account renewal currency",
    "Remaining VIP renewal currency": "Remaining VIP renewal currency"
  },
  // 表单
  form: {
    functiongroup: "FunctionGroup",
    Permission: "Permission",
    "Group Call": "Group Call",
    "Switch Group": "Switch Group",
    "Temp call": "Temp call",
    "Across-groups temp call": "Across-groups temp call",
    IMEI: "IMEI",
    GPS: "GPS",
    childgroup: "ChildGroup",
    Quantity: "Quantity",
    "Initial Number": "Initial Number",
    "name starting number": "Name starting number",
    "Join group": "Join group",
    Group: "Group",
    Selected: "Selected ",
    Assignfunctiongroups: "Assign FunctionGroup",
    discountmonth: "Number of months of offer",
    groupName: "Group Name",
    Null: "Null",
    Preferential: "Preferential",
    ThreeYears: "ThreeYears",
    OneYear: "OneYear",
    Permanent: "Permanent",
    VIP: "VIP",
    YES: "YES",
    "Basic package": "Basic package",
    "Schedule packages": "Schedule packages",
    "Video packages": "Video packages",
    "GPS reporting cycle": "GPS reporting cycle",
    selecctfile: "Select the file",
    download: "Template download",
    modifyagentpw: "Change the agent password",
    agentpw: "Agent password",
    oldagentpw: "Old agentpw",
    newagentpw: "New agentpw",
    renewaccount: "Renew your account",
    mastarICCID: "ICCID primary card",
    secondICCID: "ICCID secondary card",
    ICCIDLOGIN: "ICCID login is preferred",
    WhetherHyphen: "Whether or not to hyphenate",
    "Serial number creation": "Serial number creation",
    "Created without hyphens": "Created without hyphens",
    "ICCID start number": "ICCID start number",
    numofIccid: "Number of ICCIDs"
  },
  adminlevel: {
    super: "Super",
    admin: "Admin",
    Level1: "Level1",
    Level2: "Level2",
    Level3: "Level3"
  }
};
