/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-04-24 11:05:41
 * @LastEditors: fugang
 * @LastEditTime: 2022-04-24 12:12:14
 */

export const Time = (time: number) => {
  let date = new Date(time);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();
  return year + "-" + month.toString().padStart(2, "0") + "-" + day.toString().padStart(2, "0")
    + " " + hour.toString().padStart(2, "0") + ":" + minute.toString().padStart(2, "0")
    + ":" + second.toString().padStart(2, "0");
}


