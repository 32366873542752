/*
 * @Description: cookies封装
 * @Author: ZY
 * @Date: 2020-12-17 16:06:33
 * @LastEditors: fugang
 * @LastEditTime: 2022-04-27 09:38:13
 */
import Keys from "@/constant/key";
import Cookies from "js-cookie";

export const getSidebarStatus = () => Cookies.get(Keys.sidebarStatusKey);
export const setSidebarStatus = (sidebarStatus: string) =>
  Cookies.set(Keys.sidebarStatusKey, sidebarStatus);

export const getLanguage = () => Cookies.get(Keys.languageKey);
export const setLanguage = (language: string) =>
  Cookies.set(Keys.languageKey, language);

export const getSize = () => Cookies.get(Keys.sizeKey);
export const setSize = (size: string) => Cookies.set(Keys.sizeKey, size);

export const getToken = () => localStorage.getItem("token");
export const setToken = (token: string) => localStorage.setItem("token", token);
export const removeToken = () => localStorage.removeItem("token");

export const getSession = () => localStorage.getItem("tokensession");
export const setSession = (managerWebSession: string) =>
  localStorage.setItem("tokensession", managerWebSession);
export const removeSession = () => localStorage.removeItem("tokensession");

export const setLoginName = (loginName: string) =>
  localStorage.setItem("loginName", loginName);
export const getLoginName = () => localStorage.getItem("loginName");
export const removeLoginName = () => localStorage.removeItem("loginName");

export const setLoginId = (id: string) => localStorage.setItem("loginId", id);
export const getLoginId = () => localStorage.getItem("loginId");
export const removeLoginId = () => localStorage.removeItem("loginId");

export const setPerrsion = (perrsion: any) =>
  localStorage.setItem("perrsion", perrsion);
export const getPerrsion = () => localStorage.getItem("perrsion");
export const removePerrsion = () => localStorage.removeItem("perrsion");
