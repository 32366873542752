
import { defineComponent, onMounted, reactive, toRefs, ref, toRaw } from "vue";
import { useRoute, useRouter } from "vue-router";
import { api } from "@/apis/user";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";

interface imeiInfo {
  uid: any;
  account: any;
  name: any;
  password: any;
  imei: any;
  agentpwd: any;
}
interface agentPwdInfo {
  uid: any;
  account: any;
  name: any;
  agentpwd: any;
  oldAgentpwd: any;
}
export default defineComponent({
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const routerList = useRouter();
    const uid = route.query.uid || "";
    const imeiForm = reactive<imeiInfo>({
      uid: "",
      account: "",
      name: "",
      password: "",
      imei: "",
      agentpwd: ""
    });
    const agentpwdForm = reactive<agentPwdInfo>({
      uid: "",
      account: "",
      name: "",
      agentpwd: "",
      oldAgentpwd: ""
    });
    const dataMap = reactive({
      // 提交
      async submitForm(e: any) {
        if (e == "imei") {
          console.log(imeiForm, "imei表单");
          const data: any = await api.revise.userWriteImei(imeiForm);
          if (data.status == 200) {
            routerList.push({ path: "/userlist" });
            ElMessage.success(t('input.If the remote number is successfully released, please start the computer to verify'));
          } else {
            ElMessage.warning(data.message);
          }
        } else if (e == "agentpwd") {
          console.log(agentpwdForm, "agent表单");
          const data: any = await api.revise.agentpwdUpdate(agentpwdForm);
          console.log(data.status, data);
          if (data.status == 200) {
            routerList.push({ path: "/userlist" });
            ElMessage.success(t('input.The dealer password was successfully modified'));
          } else {
            ElMessage.warning(data.message);
          }
        }
      },
      // 初始化
      async getformInfo() {
        // 查询uid对应的终端账号信息
        const userInfo: any = await api.detail.userInfo({ uid: uid });
        const { data } = userInfo.data;
        imeiForm.uid = data.uid;
        agentpwdForm.uid = data.uid;
        imeiForm.account = data.account;
        agentpwdForm.account = data.account;
        imeiForm.name = data.name;
        agentpwdForm.name = data.name;
        imeiForm.password = data.password;
        imeiForm.imei = data.imei;
      }
    });

    onMounted(() => {
      dataMap.getformInfo();
    });
    return {
      ...toRefs(dataMap),
      imeiForm,
      agentpwdForm
    };
  }
});
