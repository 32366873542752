
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { api } from '@/apis/user'
export default defineComponent({
  setup () {
    const { t } = useI18n()
    const dataMap = reactive({
      tableKey: 0,
      list: [],
      total: 0,
      name: null,
      listLoading: false,
      downloadLoading: false,
      val: 10,
      // 分页查询参数
      listQuery: {
        srcsvr: '',
        destsvr: '',
        page: {
          pageNo: 1,
          pageSize: 10
        }
      },
      // 查询
      toSearch () {
        dataMap.getList(1, 10, dataMap.listQuery.srcsvr, dataMap.listQuery.destsvr)
      },
      //    
      toReset () {
        dataMap.listQuery.srcsvr = ''
        dataMap.listQuery.destsvr = ''
        dataMap.getList(1, 10, dataMap.listQuery.srcsvr, dataMap.listQuery.destsvr)
      },
      // 分页
      handleSizeChange (val: any) {
        dataMap.val = val
        dataMap.getList(1, val)
      },
      handleCurrentChange (page: any) {
        dataMap.getList(page, dataMap.val)
      },
      // 页面初始化数据
      async getList (page?: any, pageSize?: any, srcsvr?: any, destsvr?: any) {
        if (page) {
          dataMap.listQuery.page.pageNo
        }
        if (pageSize) {
          dataMap.listQuery.page.pageSize = pageSize
        }
        dataMap.listQuery.page.pageNo = page
        dataMap.listQuery.page.pageSize = pageSize
        dataMap.listLoading = true
        const data: any = await api.list.changeList(dataMap.listQuery)
        dataMap.list = data?.data.data.resultList
        dataMap.total = data?.data.data.totalCount
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.3 * 1000)
      }
    })
    onMounted(() => {
      dataMap.getList(1, 10)
    })

    return {
      t, ...toRefs(dataMap)
    }
  }
})
