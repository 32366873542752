/*
 * @Descripttion: no
 * @version: 1.0.0
 * @Author: fugang
 * @Date: 2022-04-19 10:45:04
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-11 11:43:19
 */

import { RouteRecordRaw } from "vue-router";
import Layout from "@/layout/Index.vue";

const ExampleRouter: Array<RouteRecordRaw> = [
  {
    path: "/group",
    component: Layout,
    redirect: "noredirect",
    meta: {
      title: "groupPages",
      icon: "#icon-a-2Dqunzu",
      roles: ["系统管理员", "二级管理"]
    },
    children: [
      {
        path: "/groupList",
        component: () =>
          import(
            /* webpackChunkName: "error-page-401" */ "@/views/group/grouplist.vue"
          ),
        name: "groupList",
        meta: {
          title: "groupList",
          icon: "#icon-a-2Dqunzu",
          noCache: true,
          keepAlive: true
        }
      },
      {
        path: "/groupadd",
        component: () =>
          import(
            /* webpackChunkName: "error-page-404" */ "@/views/group/groupadd.vue"
          ),
        name: "GroupAdd",
        meta: {
          title: "groupAdd",
          icon: "#icon-tianjiatianchong",
          noCache: true
        }
      },
      // {
      //   path: "/groupUpdate",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "error-page-404" */ "@/views/group/groupadd.vue"
      //     ),
      //   name: "GroupAdd",
      //   meta: {
      //     title: "groupUpdate",
      //     icon: "#icon-tianjiatianchong",
      //     noCache: true,
      //     hidden: true
      //   }
      // },
      {
        path: "/groupnested",
        component: () => import("@/views/group/groupnested.vue"),
        name: "groupNested",
        meta: {
          title: "groupNested",
          icon: "#icon-qiantaobingtu",
          noCache: true
        }
      }
    ]
  }
];

export default ExampleRouter;
