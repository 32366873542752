
import { ref } from 'vue'
import PanelGroup from './components/PanelGroup.vue'

export default {
  components: {
    PanelGroup
  },
  setup() {

  }
}
