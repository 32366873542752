import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")
  const _component_el_tabs = _resolveComponent("el-tabs")

  return (_openBlock(), _createBlock(_component_el_tabs, { style: {"margin-left":"20px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tab_pane, {
        label: _ctx.$t('table.remotrnum')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form, { data: _ctx.imeiForm }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('table.Account')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    disabled: "",
                    modelValue: _ctx.imeiForm.account,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.imeiForm.account = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('table.name')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    disabled: "",
                    modelValue: _ctx.imeiForm.name,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.imeiForm.name = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, { label: "IMEI:" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.imeiForm.imei,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.imeiForm.imei = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('form.agentpw')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.imeiForm.agentpwd,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.imeiForm.agentpwd = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.submitForm('imei')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('table.remotrnum')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_el_tab_pane, {
        label: _ctx.$t('form.modifyagentpw')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form, { data: _ctx.agentpwdForm }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('table.Account')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    disabled: "",
                    modelValue: _ctx.agentpwdForm.account,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.agentpwdForm.account = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('table.name')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    disabled: "",
                    modelValue: _ctx.agentpwdForm.name,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.agentpwdForm.name = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('form.oldagentpw')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    distable: "",
                    modelValue: _ctx.agentpwdForm.oldAgentpwd,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.agentpwdForm.oldAgentpwd = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, {
                label: _ctx.$t('form.newagentpw')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.agentpwdForm.agentpwd,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.agentpwdForm.agentpwd = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.submitForm('agentpwd')))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('form.modifyagentpw')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _: 1
  }))
}