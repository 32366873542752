
import { defineComponent, onMounted, reactive, toRefs, getCurrentInstance, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { api } from '@/apis/user'
import { ElMessage } from 'element-plus'

export default defineComponent({
  setup () {

    const { t } = useI18n()
    const props1 = {
      checkStrictly: true,
      value: "id",
      label: "name"
    }

    const { proxy } = getCurrentInstance() as any;

    const distributeForm = reactive({
      form: {
        name: '',
        id: '',
        href: '',
        isShow: '',
        parentId: '',
        permission: ''
      },

    })
    const formdistribute = ref()
    const rules = reactive({
      name: [
        { required: true, message: '请输入名称', trigger: 'blur' }
      ],
    })
    const dialogFormdistribute = ref(false)
    const dataMap = reactive({
      tableKey: 0,
      list: [],
      total: 0,
      name: null,
      title: '',
      listLoading: false,
      // 分页查询参数
      listQuery: {
        page: {
          pageSize: 10,
          pageNo: 1,
        },
        parentId: '1',
      },

      // 添加
      toAdd () {
        dataMap.title = '添加'
        distributeForm.form = Object.assign({})
        dialogFormdistribute.value = true

      },
      // 修改
      async handleEdit (index: any, row: any) {
        dataMap.title = '修改'
        // distributeForm.form = row
        const { data }: any = await api.list.findmenuList({ id: row.parentId })
        console.log(data)
        distributeForm.form = row
        dialogFormdistribute.value = true

      },
      // 删除
      async handleDelete (row: any) {
        const { data }: any = await api.revise.menudelete({ menuid: row.id })
        if (data.status === 200) {
          ElMessage.success('删除成功')
          dataMap.getList()
        }
      },
      // 分页
      handleSizeChange (val: any) {
        dataMap.getList(1, val)
      },
      handleCurrentChange (page: any) {
        dataMap.getList(page, 10)
      },

      // 提交
      submitDisForm () {
        console.log(distributeForm.form)
        formdistribute.value.validate(async (valid: any) => {
          if (valid) {
            if (dataMap.title === '添加') {
              const { data }: any = await api.revise.menuadd(distributeForm.form)
              if (data.status === 200) {
                ElMessage.success('添加成功')
                dialogFormdistribute.value = false
                dataMap.getList(1, 10)
              }
            } else if (dataMap.title === '修改') {
              const { data }: any = await api.revise.menuupdate(distributeForm.form)
              if (data.status === 200) {
                ElMessage.success('修改成功')
                dialogFormdistribute.value = false

                dataMap.getList(1, 10)
              }
            }
          } else {
            return false
          }
        })
      },
      // 页面初始化数据
      async getList (page?: any, pageSize?: any, parentId?: any) {
        if (page) {
          dataMap.listQuery.page.pageNo
        }
        if (pageSize) {
          dataMap.listQuery.page.pageSize = pageSize
        }
        dataMap.listQuery.page.pageNo = page
        dataMap.listQuery.page.pageSize = pageSize
        dataMap.listQuery.parentId = parentId
        dataMap.listLoading = true
        const data: any = await api.list.menuList(dataMap.listQuery)
        dataMap.list = data?.data.data.resultList
        dataMap.total = data?.data.data.totalCount
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.3 * 1000)
      }
    })
    onMounted(() => {
      dataMap.getList(1, 10)
    })

    return {
      t, ...toRefs(dataMap), ...toRefs(distributeForm), dialogFormdistribute, formdistribute, rules, props1,
    }
  }
})
