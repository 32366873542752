
import { defineComponent, onMounted, reactive, toRefs, getCurrentInstance, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { api } from '@/apis/user'
import { Time } from '@/utils/filter'
import { formatJson } from '@/utils'
import { exportJson2Excel } from '@/utils/excel'
import { ElMessage } from 'element-plus'
export default defineComponent({
  setup () {
    const { t } = useI18n()
    const { proxy } = getCurrentInstance() as any;
    const day = proxy.day
    const multipleSelection = ref()

    const dataMap = reactive({
      tableKey: 0,
      list: [],
      total: 0,
      name: null,
      listLoading: false,
      downloadLoading: false,
      downallloadLoading: false,
      val: 10,
      time: Time,
      date: '',
      filename: '',
      keyValue: {
        value: '',
        label: ''
      },
      // 分页查询参数
      listQuery: {
        page: {
          pageNo: 1,
          pageSize: 10
        },
        pttUserEntity: {
          account: '',
          uid: '',
          defaultGroupName: '',
        }
      },
      // 查询
      toSearch () {
        if (dataMap.date != '') {
          console.log(dataMap.date)
          dataMap.getList(1, 10, dataMap.listQuery.pttUserEntity)
        } else {  
          dataMap.getList(1, 10, dataMap.listQuery.pttUserEntity)

        }
      },
      // 重置
      toReload () {
        dataMap.date = ''
        dataMap.listQuery.pttUserEntity.account = ''
        dataMap.listQuery.pttUserEntity.uid = ''
        dataMap.listQuery.pttUserEntity.defaultGroupName = ''
        dataMap.getList(1, 10,)

      },
      handleSelectionChange (val: any) {
        multipleSelection.value = val
        console.log(val)
      },
      // 分页
      handleSizeChange (val: any) {
        dataMap.val = val
        dataMap.getList(1, val)
      },
      handleCurrentChange (page: any) {

        dataMap.getList(page, dataMap.val)
      },
      // 页面初始化数据
      async getList (page?: any, pageSize?: any, userLoginEntity?: any) {
        if (page) {
          dataMap.listQuery.page.pageNo
        }
        if (pageSize) {
          dataMap.listQuery.page.pageSize = pageSize
        }
        dataMap.listQuery.page.pageNo = page
        dataMap.listQuery.page.pageSize = pageSize
        // dataMap.listQuery.userLoginEntity
        dataMap.listLoading = true

        const data: any = await api.list.userLoginList(dataMap.listQuery)
        console.log('登录信息列表',data?.data.data.resultList)
        dataMap.list = data?.data.data.resultList 
        dataMap.total = data?.data.data.totalCount
        setTimeout(() => {
          dataMap.listLoading = false
        }, 0.3 * 1000)
      }
    })

    onMounted(() => {
      dataMap.getList(1, 10), multipleSelection
    })

    return {
      t, ...toRefs(dataMap)
    }
  }
})
