<!--
 * @Description: 导航栏
 * @Author: ZY
 * @Date: 2020-12-17 15:52:19
 * @LastEditors: fugang
 * @LastEditTime: 2022-05-23 09:43:51
-->
<template>
  <div class="navbar">
    <Hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggle-click="toggleSideBar"
    />
    
    <!-- 节点导航 -->
    <BreadCrumb id="breadcrumb-container" class="breadcrumb-container" />

    <!-- 好攀宜佳标题 -->
    <!-- <div class="title-div">
      <div class="sidebar-title">
        <img src="../../../../src/assets/images/login/logo_phyj.png" alt="">
      </div>
      <span class="text">5G</span>
      <span>时代智能数字对讲管理平台</span>
    </div> -->
    <div class="right-menu">
      <template v-if="device !== 'mobile'">
        <!-- <error-log class="errLog-container right-menu-item hover-effect" /> -->
        <Screenfull class="right-menu-item hover-effect" />
        <el-tooltip
          :content="t('navbar.size')"
          effect="dark"
          placement="bottom"
        >
          <SizeSelect class="right-menu-item hover-effect" />
        </el-tooltip>
        <LangSelect class="right-menu-item hover-effect" />
      </template>
      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
      >
        <div class="avatar-wrapper">
          <img :src="avatar + '?imageView2/1/w/80/h/80'" class="user-avatar" />
          <span style="position:relative;top:-15px;margin-left:15px;">{{
            getloginName
          }}</span>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <!-- <router-link to="/profile/">
              <el-dropdown-item>
                {{ t("navbar.profile") }}
              </el-dropdown-item>
            </router-link> -->
            <router-link to="/">
              <el-dropdown-item>
                {{ t("navbar.dashboard") }}
              </el-dropdown-item>
            </router-link>

            <!-- <a target="_blank" href="https://armour.github.io/vue-typescript-admin-docs/">
              <el-dropdown-item>Docs</el-dropdown-item>
            </a> -->
            <el-dropdown-item divided @click="logout">
              <span style="display:block;">
                {{ t("navbar.logOut") }}
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/bread-crumb/Index.vue";
import Hamburger from "@/components/hamburger/Index.vue";
import Screenfull from "@/components/screenfull/Index.vue";
import LangSelect from "@/components/lang_select/Index.vue";
import SizeSelect from "@/components/size_select/Index.vue";
import { getLoginName } from "@/utils/cookies";
import { TagsActionTypes } from "@/store/modules/tagsview/action-types";
import { api } from "@/apis/user";
import { computed, reactive, toRefs } from "vue";
import { useStore } from "@/store";
import { AppActionTypes } from "@/store/modules/app/action-types";
import { useI18n } from "vue-i18n";
import { UserActionTypes } from "@/store/modules/user/action-types";
import { useRoute, useRouter } from "vue-router";
export default {
  components: {
    BreadCrumb,
    Hamburger,
    Screenfull,
    LangSelect,
    SizeSelect
  },
  setup() {
    const getloginName = getLoginName();

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const sidebar = computed(() => {
      return store.state.app.sidebar;
    });
    const device = computed(() => {
      return store.state.app.device.toString();
    });
    const avatar = computed(() => {
      return store.state.user.avatar;
    });
    const state = reactive({
      toggleSideBar: () => {
        store.dispatch(AppActionTypes.ACTION_TOGGLE_SIDEBAR, false);
      },
      logout: () => {
        useStore().dispatch(UserActionTypes.ACTION_LOGIN_OUT);
        useStore().dispatch(TagsActionTypes.ACTION_DEL_ALL_VIEWS, undefined);
        api.table.logout();
        localStorage.removeItem("searchgid");
        localStorage.removeItem("searchname");
        localStorage.removeItem("searchremarks");
        localStorage.removeItem("searchgid");
        localStorage.removeItem("searchname");
        localStorage.removeItem("keyValueLabel");
        localStorage.removeItem("keyValueValue");
        router.push(`/login?redirect=${route.fullPath}`).catch(err => {
          console.warn(err);
        });
      }
    });
    return {
      sidebar,
      device,
      avatar,
      ...toRefs(state),
      getloginName,
      t
    };
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  // background-image: linear-gradient(to top, #d7d7d7, #f5f5f5);
  // position: fixed;
  // top: 0;
  // width: 100%;
  // z-index: 1000;
  
  // .title-div{
  //   float: left;
  //   display: flex;
  //   align-items: center;
  //   height: 100%;
  //   .sidebar-title {
  //     display: inline-block;
  //     vertical-align: middle;
  //     padding-left: 30px;
  //     padding-right: 120px;
  //     img {
  //       width: 135px;
  //       height: 100%;
  //       padding-left: 15px;
  //     }
  //   }
  //   span{
  //     line-height:100%;
  //     font-size: 22px;
  //     color:#251716;
  //   }
  //   .text{
  //     font-weight: bold;
  //     font-size: 30px;
  //     color:#e70a1c;
  //   }
  // }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    padding: 0 15px;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      .avatar-wrapper {
        margin-top: 5px;
        margin-right: 16px;
        margin-left: 16px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
