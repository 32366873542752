
import { defineComponent, reactive, onMounted, toRefs, ref } from "vue";
import { api } from "@/apis/user";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
interface dataList {
  data: {
    uid: number;
    account: string;
    password: string;
    imei: string;
    agentpwd: number;
  }[];
}
interface userListModel {
  id: number;
  uid: number;
  account: string;
  name: string;
  password: string;
  funcs: string;
  gpsInterval: string;
  onLine: string;
  imei: any;
  type: number;
  createBy: number;
  defaultGid: number;
  defaultGroupName: number;
  createData: string;
  validDate: string;
  oldType: any;
}
export default defineComponent({
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const route = useRoute();
    const showDialogAgent = ref(false);
    const jxsref = ref();
    const rules = reactive({
      agentpwd: []
    });
    const dataForm = reactive<dataList>({
      data: []
    });

    const dataMap = reactive({
      val: 10,
      total: 0,
      // 分页查询参数
      listQuery: {
        page: {
          pageNo: 1,
          pageSize: 10,
          uids: ""
        }
      },
      tableKey: 0,
      list: Array<userListModel>(),
      jxsfrom: {
        agentpwd: ""
      },
      listLoading: false,
      handleShowAgent() {
        showDialogAgent.value = true;
      },
      // 提交
      async submit() {
        console.log(dataMap.list, "list?");
        const userImeiList: any = [];
        dataMap.list.map(item => {
          if (item.imei !== null && item.imei.length > 0) {
            userImeiList.push({
              uid: item?.uid,
              account: item.account,
              password: item.password,
              imei: item.imei,
              agentpwd: dataMap.jxsfrom.agentpwd
            });
          }
        });
        console.log(userImeiList, "userImeiList");
        const data: any = await api.revise.batchWriteImei(userImeiList);
        console.log("res", data);
        if (data.data.status == 200) {
          ElMessage.success(t('input.Batch binding IMEI is successful'));
        }
        showDialogAgent.value = false;
        router.push({ path: "/userList" });
      },

      // 分页
      handleSizeChange(val: any) {
        dataMap.val = val;
        dataMap.getList(1, val);
      },
      handleCurrentChange(page: any) {
        console.log(page);
        dataMap.getList(page, dataMap.val);
      },
      // 初始化
      async getList(page?: any, pageSize?: any, uids?: any) {
        if (page) {
          dataMap.listQuery.page.pageNo = page;
        }
        if (pageSize) {
          dataMap.listQuery.page.pageSize = pageSize;
        }
        if (uids) {
          dataMap.listQuery.page.uids = uids;
        }
        const data: any = await api.list.userNoImeiList(dataMap.listQuery.page);
        console.log(data, "data");

        dataMap.list = data?.data.data.resultList;
        dataForm.data = data?.data.data.resultList;

        dataMap.total = data?.data.data.totalCount;
        setTimeout(() => {
          dataMap.listLoading = false;
        }, 0.3 * 1000);
      }
    });
    onMounted(() => {
      dataMap.getList(1, 10, route.query.uids);
    });
    return {
      ...toRefs(dataMap),
      dataForm,
      showDialogAgent,
      rules,
      jxsref
    };
  }
});
